export const getFileMimeType = (fileOrExt: string) => {
  const ext = fileOrExt.includes('.') ? fileOrExt.split('.').pop()?.toLowerCase() : fileOrExt.toLowerCase()

  if (!ext) return 'application/octet-stream'

  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg'
    case 'png':
      return 'image/png'
    case 'bmp':
      return 'image/bmp'
    case 'svg':
      return 'image/svg+xml'
    case 'gif':
      return 'image/gif'
    case 'webp':
      return 'image/webp'
    case 'tiff':
    case 'tif':
      return 'image/tiff'
    case 'ico':
      return 'image/x-icon'
    case 'mp4':
      return 'video/mp4'
    case 'avi':
      return 'video/x-msvideo'
    case 'mov':
      return 'video/quicktime'
    case 'wmv':
      return 'video/x-ms-wmv'
    case 'mp3':
      return 'audio/mpeg'
    case 'wav':
      return 'audio/wav'
    case 'flac':
      return 'audio/flac'
    case 'pdf':
      return 'application/pdf'
    case 'doc':
    case 'docx':
      return 'application/msword'
    case 'xls':
    case 'xlsx':
      return 'application/vnd.ms-excel'
    case 'ppt':
    case 'pptx':
      return 'application/vnd.ms-powerpoint'
    case 'zip':
      return 'application/zip'
    case 'rar':
      return 'application/x-rar-compressed'
    case 'txt':
      return 'text/plain'
    case 'html':
    case 'htm':
      return 'text/html'
    case 'css':
      return 'text/css'
    case 'js':
      return 'application/javascript'
    case 'json':
      return 'application/json'
    case 'xml':
      return 'application/xml'
    default:
      return 'application/octet-stream' // fallback for unknown types
  }
}


export enum FileType {
  Image = 'image',
  Audio = 'audio',
  Video = 'video',
  PDF = 'pdf',
  Document = 'document',
  Spreadsheet = 'spreadsheet',
  Presentation = 'presentation',
  Compressed = 'compressed',
  Script = 'script',
  HTML = 'html',
  Text = 'text',
  Unknown = 'unknown'
}

export const getFileType = (fileOrExt: string): FileType => {
  const ext = fileOrExt.includes('.') ? fileOrExt.split('.').pop()?.toLowerCase() : fileOrExt.toLowerCase()

  if (!ext) return FileType.Unknown

  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'bmp':
    case 'svg':
    case 'gif':
    case 'webp':
    case 'tiff':
    case 'tif':
    case 'ico':
      return FileType.Image
    case 'mp4':
    case 'avi':
    case 'mov':
    case 'wmv':
      return FileType.Video
    case 'mp3':
    case 'wav':
    case 'flac':
      return FileType.Audio
    case 'pdf':
      return FileType.PDF
    case 'doc':
    case 'docx':
      return FileType.Document
    case 'xls':
    case 'xlsx':
      return FileType.Spreadsheet
    case 'ppt':
    case 'pptx':
      return FileType.Presentation
    case 'zip':
    case 'rar':
      return FileType.Compressed
    case 'js':
      return FileType.Script
    case 'html':
    case 'htm':
      return FileType.HTML
    case 'txt':
      return FileType.Text
    default:
      return FileType.Unknown // fallback for unknown types
  }
}
