import { get, writable, type Unsubscriber } from 'svelte/store'
import initializationStore from '$src/core-app/stores/initialization.store'
import type GlobalInitializationStore from '$src/core-app/models/initialization-store'
import { remoteDebuggingService, Severity } from '$src/core-app/services/remote-debugging.service'
import { getFeatures, type Features } from './features.service'

export const featureToggleStore = writable<Features>()
export let unsubscribeFeatureToggleStore: Unsubscriber

/**
 * INITIALIZES THE STORE ON APPLICATION STARTUP
 * @returns 
 */
export const initializeFeatureToggleStore = async () => {
  if (get(initializationStore).featureToggleStoreInitialized) return

  try {
    await getFeatures()

    unsubscribeFeatureToggleStore = featureToggleStore.subscribe(() => { })

    initializationStore.update((store: GlobalInitializationStore) => {
      store.featureToggleStoreInitialized = true

      return store
    })
  } catch (err) {
    remoteDebuggingService.addInfo(err, '', Severity.Error)
  }
}
