<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { FileType, getFileMimeType, getFileType } from '$src/shared/utils/file'

  export let width:number | string = '100%'
  export let height:number | string = '100%'
  export let hidden:boolean = false
  export let imageBase64: string = ''
  export let imageTransparency: number = 50
  export let maxSizeMo: number = 2
  export let allowedExtensions = ['jpg', 'jpeg', 'bmp', 'png', 'svg']

  let fileInput: HTMLInputElement | null = null
  let file: File | null = null
  let isLoading: boolean = false

  const dispatch = createEventDispatcher()

  const maxFileSize = maxSizeMo * 1024 * 1024 // Mo

  export const openFileDialog = (e:Event) => {
    if (!fileInput) return
    fileInput.click()

    if (!e?.target) return
    const files = (e.target as HTMLInputElement).files
    if (files && files.length > 0) dispatch('fileSelected', files[0])
  }

  $:extToMimeType = allowedExtensions.map((ext:string) => getFileMimeType(ext)).join(',')

  const imageToBase64 = async (img: HTMLImageElement): Promise<string> => {
    let base64String: string = ''
  
    const canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    const ctx = canvas.getContext('2d')

    if (!ctx) {
      dispatch('error', 'imageToBase64 ctx')
      canvas.remove()
      
      return base64String
    }

    ctx.drawImage(img, 0, 0)

    if (!file) {
      dispatch('error', 'file undefined')
      canvas.remove()
      
      return base64String
    }

    const fileExtension = file.name.split('.').pop()?.toLowerCase()
    let mimeType: string

    switch (fileExtension) {
      case 'png':
        mimeType = 'image/png'
        base64String = canvas.toDataURL(mimeType)
        canvas.remove()
        
        return base64String

      case 'jpg':
      case 'jpeg':
        mimeType = 'image/jpeg'
        base64String = canvas.toDataURL(mimeType)
        canvas.remove()
        
        return base64String

      case 'bmp':
        mimeType = 'image/bmp'
        base64String = canvas.toDataURL(mimeType)
        canvas.remove()
        
        return base64String

      case 'svg':
      // Use a Promise to handle async FileReader
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = (event) => {
            if (!event?.target) {
              dispatch('error', 'imageToBase64 FileReader error')
              canvas.remove()
              
              return reject('FileReader error')
            }
            base64String = String(event.target.result)
            resolve(base64String)
          }
          reader.onerror = () => {
            dispatch('error', 'imageToBase64 FileReader failed')
            reject('FileReader failed')
          }
          reader.readAsDataURL(file as File)
        })

      default:
        dispatch('error', 'imageToBase64 unsupported file type')
        
        return base64String
    }
  }

  const onOpenFileDialog = (e:Event) => {
    openFileDialog(e)
  }

  const onFileChange = async (event: Event) => {
    isLoading = true

    const target = event.target as HTMLInputElement
    file = target.files?.[0] || null

    if (!file) {
      dispatch('error', 'No file selected')
      isLoading = false
      
      return
    }

    const extension = file.name.split('.').pop()?.toLowerCase()
    if (!allowedExtensions.includes(extension || '')) {
      dispatch('error', 'Invalid file extension')
      isLoading = false
      
      return
    }

    if (file.size > maxFileSize) {
      dispatch('error', 'File too large')
      isLoading = false
      
      return
    }

    // not image : nothing to display
    const isImage = getFileType(file?.name ?? '') === FileType.Image

    if (!isImage) {
      const metadata = {
        fileName: file?.name ?? '',
        fileExtension: file?.name?.split('.')?.slice(-1)?.toString(),
        fileSize: file?.size ?? 0, // in bytes
        fileMimeType: file?.type ?? ''
      }
      
      dispatch('loaded', metadata)
      isLoading = false
      
      return
    }

    // // load file and get metadata if image
    // const img = new Image()
    // img.src = URL.createObjectURL(file)
    // img.onload = async () => {
    //   imageBase64 = await imageToBase64(img)
      
    //   const metadata = {
    //     fileName: file?.name ?? '',
    //     fileExtension: file?.name?.split('.')?.slice(-1)?.toString(),
    //     fileSize: file?.size ?? 0, // in bytes
    //     fileMimeType: file?.type ?? '',
    //     imageWidth: img.width ?? 0,
    //     imageHeight: img.height ?? 0,
    //     base64: imageBase64 ?? ''
    //   }

    //   dispatch('loaded', metadata)
    //   isLoading = false
    // }
    try {
      const img = new Image()
      img.src = URL.createObjectURL(file)

      await new Promise<void>((resolve, reject) => {
        img.onload = async () => {
          try {
            const base64 = await imageToBase64(img)
          
            const metadata = {
              fileName: file?.name,
              fileExtension: extension,
              fileSize: file?.size,
              fileMimeType: file?.type,
              imageWidth: img.width,
              imageHeight: img.height,
              base64
            }

            dispatch('loaded', metadata)
            resolve()
          } catch (error) {
            dispatch('error', 'Failed to convert image to base64')
            reject(error)
          }
        }
        img.onerror = (error) => {
          dispatch('error', 'Failed to load image')
          reject(error)
        }
      })
    } catch (error) {
      console.error(error)
    } finally {
      isLoading = false
    }
  }
</script>

<div
  class="input-uploader" style='--width:{typeof width === 'number' ? `${width}px` : width};--height:{typeof height === 'number' ? `${height}px` : height};--opacity:{imageTransparency / 100};'
  class:input-uploader-disabled={isLoading}
  class:input-uploader-hidden={hidden}
>
  <button
    style='background-image: url({imageBase64});'
    on:click={onOpenFileDialog}
  >
    <div class="z-10">
      <slot />
    </div>
    {#if isLoading}
      <div class="skeleton-loading" />
    {/if}
  </button>
  
  <input
    type="file"
    accept={extToMimeType}
    bind:this={fileInput}
    on:change={onFileChange}
    style="display: none;"
  />
</div>

<style>
  .input-uploader {
    position: relative;
    width: var(--width);
    height: var(--height);
    min-width: var(--width);
    min-height: var(--height);

    &-disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &-hidden {
      display: none;
    }

    & button {
      position: relative;
      height: 100%;
      width: 100%;
      border: 2px dashed #ccc;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      cursor: pointer;
      background-color: white;
      background-position: top left;
      background-size: contain;
      background-repeat: no-repeat;
      line-height: normal;
      text-align: left;
      padding: 0;
      margin:0;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, var(--opacity));
        z-index: 1;
      }
    }
  }

  .skeleton-loading {
    width: 100%;
    height: 100%;
    background: #e0e0e0;
    background: linear-gradient(90deg, #f0f0f0 25%, #9e9e9e 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: skeleton 2s infinite linear;
  }

  @keyframes skeleton {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
</style>