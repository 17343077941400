<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { stringNormalize } from '$shared/utils/string'
  import type { Variable, Variables } from '$src/shared/types/variable'
  export let variables:Variables = []
  export let placeholder:string = ''
  export let disabled:boolean = false
  export let searchValue: string = ''
  
  $:vars = searchValue 
    ? variables.filter(p => stringNormalize(p.key).includes(stringNormalize(searchValue)) || stringNormalize(p.value).includes(stringNormalize(searchValue)))
    : variables

  const dispatch = createEventDispatcher()
  
  const onMouseUp = (item:Variable) => {
    if (!disabled) dispatch('select', item)
  }

</script>

<div class="variable relative h-full flex flex-col">
  <input
    type="text"
    class="search"
    {placeholder}
    bind:value={searchValue}
  />

  {#if vars.length > 0}
    <ul class="min-h-full mt-2 overflow-y-auto overflow-x-hidden border rounded grow">
      {#each vars as v}
        <li role="presentation"
          class="cursor-pointer whitespace-nowrap py-1 px-2 text-sm font-normal hover:bg-[#FDF0EE]"
          class:disabled={disabled}
          on:mouseup={() => onMouseUp(v)}
        >
          {v.value}
        </li>
      {/each}
    </ul>
  {:else}
    {#if searchValue}
      <span class="mt-2 text-center">Aucun résultat</span>
    {/if}
  {/if}

</div>

<style lang="postcss">
  .search {
    @apply w-full text-sm font-normal shadow-none rounded border border-loblolly pl-8;

    background: url("/img/icons/search.svg") no-repeat;
    background-size: 1.3rem;
    background-position: .5rem center;
    background-clip: border-box;
  }
  .search:focus {
      @apply border-dundyOrange;
    }

  li.disabled {
    @apply text-gray-500 hover:bg-transparent cursor-default;
  }
</style>