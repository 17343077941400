import { derived, get, type Readable, writable, type Writable } from 'svelte/store'
import initializationStore from '../../core-app/stores/initialization.store'
import type GlobalInitializationStore from '../../core-app/models/initialization-store'
import { encrypt256Hash } from '../../core-app/util/encryption'
import { eventsManager } from '../../core-app/events/event-manager'
import { EventType } from '../../core-app/events/event-type'
import { isEqual } from 'lodash'
import type { CoreDocument } from '../models/business-document'
import { BusinessDocument, BusinessDocumentAllDataPersisted, CollateralDocument } from '../models/business-document'
import { BusinessDocumentViewListOption } from '../enums/business-documents-view-list-options'
import { deepClone } from '../../core-app/util/object-deep-cloning'
import {
  emitBusinessDocumentsChangedEvents,
  emitBusinessDocumentsMissingEvents
} from '../services/business-document-various-functions'
import { BusinessDocumentStatus } from '../enums/business-document-status'
import { BusinessDocumentState } from '../enums/business-document-state'
import { BusinessDocumentKind } from '../enums/business-document-kind'
import { TaxonomyTag } from '../enums/taxonomy-tag'

/**
 * Compare the new and current state
 * const currentBusinessDocumentList: CoreDocument[] = get(BusinessDocumentsStore); // Get the current state
 */
let currentBusinessDocumentAllDataPersistedList: BusinessDocumentAllDataPersisted[] = <BusinessDocumentAllDataPersisted[]>[] // <-- Define it outside the subscribe function
let isBusinessDocumentsListViewChoiceSubscriptionDefined: boolean = false

/**
 * Boolean flag to indicate if the store is initialized
 */
export const isFetchingBusinessStore: Writable<boolean> = writable(true)

const getFromLocalStorage = <T>(key: string, defaultValue: T = null): T[] | T => {
  const emptyValue: T[] = <T[]>[]
  if (!localStorage) {
    return defaultValue ?? emptyValue
  }
  const existingValue: string | null = localStorage.getItem(key)
  if (!existingValue || existingValue === 'undefined') return defaultValue ?? emptyValue
  if (['null', '', '{}'].includes(existingValue)) return defaultValue ?? emptyValue

  return defaultValue ? <T>existingValue : <T[]>JSON.parse(existingValue)
}

/**
 * STORES
 */
// TODO : BusinessAllDocuments (CoreDocument + CollateralDocument)
export let BusinessDocumentsAllDataPersistedStore: Writable<BusinessDocumentAllDataPersisted[]> = writable<BusinessDocumentAllDataPersisted[]>([])
// TODO : CoreDocument (...BusinessDocument)
export let BusinessDocumentsStore: Readable<CoreDocument[]> = derived(
  BusinessDocumentsAllDataPersistedStore,
  ($store: BusinessDocumentAllDataPersisted[]) => {
    if (!$store) return []

    return $store.map((d: BusinessDocumentAllDataPersisted) => d.coreDocument)
  }
)
// TODO : CollateralDocument
export let CollateralDocumentsStore: Readable<CollateralDocument[]> = derived(
  BusinessDocumentsAllDataPersistedStore,
  ($store: BusinessDocumentAllDataPersisted[]) => {
    if (!$store) return []

    return $store.map((d: BusinessDocumentAllDataPersisted) => d.collateralDocument)
  }
)
// TODO BusinessDocument
export let SimpleDocumentsStore: Readable<BusinessDocument[]> = derived(
  BusinessDocumentsStore,
  ($businessDocumentsStore: CoreDocument[]) => {
    if (!$businessDocumentsStore) return []

    return $businessDocumentsStore.map<BusinessDocument>((coreDocument: CoreDocument) => coreDocument.businessDocument)
  },
)

const getBusinessDocumentStateFromKind = (d: BusinessDocument): BusinessDocumentState => {
  const isDraft = d.businessDocumentStatus === BusinessDocumentStatus.DRAFT

  switch (d.businessDocumentKind) {
    case BusinessDocumentKind.CREDITNOTE: return isDraft ? BusinessDocumentState.CREDIT_NOTE_DRAFT : BusinessDocumentState.CREDIT_NOTE
    case BusinessDocumentKind.INVOICE: return isDraft ? BusinessDocumentState.INVOICE_DRAFT : BusinessDocumentState.INVOICE
    case BusinessDocumentKind.PURCHASEORDER: return isDraft ? BusinessDocumentState.PURCHASE_ORDER_DRAFT : BusinessDocumentState.PURCHASE_ORDER
    case BusinessDocumentKind.QUOTE: return isDraft ? BusinessDocumentState.QUOTE_DRAFT : BusinessDocumentState.QUOTE
  }
}

export const HistoryStore = derived(SimpleDocumentsStore, ($store: BusinessDocument[]) => {
  const res: Map<string, string>[] = []

  if (!$store) return res

  $store.forEach(d => {
    const id = d.businessDocumentId
    const isDraft = d.businessDocumentStatus === BusinessDocumentStatus.DRAFT
    const links = $store.filter(s => s.relatedBusinessDocuments?.find(bd => bd.toBusinessDocumentId === id || bd.fromBusinessDocumentId === id))

    const tmp = new Map()

    if (!links.length) {
      const state = getBusinessDocumentStateFromKind(d)
      tmp.set(id, { state })
    } else {
      let isVoidedInvoice: boolean = false
      let isVoidedCreditNote: boolean = false

      // CHECK IF VOIDED
      const tmpVoided = links.find(l => l.relatedBusinessDocuments.find(r => r.fromBusinessDocumentId === id))
      if (tmpVoided) {
        if (d.businessDocumentKind === BusinessDocumentKind.INVOICE) {
          isVoidedInvoice = (tmpVoided.relatedBusinessDocuments.find(e => e.fromBusinessDocumentId === id)?.toBusinessDocumentTaxonomyTags?.at(-1) ?? '') === 'VoidingCreditNote'
        } else if (d.businessDocumentKind === BusinessDocumentKind.CREDITNOTE) {
          isVoidedCreditNote = (tmpVoided.relatedBusinessDocuments.find(e => e.fromBusinessDocumentId === id)?.toBusinessDocumentTaxonomyTags?.at(-1) ?? '') === 'CorrectiveInvoice'
        }
      }

      // CHECK IF VOIDING
      const isVoidingInvoice = d.taxonomyTags?.some(t => [TaxonomyTag.CORRECTIVE_INVOICE].includes(t))
      const isVoidingCreditNote = d.taxonomyTags?.some(t => [TaxonomyTag.VOIDING_CREDIT_NOTE].includes(t))

      if (isVoidedInvoice || isVoidedCreditNote) {
        tmp.set(id, { state: isDraft ? BusinessDocumentState.PENDING_VOIDED : BusinessDocumentState.VOIDED })
      } else if (isVoidingInvoice || isVoidingCreditNote) {
        tmp.set(id, { state: isDraft ? BusinessDocumentState.PENDING_VOIDING : BusinessDocumentState.VOIDING })
      } else {
        let state = BusinessDocumentState.INVOICE // getBusinessDocumentStateFromKind(d)
        if (d.businessDocumentKind === BusinessDocumentKind.INVOICE) state = BusinessDocumentState.INVOICE
        if (d.businessDocumentKind === BusinessDocumentKind.CREDITNOTE) state = BusinessDocumentState.CREDIT_NOTE

        tmp.set(id, { state })
      }
    }

    if (tmp.size) res.push(tmp)
  })

  return res
})

// export let businessDocumentsListViewChoice: Writable<BusinessDocumentViewListOption> = writable(<BusinessDocumentViewListOption>getFromLocalStorage('businessDocumentsListViewChoice', BusinessDocumentViewListOption.INVOICES))
export let businessDocumentsListViewChoice: Writable<BusinessDocumentViewListOption> = writable<BusinessDocumentViewListOption>(BusinessDocumentViewListOption.INVOICES)

/**
 * Initialize the BusinessDocumentsStore
 * - Uses the safelyGetArray utility function to ensure the input data is an array.
 * - Sets the isFetchingBusinessStore flag to false.
 * - Sets the BusinessDocumentsStore to the input data.
 * @param data
 */
export const initializeBusinessDocumentsAllDataPersistedStore = (data: BusinessDocumentAllDataPersisted[]): void => {
  if (get(initializationStore).businessDocumentsStoreInitialized) return

  initializationStore.update((store: GlobalInitializationStore) => {
    store.businessDocumentsStoreInitialized = true

    return store
  })

  BusinessDocumentsAllDataPersistedStore.set(data)
  isFetchingBusinessStore.set(false)

  /* console.log('### subscribing to BusinessDocumentsStore') */
  let isBusinessDocumentsStoreSubscriptionDefined: boolean = false

  eventsManager.on(EventType.AGGRID_ADD_TEMPORARY_DOCUMENT, e => AddTempBusinessDocument(e.data))

  // Subscribe to the TodosStore, handling updates with the extracted functions and emitting appropriate events.
  BusinessDocumentsAllDataPersistedStore.subscribe((newList: BusinessDocumentAllDataPersisted[]): void => {

    /* console.log('BusinessDocumentsStore.subscribe, store updated') */
    if (!isBusinessDocumentsStoreSubscriptionDefined) {
      /* console.log('BusinessDocumentsStore subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently') */
      isBusinessDocumentsStoreSubscriptionDefined = true
      currentBusinessDocumentAllDataPersistedList = deepClone(newList)

      return
    }
    /* console.log('Before isEqual statement') */
    /* console.log(isEqual(newBusinessDocumentList, currentBusinessDocumentList), 'isEqual(newBusinessDocumentList, currentBusinessDocumentList)') */

    if (isEqual(newList, currentBusinessDocumentAllDataPersistedList)) return // Compare the states using lodash's isEqual function
    /* console.log('Inside isEqual statement') */
    /* console.log('currentBusinessDocumentList:', currentBusinessDocumentList) */
    /* console.log('newBusinessDocumentList:', newBusinessDocumentList) */

    const validatedNewBusinessDocumentList: BusinessDocumentAllDataPersisted[] = newList

    if (validatedNewBusinessDocumentList?.length) {
      /* console.log('Condition #1 - validatedNewBusinessDocumentList.length:', validatedNewBusinessDocumentList.length) */
      /* console.log('Condition #1 - currentBusinessDocumentList.length:', currentBusinessDocumentList.length) */
      if (currentBusinessDocumentAllDataPersistedList) {
        const currentBusinessDocumentListHash: string = encrypt256Hash(currentBusinessDocumentAllDataPersistedList)
        const newBusinessDocumentListHash: string = encrypt256Hash(validatedNewBusinessDocumentList)

        if (currentBusinessDocumentListHash !== newBusinessDocumentListHash) {
          emitBusinessDocumentsChangedEvents(currentBusinessDocumentAllDataPersistedList.map((b: BusinessDocumentAllDataPersisted) => b.coreDocument.businessDocument), validatedNewBusinessDocumentList.map((b: BusinessDocumentAllDataPersisted) => b.coreDocument.businessDocument))
          // emitBusinessDocumentsMissingEvents(currentBusinessDocumentAllDataPersistedList.map((b: BusinessDocumentAllDataPersisted) => b.coreDocument), validatedNewBusinessDocumentList.map((b: BusinessDocumentAllDataPersisted) => b.coreDocument))
          emitBusinessDocumentsMissingEvents(currentBusinessDocumentAllDataPersistedList, validatedNewBusinessDocumentList)

          currentBusinessDocumentAllDataPersistedList = deepClone(newList) // <-- Update the current state after handling the update
          // setTimeout(() => {
          eventsManager.emit(EventType.BUSINESS_DOCUMENTS_LIST_CHANGED, validatedNewBusinessDocumentList, 'BusinessDocumentsStore')
          // }, 3000)
        }
      }
    } else {
      /* console.log('Condition #2 - validatedNewBusinessDocumentList.length:', validatedNewBusinessDocumentList.length) */

      currentBusinessDocumentAllDataPersistedList = deepClone(newList) // <-- Update the current state after handling the update
      eventsManager.emit(EventType.BUSINESS_DOCUMENTS_LIST_CHANGED, [], 'BusinessDocumentsStore')
    }
  })
  /* console.log('%c BusinessDocumentsStore initialized.', 'color: #8A99AC') */
}

// NB: understanding the 'strange' behaviour of .subscribe: when we .subscribe to a svelte store variable,
// then it is executed immediately (including when the store.ts file is imported at the beginning of the web app)
// and its argument is whatever the store variable contains at this point
businessDocumentsListViewChoice.subscribe((val: BusinessDocumentViewListOption) => {
  if (!isBusinessDocumentsListViewChoiceSubscriptionDefined) {
    /* console.log('businessDocumentsListViewChoice subscribing and executing it at subscribe time: blocked here only at subscription time, but allowed subsequently') */
    isBusinessDocumentsListViewChoiceSubscriptionDefined = true

    return // we avoid the .subscribe() execution at the subscription occurrence
  }
  localStorage.setItem('businessDocumentsListViewChoice', val)
})


export const AddTempBusinessDocument = ({ businessDocument, invoiceCollateralData }): void => {
  businessDocument.businessDocumentStatus = BusinessDocumentStatus.FINAL

  const doc: BusinessDocumentAllDataPersisted = {
    collateralDocument: {
      businessDocumentCollateralData: invoiceCollateralData,
      modifiedDate: null,
      requesterId: null,
      workspaceId: null
    },
    coreDocument: {
      businessDocument,
      requesterId: null,
      workspaceId: null,
      modifiedDate: null
    }
  }

  BusinessDocumentsAllDataPersistedStore.update((store: BusinessDocumentAllDataPersisted[]) => [...store, doc])
}
