<script lang="ts">
  import { convertHtmlToRaw } from '$src/dundy-app/services/dunning-template.service'
  import { createEventDispatcher, tick } from 'svelte'
  import { stringNormalize } from '$src/shared/utils/string'
  import type { Variables } from '$src/shared/types/variable'

  export let multiline:boolean = true
  export let placeholder:string = ''
  export let value:string = ''
  export let error:string = ''
  export let allowInject:boolean = true
  export let allowPlaceholderEdition:boolean = true
  export let placeholders:Readonly<Variables> = []
  export let variables:Readonly<Variables> = []
  export let placeholderOpenChr:string = '{'
  export let placeholderCloseChr:string = '}'
  export let getCurrentWord:boolean = true
  export let editable:boolean = true
  export let clearBeforeInject:boolean = false

  type Caret = { element: Node | null, startOffset: number, endOffset: number }
  
  const dispatch = createEventDispatcher()
  
  let editorHtml: HTMLDivElement
  let editor: HTMLDivElement
  let editorPlaceholder: HTMLDivElement
  let valueHtml:string = ''
  let valueBeforeFormat: string = ''
  let caret:Caret = { element: null, startOffset: 0, endOffset: 0 }
  let focus: boolean = false
  let select:Selection | null = null

  /**
   * GET CURRENT CARET POSITION AND CURRENT ELEMENT
   */
  const getCaretPositionAndElement = () => {
    const selection = window.getSelection()
    if (!selection || selection?.rangeCount === 0) return 
    
    select = selection

    const range = selection.getRangeAt(0)
    
    // range must be inside editor DOM element
    const commonAncestorContainer = range.commonAncestorContainer
    if (!editor.contains(commonAncestorContainer)) {
      caret.startOffset = 0
      
      return
    }
    
    caret = { element: range.startContainer, startOffset: range.startOffset, endOffset: range.endOffset }
  }

  /**
   * SET CARET OFFSER POSITION IN ELEMENT
   * @param element
   * @param offset
   */
  const setCaretPositionAndElement = (element:Node, offset:number) => {
    if (!element) return

    const range = document.createRange()

    range.setStart(element, offset)
    range.setEnd(element, offset)
    
    let selection = window.getSelection()
    if (!selection) return 
    selection.removeAllRanges()
    selection.addRange(range)
  }

  /**
   * CHECK IF USER CAN INJECT PLACEHOLDER (FORBIDEN BETWEEN EXISTING PLACEHOLDER)
   */
  const checkAllowInject = () => {
    allowInject = true

    const text:string | null | undefined = caret?.element?.textContent
    if (!text) return
    
    const beforeCursor = text.slice(0, caret.startOffset)
    const afterCursor = text.slice(caret.startOffset)
    const lastOpenBrace = beforeCursor.lastIndexOf(placeholderOpenChr)
    const firstCloseBrace = afterCursor.indexOf(placeholderCloseChr)
    const countOpenBraceBefore = beforeCursor.split(placeholderOpenChr).length - 1
    const countCloseBraceBefore = beforeCursor.split(placeholderCloseChr).length - 1
    const countOpenBraceAfter = afterCursor.split(placeholderOpenChr).length - 1
    const countCloseBraceAfter = afterCursor.split(placeholderCloseChr).length - 1

    const countOpenBraceRemain = countOpenBraceBefore - countCloseBraceBefore
    const countCloseBraceRemain = countOpenBraceAfter - countCloseBraceAfter
    
    if (countOpenBraceRemain === 0 && countCloseBraceRemain === 0) return
    if (lastOpenBrace === -1 || firstCloseBrace === -1) return

    if (lastOpenBrace < caret.startOffset && caret.startOffset <= (caret.startOffset + firstCloseBrace)) allowInject = false
  }

  /**
   * COLORIZE PLACEHOLDERS
   */
  const colorize = () => {
    valueHtml = value
    
    if (!valueHtml) return
    
    let rxVars:RegExp
    let vars:string[] | RegExpMatchArray | null | undefined
    
    // placeholders
    if (placeholders.length) {
      rxVars = new RegExp(`${placeholderOpenChr}\\S*?${placeholderCloseChr}`, 'gm')
      vars = valueHtml.match(rxVars)?.filter(v => placeholders.findIndex(p => p.key === v) >= 0)
      
      if (vars) vars.forEach(v => valueHtml = valueHtml.replaceAll(v,
        `<span style='color: #6738ea'>${v}</span>`
      ))
    }

    // variables
    if (variables.length && focus) {
      rxVars = new RegExp(variables.map(v => v.key.replaceAll('.', '\.')).join('|'), 'gm')
      vars = valueHtml.match(rxVars)
      
      if (vars) vars.forEach(v => valueHtml = valueHtml.replaceAll(v,
        `<span style='color: #6738ea'>${v}</span>`
      ))
    }
  }

  /**
   * FORMAT VARIABLES (i.e usernames from email)
   */
  const formatting = async () => {
    if (!variables.length) return
    if (valueHtml === '') return

    await tick() // wait component to be render to use innerText instead value HTML
    
    const lines = editor?.innerText?.split('\n')
    
    if (!lines) return

    const separators = /[,; ]+/
    const replacers = new Map()

    lines.forEach(line => {
      const parts = line.split(separators)

      parts.forEach(part => {
        if (part) {
          const p = part.trim().replaceAll('&nbsp;', '')
          const entity = variables.find(e => stringNormalize(e.key) === stringNormalize(p))

          if (entity && !replacers.has(p)) replacers.set(p, entity)
        }
      })
    })

    replacers.forEach(r => valueHtml = valueHtml.replaceAll(r.key, 
      `<div style='position:relative; display:inline-block; background-color:#F8FAFC; border:1px solid #D8D8D8; border-radius:5px; padding:0 6px 0 1.7rem; margin-top:-1px'><img src="/img/icons/braces-with-dots.svg" style="position:absolute; top:.4rem; left:.4rem" />${r.value}</div>`))
  }

  /**
   * GET CURRENT WORD BETWEEN SEPARATORS
   * @param {string} text
   * @param {number} position
   * @param {RegExp} separators (ex : /\s|[.,;:!?]/)
   */
  const getCurrentWordAtPosition = (text:string, position:number, separators:RegExp = /[,]/) => {
    if (position === text.length && separators.test(text[position - 1])) return ''

    let start = position
    while (start > 0 && !separators.test(text[start - 1])) start--

    let end = position
    while (end < text.length && !separators.test(text[end])) end++
    
    return text.substring(start, end)
  }

  /**
   * GET RANGE POSITION FOR CURRENT WORD EDITING BETWEEN SEPARATORS
   * @param {string} text
   * @param {number} position
   * @param {RegExp} separators (ex : /\s|[.,;:!?]/)
   */
  const getRangePositionForCurrentWord = (text:string, position:number, separators:RegExp = /[,]/) => {
    let start = position
    while (start > 0 && !separators.test(text[start - 1])) start--

    let end = position
    while (end < text.length && !separators.test(text[end])) end++

    return { start, end }
  }

  const onClick = () => {
    getCaretPositionAndElement()
    checkAllowInject()
  }

  const onFocus = () => {
    dispatch('focus', arguments[0]) // arguments[0] = get current instance component

    focus = true
    valueHtml = valueBeforeFormat
    colorize()
  }

  const onBlur = (e:any) => {
    dispatch('blur', { e: arguments[0], value: e.srcElement.innerText }) // arguments[0] = get current instance component

    focus = false
    valueBeforeFormat = valueHtml
    colorize()
    formatting()
  }
  
  const onScroll = () => {
    editorHtml.scrollTop = editor.scrollTop
    editorHtml.scrollLeft = editor.scrollLeft
  }

  const onPaste = (e:any) => {
    e.preventDefault()
    if (!editable) return


    let data = e.clipboardData.getData('text')

    if (!data) return

    // clean allow DOM element (div, p, span)
    data = convertHtmlToRaw(data)

    // clean HTML
    data = data.replace(/<[^>]+>/g, '')

    // remove multiple \n
    data = data.replace(/(\n\s*\n){2,}/g, '\n').trim()
    
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    injectPlaceholder(data)
  }

  const onMouseEvent = () => getCaretPositionAndElement()

  const onKeyDown = (e:KeyboardEvent) => {
    if (!editable || !multiline && e.key === 'Enter') {
      e.stopImmediatePropagation()
      e.preventDefault()
    }
  }

  const onKeyUp = (e:KeyboardEvent) => {
    if (!editable || (!multiline && e.key === 'Enter')) {
      e.stopImmediatePropagation()
      e.preventDefault()
      
      return
    }
    
    getCaretPositionAndElement()
    checkAllowInject()
    
    if (editor && getCurrentWord && value !== placeholder) {
      const word = getCurrentWordAtPosition(editor.innerText, caret.startOffset)
      dispatch('changeWord', word?.trim())
    }
  }

  const onKeyDownPlaceholder = async (e:KeyboardEvent) => {
    if (['Backspace', 'Delete'].includes(e.key)) {
      e.preventDefault()
      
      return
    }

    if (e.metaKey || e.altKey || e.ctrlKey || ['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'].includes(e.key)) {
      return
    }

    editor.focus()

    await tick()
    setCaretPositionAndElement(editor.childNodes[0], 1)
  }

  const onCutPlaceholder = (e:any) => {
    e.preventDefault()
  }

  const onPastePlaceholder = (e:any) => {
    e.preventDefault()
    editor.focus()
    onPaste(e)
  }

  const onValueChange = async (newValue:string, e:HTMLDivElement) => {
    // placeholder edition
    if (allowPlaceholderEdition && (!newValue || !newValue.trim())) {
      newValue = convertHtmlToRaw(placeholder)
      value = newValue
    }

    valueHtml = newValue
    
    if (newValue !== placeholder) {
      if (newValue) {
        colorize()
        if (!focus && e) formatting()
      } else {
        if (getCurrentWord) dispatch('changeWord', '')
        await tick()
        if (editorPlaceholder && focus) editorPlaceholder.focus()
      }
    }

    dispatch('change', newValue)
  }

  $: onValueChange(value, editor)

  /**
   * ALLOWS YOU TO INJECT A PLACEHOLDER FROM AN EXTERNAL COMPONENT
   * @param placeholder
   */
  export const injectPlaceholder = (placeholderKey:string, separator: string = '', replaceWord:boolean = false) => {
    if (!caret.element && value || !placeholderKey) return

    // remove selection
    select?.deleteFromDocument()
    if (clearBeforeInject) {
      value = ''
      caret.element = editor
      caret.startOffset = 0
      caret.endOffset = 0
    }

    // define variables
    const node = caret.element ?? editor
    const data = node?.nodeValue
    let startOffset = data ? caret.startOffset : 0
    let endOffset = data ? caret.endOffset : 0
    let txt:string = placeholderKey + separator

    // if current word must be replace, get full content between separator
    if (replaceWord) {
      const pos = getRangePositionForCurrentWord(editor.innerText, caret.startOffset)
      startOffset = pos.start
      endOffset = pos.end
    }
    
    // get strings before and after
    const beforeText = data ? data.substring(0, startOffset) : ''
    const afterText = data ? data.substring(endOffset) : ''

    // check separator and add before/after if needed
    if (separator) {
      // get last character from beforeText to define if separator is needed before / after text
      let start:number = startOffset
      while (start > 0 && beforeText[start - 1] === ' ') start--

      const lastCharForBeforeText = beforeText[start - 1]
      const firstCharForAfterText = afterText[0]
      
      if (lastCharForBeforeText && lastCharForBeforeText !== separator) txt = separator + txt
      if (firstCharForAfterText === separator) txt = txt.slice(0, -1) // remove separator
    }

    node.textContent = beforeText + txt + afterText
    
    // get good node
    let nodeElement:Node
    switch (node.nodeType) {
      default: case Node.TEXT_NODE: nodeElement = node
        break
      case Node.ELEMENT_NODE: nodeElement = node.firstChild as Node
        break
    }

    // set caret
    setCaretPositionAndElement(nodeElement, startOffset + txt.length)
    getCaretPositionAndElement()
    
    if (replaceWord && getCurrentWord) dispatch('changeWord', getCurrentWordAtPosition(editor.innerText, startOffset + txt.length))

    value = editor.innerHTML
  }

</script>
  <div class="relative size-full {multiline ? 'size-full' : 'w-full h-10'} {error ? 'mb-2' : ''}">
    <div
      class="editor editor-back"
      class:editor-multiline={multiline}
      class:editor-default={value === placeholder}
      contenteditable="true"
      role="textbox"
      spellcheck="false"
      tabindex=0
      bind:this={editorHtml}
      bind:innerHTML={valueHtml}
    />
    
    <div
      class="editor editor-front"
      class:editor-multiline={multiline}
      class:editor-error={error}
      contenteditable="true"
      role="textbox"
      spellcheck="false" 
      tabindex=0
      bind:this={editor}
      bind:innerHTML={value}
      on:focus={() => onFocus()}
      on:blur={(e) => onBlur(e)}
      on:click={() => onClick()}
      on:keydown={(e)=> onKeyDown(e)}
      on:keyup={(e)=> onKeyUp(e)}
      on:scroll={()=> onScroll()}
      on:paste={(e) => onPaste(e)}
      on:mouseup={() => onMouseEvent()}
      on:mouseleave={() => onMouseEvent()}
    >
      {value}
    </div>

    {#if !value}
      <div
        class="editor editor-placeholder"
        class:editor-multiline={multiline}
        contenteditable="true"
        role="textbox"
        tabindex=0
        bind:this={editorPlaceholder}
        bind:innerHTML={placeholder}
        on:focus={() => onFocus()}
        on:keydown={(e) => onKeyDownPlaceholder(e)}
        on:cut={(e) => onCutPlaceholder(e)}
        on:paste={(e) => onPastePlaceholder(e)}
      />
    {/if}

  </div>

  <div class="error">
    {error}
  </div>

<style lang="postcss">
  .editor {
    @apply absolute w-full h-10 overflow-hidden whitespace-pre rounded outline-none px-3 py-2;

    &-multiline {
      @apply overflow-auto size-full min-h-52;
      line-height: 1.5;
    }

    &-back {
      @apply bg-white text-black caret-transparent z-10;
    }

    &-front {
      @apply bg-transparent text-transparent caret-black z-20 border border-loblolly focus:ring-1 focus:ring-dundyOrange focus:border-dundyOrange
    }

    &-placeholder {
      @apply text-gray-400 z-20;
    }

    &-default {
      @apply text-gray-400;
    }

    &-error {
      @apply w-full ring-1 ring-cabaret border-cabaret;
    }
  }

  .error {
    @apply text-cabaret text-sm;
  }
</style>