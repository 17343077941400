import { t } from '../../../core-app/lib/i18n/i18nextWrapper'
import { formatCurrencyWithOptions } from '../../utils/number-formatting-utils'
import { extractCountryNamePurely } from '../../../core-app/lib/i18n/i18n-country'
import { formatToDateWithSlashes } from '../../../core-app/util/date-utils'
import { substTiphaine } from '../specialOptalis/tiphaine.service'
import htmlToPdfMake from 'html-to-pdfmake'
import type { BusinessDocument } from '../../models/business-document'
import { BusinessDocumentKind } from '../../enums/business-document-kind'
import { get } from 'svelte/store'
import { ProfileStore } from '../../../core-app/stores/profile.store'
import {
  getBusinessDocumentMainTitle
} from '../business-documents-functions'
import type { Content, TDocumentDefinitions } from 'pdfmake/interfaces'
import { getAmountOfInstallmentValueLabel } from '../installment/installment.service'
import {
  getBusinessDocumentSubjectMainTitleMainDescriptionRelatedDocumentsListDescription
} from '../business-document-description/business-document-subject-main-title-main-description.service'
import type BusinessDocumentInstallmentRow from '../../models/business-documents-installments.model'
import { generateInstallmentRecapTableRows } from '../installment/installment-recap-table-rows.service'
import { SimpleDocumentsStore } from '../../stores/business-documents.store'

/**
 * Put a string in a span tag
 * Used by Description column in the invoice
 * @param string
 */
function putStringInSpanTag(string: string): string {
  return '<span>' + string + '</span>'
}

function makePayButton(showPaymentLink: boolean, text: string = '', width: number = 30, link: string = '') {
  let canvas = {
    type: 'rect',
    x: 0,
    y: 0,
    w: width,
    h: 22,
    r: 4,
    color: '#F47663',
    lineColor: '#F47663'
  }
  let column = {
    text,
    link,
    width,
    color: '#FFFFFF',
    alignment: 'center',
    noWrap: true,
    maxHeight: 16,
    bold: true
  }

  if (showPaymentLink && text && !['', 'no payment link'].includes(link)) {
    return {
      stack: [
        { canvas: [canvas] },
        {
          columns: [column],
          relativePosition: { y: -17 },
          style: { fontSize: 10, bold: true }
        }
      ],
      margin: [80, 40, 40, 0]
    }
  }
}

const generateBankList = ({ businessDocument }) => {
  let bankList: any[] = []
  bankList.push([
    [
      {
        lineHeight: 1.5,
        text: `${t('invoices.createInvoice.beneficiaryCompanyName')}`,
        color: '#C1C8D1',
        bold: false,
        fontSize: 8
      },
      {
        lineHeight: 1.5,
        text: `${t('invoices.createInvoice.beneficiaryIBANLabel')}`,
        color: '#C1C8D1',
        bold: false,
        fontSize: 8
      },
      {
        lineHeight: 1.5,
        text: `${t('invoices.createInvoice.beneficiaryBICLabel')}`,
        color: '#C1C8D1',
        bold: false,
        fontSize: 8
      },
      {
        lineHeight: 1.5,
        text: `${t('invoices.createInvoice.invoiceReferenceLabel')}`,
        color: '#C1C8D1',
        bold: false,
        fontSize: 8
      }
    ],
    [
      {
        lineHeight: 1.5,
        text: businessDocument.accountBankingInformation.bankAccountHolder,
        bold: false,
        fontSize: 8
      },
      {
        lineHeight: 1.5,
        text: businessDocument.accountBankingInformation.bankAccountIBAN,
        bold: false,
        fontSize: 8
      },
      {
        lineHeight: 1.5,
        text: businessDocument.accountBankingInformation.bankAccountBIC,
        bold: false,
        fontSize: 8
      },
      {
        lineHeight: 1.5,
        text: businessDocument.accountBankingInformation.bankWireReference,
        bold: false,
        fontSize: 8
      }
    ]
  ])

  return bankList
}

const generateInstallmentList = (businessDocument: BusinessDocument, allBusinessDocuments: BusinessDocument[], locale: string) => {
  let installmentList: any[] = []

  let installmentDataTableTitle: any
  switch (businessDocument.businessDocumentKind) {
    case BusinessDocumentKind.INVOICE:
      installmentDataTableTitle = {
        text: `${t('invoices.createInvoice.detailsOfFirstInstallmentInvoice')}`,
        style: 'tableHeader',
        alignment: 'left',
        bold: true,
        fontSize: 9,
        color: '#1F2533'
      }
      break
    case BusinessDocumentKind.CREDITNOTE:
      installmentDataTableTitle = {
        text: `${t('invoices.createInvoice.detailsOfFirstInstallmentCreditNote')}`,
        style: 'tableHeader',
        alignment: 'left',
        bold: true,
        fontSize: 9,
        color: '#1F2533'
      }
      break
    default:
      installmentDataTableTitle = {
        text: `${t('invoices.createInvoice.detailsOfFirstInstallmentOtherBusinessDocument')}`,
        style: 'tableHeader',
        alignment: 'left',
        bold: true,
        fontSize: 9,
        color: '#1F2533'
      }
      break
  }

  installmentList.push([
    installmentDataTableTitle,
    {
      text: `${t('invoices.createInvoice.totalPriceNoTaxLabel')}`,
      style: 'tableHeader',
      alignment: 'right',
      bold: true,
      fontSize: 9,
      color: '#1F2533'
    },
    {
      text: `${t('invoices.createInvoice.finalPriceTaxLabel')}`,
      style: 'tableHeader',
      alignment: 'right',
      bold: true,
      fontSize: 9,
      color: '#1F2533'
    },
    {
      text: `${t('invoices.createInvoice.finalTotalPriceWithTaxLabel')}`,
      style: 'tableHeader',
      alignment: 'right',
      bold: true,
      fontSize: 9,
      color: '#1F2533'
    }
  ])

  const installmentRows: BusinessDocumentInstallmentRow[] = generateInstallmentRecapTableRows(businessDocument, allBusinessDocuments, locale, true, true, true, false, true)
  /* console.log('pdfmake-voxy-invoice-definitions.ts generateInstallmentList generateInstallmentRecapTableRows installmentRows gave', installmentRows) */
  for (let aBusinessDocumentInstallmentRow of installmentRows) {
    const aRow: {
      text: string,
      alignment: string,
      border: [boolean, boolean, boolean, boolean],
      noWrap: boolean,
      fontSize: number,
      color: string,
    }[] = [
        {
          text: `${aBusinessDocumentInstallmentRow?.installmentDescription}`,
          alignment: 'left',
          border: [false, true, false, true],
          noWrap: false,
          fontSize: 9,
          color: '#606F82'
        },
        {
          text: aBusinessDocumentInstallmentRow?.installmentResultExcludingTaxScaledValueString?.replace(/\u202f/g, ' '), // \u202f is narrow no-break space
          alignment: 'right',
          border: [false, true, false, true],
          noWrap: true,
          fontSize: 9,
          color: '#606F82'
        },
        {
          text: aBusinessDocumentInstallmentRow?.installmentResultTaxScaledValueString?.replace(/\u202f/g, ' '), // \u202f is narrow no-break space
          alignment: 'right',
          border: [false, true, false, true],
          noWrap: true,
          fontSize: 9,
          color: '#606F82'
        },
        {
          text: aBusinessDocumentInstallmentRow?.installmentResultIncludingTaxScaledValueString?.replace(/\u202f/g, ' '), // \u202f is narrow no-break space
          alignment: 'right',
          border: [false, true, false, true],
          noWrap: true,
          fontSize: 9,
          color: '#606F82'
        }
      ]
    installmentList.push(aRow)
  }

  return installmentList
}

// locale: like 'fr-FR' from t('locales')
const generateItemList = ({ businessDocument, isPaidWhenFinalized, locale }) => {
  let list: any[] = []
  list.push([
    { text: '' },
    {
      text: `${t('invoices.createInvoice.designationLabel')}`,
      style: 'tableHeader',
      bold: true,
      fontSize: 9,
      color: '#1F2533'
    },
    {
      text: `${t('invoices.createInvoice.quantityLabel')}`,
      style: 'tableHeader',
      bold: true,
      fontSize: 9,
      color: '#1F2533'
    },
    {
      text: `${t('invoices.createInvoice.unitPriceNoTaxLabel')}`,
      style: 'tableHeader',
      alignment: 'right',
      bold: true,
      fontSize: 9,
      color: '#1F2533'
    },
    {
      text: `${t('invoices.createInvoice.taxRateLabel')}`,
      style: 'tableHeader',
      alignment: 'right',
      bold: true,
      fontSize: 9,
      color: '#1F2533'
    },
    {
      text: `${t('invoices.createInvoice.totalPriceNoTaxLabel')}`,
      style: 'tableHeader',
      alignment: 'right',
      bold: true,
      fontSize: 9,
      color: '#1F2533'
    }
  ])

  businessDocument?.lineItems?.map((item: {
    title: any;
    description: any;
    supplierReference: any;
    quantity: any;
    taxRate: any;
    itemPrice: any;
    lineItemTotalExcludingTaxScaledValue: any;
  }): void => {

    const {
      title,
      description,
      supplierReference,
      quantity,
      taxRate,
      itemPrice,
      lineItemTotalExcludingTaxScaledValue
    } = item

    const descriptionWithAddedSpan: string = putStringInSpanTag(description)

    const supplierReferenceColumn = supplierReference ? [
      {
        stack: [
          {
            text: `${supplierReference} - ${title}`,
            fontSize: 8,
            bold: true
          },
          {
            stack: htmlToPdfMake(descriptionWithAddedSpan),
            alignment: 'justify',
            margin: [0, 5, 0, 0],
            fontSize: 8
          }
        ],
        border: [false, true, false, true]
      }
    ] : [
      {
        stack: [
          {
            text: `${title}`,
            fontSize: 8,
            bold: true
          },
          {
            stack: htmlToPdfMake(descriptionWithAddedSpan),
            alignment: 'justify',
            margin: [0, 5, 0, 0],
            fontSize: 8
          }
        ],
        border: [false, true, false, true]
      }
    ]

    const listRow = [
      { text: '', border: [false, true, false, true] },
      ...supplierReferenceColumn,
      {
        text: `${quantity} ${t('unitTypesList.' + itemPrice.unit, { count: quantity })}`,
        alignment: 'right',
        border: [false, true, false, true],
        noWrap: true,
        fontSize: 9,
        color: '#606F82'
      },
      {
        text: formatCurrencyWithOptions(itemPrice.scaledValue, locale, itemPrice.currency, true),
        alignment: 'right',
        border: [false, true, false, true],
        noWrap: true,
        fontSize: 9,
        color: '#606F82'
      },
      {
        text: `${taxRate.displayName}`,
        alignment: 'right',
        border: [false, true, false, true],
        noWrap: true,
        fontSize: 9,
        color: '#606F82'
      },
      {
        text: formatCurrencyWithOptions(lineItemTotalExcludingTaxScaledValue, locale, itemPrice.currency, true),
        alignment: 'right',
        border: [false, true, false, true],
        noWrap: true,
        fontSize: 9,
        color: '#606F82'
      }
    ]

    list.push(listRow)
  })


  list.push([
    {
      colSpan: 5,
      text: `${t('invoices.createInvoice.finalTotalPriceNoTaxLabel')}`,
      bold: false,
      alignment: 'right',
      fontSize: 9,
      color: '#606F82'
    },
    '',
    '',
    '',
    '',
    {
      text: formatCurrencyWithOptions(businessDocument.subtotalExcludingTaxScaledValue, locale, businessDocument.currency, true),
      bold: false,
      alignment: 'right',
      fontSize: 9,
      color: '#606F82'
    }
  ])

  if (businessDocument.totalDiscountExcludingTaxResultScaledValue) {
    list.push([
      {
        colSpan: 5,
        text: `${t('invoices.createInvoice.discountLabel')}`,
        bold: false,
        alignment: 'right',
        fontSize: 9,
        color: '#606F82'
      },
      '',
      '',
      '',
      '',
      {
        text: formatCurrencyWithOptions(businessDocument.totalDiscountExcludingTaxResultScaledValue, locale, businessDocument.currency, true),
        bold: false,
        alignment: 'right',
        fontSize: 9,
        color: '#606F82'
      }
    ])
  }

  list.push([
    {
      colSpan: 5,
      text: `${t('invoices.createInvoice.finalPriceTaxLabel')}`,
      bold: false,
      fontSize: 9,
      alignment: 'right',
      color: '#606F82'
    },
    '',
    '',
    '',
    '',
    {
      text: formatCurrencyWithOptions(businessDocument.totalTaxScaledValue, locale, businessDocument.currency, true),
      bold: false,
      alignment: 'right',
      fontSize: 9,
      color: '#606F82'
    }
  ])
  list.push([
    {
      colSpan: 5,
      text: `${t('invoices.createInvoice.finalTotalPriceWithTaxLabel')}`,
      bold: true,
      alignment: 'right',
      fontSize: 10,
      margin: [0, 1, 0, 5]
    },
    '',
    '',
    '',
    '',
    {
      text: formatCurrencyWithOptions(businessDocument.totalIncludingTaxScaledValue, locale, businessDocument.currency, true),
      fontSize: 10,
      bold: true,
      alignment: 'right'
    }
  ])

  if (businessDocument.hasSpecificInstallment) {
    list.push([
      {
        colSpan: 5,
        text: `${getAmountOfInstallmentValueLabel(businessDocument)}`,
        bold: true,
        alignment: 'right',
        fontSize: 10
      },
      '',
      '',
      '',
      '',
      {
        text: formatCurrencyWithOptions(businessDocument.installmentResultIncludingTaxScaledValue, locale, businessDocument.currency, true),
        bold: true,
        alignment: 'right',
        fontSize: 11
      }
    ])
  }

  if (isPaidWhenFinalized) {
    list.push([
      {
        colSpan: 5,
        text: `${t('invoices.createInvoice.amountDueScaledValueLabel')}`,
        bold: false,
        alignment: 'right',
        fontSize: 9,
        color: '#606F82'
      },
      '',
      '',
      '',
      '',
      {
        text: formatCurrencyWithOptions(businessDocument.amountDueScaledValue, locale, businessDocument.currency, true),
        bold: false,
        alignment: 'right',
        fontSize: 9,
        color: '#606F82'
      }
    ])
    list.push([
      {
        colSpan: 5,
        text: `${t('invoices.createInvoice.paidInvoiceLabel')}`,
        bold: false,
        alignment: 'right',
        fontSize: 9,
        color: '#606F82'
      },
      '',
      '',
      '',
      '',
      ''
    ])
  }

  return list
}

const generateInvoice = ({
  isPaidWhenFinalized,
  businessDocument,
  accountCompanyParsedFRSIREN,
  customerTVANumber
}) => {
  const invoice: any[] = []
  const customer = businessDocument.customerCustomer

  invoice.push({
    columns: [
      {
        text: `${t('invoices.createInvoice.accountInvoiceLabel')}`,
        bold: true,
        color: '#1F2533',
        fontSize: 9,
        alignment: 'left',
        margin: [0, 20, 0, 5]
      },
      {
        text: `${t('invoices.createInvoice.clientInvoiceLabel')}`,
        bold: true,
        color: '#1F2533',
        fontSize: 9,
        alignment: 'left',
        margin: [0, 20, 0, 5]
      }
    ]
  },
    {
      columns: [
        {
          text: `${businessDocument.accountCompany.formalName}`,
          bold: true,
          color: '#1F2533',
          alignment: 'left',
          fontSize: 10
        },
        {
          text: `${customer.company.formalName}`,
          bold: true,
          color: '#1F2533',
          alignment: 'left',
          fontSize: 10
        }

      ]
    },
    {
      columns: [
        {
          text: `${businessDocument.accountCompany.mailAddress.street}
                           ${businessDocument.accountCompany.mailAddress.zipCode}, ${businessDocument.accountCompany.mailAddress.city}
                           ${extractCountryNamePurely(t('countriesList.' + (businessDocument.accountCompany.mailAddress.countryCode).toLowerCase()))}
                           ${substTiphaine(businessDocument.accountCompany.companyId, 'businessDocumentFromEmail', businessDocument.accountContact.email)}
                           `,
          fontSize: 8,
          color: '#1F2533'
        },
        {
          text: `${customer.isPrivateIndividual ? '' : businessDocument.customerContact.firstName + ' ' + businessDocument.customerContact.lastName}
                ${customer.company.mailAddress.street}
                ${customer.company.mailAddress.zipCode}, ${customer.company.mailAddress.city}
                ${extractCountryNamePurely(t('countriesList.' + (customer.company.mailAddress.countryCode).toLowerCase()))}
                ${businessDocument.customerContact.email}
                ${businessDocument.customerContact.mobilePhone ? businessDocument.customerContact.mobilePhone : ''}`,
          fontSize: 8,
          color: '#1F2533'
        }
      ]
    },
  )
  /* ${ businessDocument.accountContact.mobilePhone ? businessDocument.accountContact.mobilePhone : '' } */

  let cols = []
  if (accountCompanyParsedFRSIREN) cols.push({
    text: `${t('invoices.createInvoice.sirenNumberLabel')} ${accountCompanyParsedFRSIREN}`,
    fontSize: 8
  })
  if (customerTVANumber) cols.push({
    text: `${t('invoices.createInvoice.vatNumberLabel')} ${customerTVANumber}`,
    fontSize: 8
  })

  if (cols.length) invoice.push({ columns: cols })

  if (getBusinessDocumentSubjectMainTitleMainDescriptionRelatedDocumentsListDescription(businessDocument, get(ProfileStore).lang).length > 0) {
    invoice.push({
      width: '100%',
      alignment: 'left',
      text: `${t('invoices.businessDocument.commonWords.description')}`,
      margin: [0, 10, 0, 10],
      fontSize: 10,
      bold: true
    })
    for (let aBusinessDocumentSubjectMainTitleMainDescriptionRelatedDocumentLine of getBusinessDocumentSubjectMainTitleMainDescriptionRelatedDocumentsListDescription(businessDocument, get(ProfileStore).lang)) {
      invoice.push({
        columns: [
          {
            text: `${aBusinessDocumentSubjectMainTitleMainDescriptionRelatedDocumentLine}`,
            fontSize: 10
          }
        ]
      })
    }
  }

  invoice.push({
    width: '100%',
    alignment: 'center',
    text: '',
    margin: [0, 10, 0, 10],
    fontSize: 15
  },
    '\n\n',
    {
      table: {
        widths: [0, '*', 'auto', 'auto', 'auto', 'auto'],
        headerRows: 1,
        body: generateItemList({ businessDocument, isPaidWhenFinalized, locale: t('locales') })
      },
      layout: defaultLayout
    })

  if (businessDocument.hasSpecificInstallment) {
    invoice.push({
      width: '100%',
      alignment: 'center',
      text: '',
      margin: [0, 10, 0, 10],
      fontSize: 15
    },
      '\n\n',
      {
        table: {
          widths: ['*', 'auto', 'auto', 'auto'],
          headerRows: 1,
          body: generateInstallmentList(businessDocument, get(SimpleDocumentsStore), t('locales'))
        },
        layout: defaultLayout
      })
  }

  return invoice
}

const defaultLayout = {

  defaultBorder: false,
  hLineColor: function (i: number): string {
    if (i === 1 || i === 0) {
      return '#eaeaea'
    }

    return '#eaeaea'
  },
  paddingLeft: function (): number {
    return 5
  },
  paddingRight: function (): number {
    return 5
  },
  paddingTop: function (): number {
    return 7
  },
  paddingBottom: function (): number {
    return 7
  },
  fillColor: function (): string {
    return '#fff'
  }
}

const setSvgOpacity = (svg: string, opacity: number) => {
  if (svg.includes('opacity=')) {
    return svg.replace(/opacity="[^"]*"/, `opacity="${opacity}"`)
  } else {
    return svg.replace('<svg', `<svg opacity="${opacity}"`)
  }
}

export const pdfMakeInvoiceDefinition = ({
  isPaidWhenFinalized,
  paymentURL,
  businessDocument,
  accountCompanyLogoBase64,
  accountCompanyParsedFRSIREN,
  customerTVANumber,
  showPaymentLink,
  backgroundImage = '',
  backgroundImageTransparency = 1,
  headerMargin = 0,
  headerTextColor = '',
  footerMargin = 0,
  footerTextColor = ''
}): TDocumentDefinitions => ({
  pageSize: 'A4',
  pageMargins: [40, 120 + headerMargin, 40, 168],
  background: function () {
    if (backgroundImage) {
      type Img = {
        width: number,
        opacity?: number,
        svg?: string,
        image?: string
      }

      const img: Img = {
        width: 595 // 100% width (A4: 595 points)
      }

      // SVG OR OTHER IMAGE
      if (backgroundImage.startsWith('data:image/svg+xml;base64')) {
        backgroundImage = atob(backgroundImage.replace('data:image/svg+xml;base64,', ''))
        img.svg = setSvgOpacity(backgroundImage, backgroundImageTransparency)
      } else {
        img.image = backgroundImage
        img.opacity = backgroundImageTransparency
      }

      return img as Content
    }
  },
  header: function () {
    if (!!accountCompanyLogoBase64 && accountCompanyLogoBase64 !== '') {
      return {
        margin: [0, headerMargin, 0, 0],
        table: {
          widths: ['*'],
          body: [
            [{
              fontSize: 10,
              border: [false, false, false, false],
              fillColor: backgroundImage ? '' : '#F6F5F9',
              alignment: 'left',
              columns: [
                {
                  image: accountCompanyLogoBase64,
                  width: 80,
                  margin: [30, 20, 0, 15]
                },
                '',
                [
                  {
                    text: `${getBusinessDocumentMainTitle(businessDocument.businessDocumentKind, businessDocument.taxonomyTags, businessDocument.hasSpecificInstallment)} ${businessDocument.businessDocumentNumber}`,
                    color: headerTextColor ?? '#1F2533',
                    width: '*',
                    fontSize: 16,
                    bold: true,
                    alignment: 'left',
                    margin: [0, 25, 0, 15]
                  },
                  {
                    columns: [
                      {
                        text: `${t('invoices.createInvoice.dateIssued')}`,
                        color: headerTextColor ?? '#1F2533',
                        width: '*',
                        fontSize: 9,
                        bold: true,
                        alignment: 'left'
                      },
                      {
                        text: `${t('invoices.createInvoice.dateDue')}`,
                        color: headerTextColor ?? '#1F2533',
                        width: '*',
                        fontSize: 9,
                        bold: true,
                        alignment: 'left'
                      }
                    ]
                  },
                  {
                    columns: [
                      {
                        /*text:      `${ invoiceIssuedDateReadable }`,*/
                        text: `${(formatToDateWithSlashes(new Date(businessDocument.issuedDate.unixSeconds * 1000)))}`,
                        color: headerTextColor ?? '#1F2533',
                        fontSize: 9,
                        alignment: 'left',
                        width: '*'
                      },
                      {
                        text: `${(formatToDateWithSlashes(new Date(businessDocument.dueDate.unixSeconds * 1000)))}`,
                        color: headerTextColor ?? '#1F2533',
                        fontSize: 9,
                        alignment: 'left',
                        width: '*'
                      }
                    ]
                  }
                ]
              ]
            }]]
        }
      }
    } else {
      return {
        margin: [0, headerMargin, 0, 0],
        table: {
          widths: ['*'],
          body: [[{
            fontSize: 10,
            border: [false, false, false, false],
            fillColor: backgroundImage ? '' : '#F6F5F9',
            alignment: 'left',
            columns: [
              [
                {
                  text: `${getBusinessDocumentMainTitle(businessDocument.businessDocumentKind, businessDocument.taxonomyTags, businessDocument.hasSpecificInstallment)} ${businessDocument.businessDocumentNumber}`,
                  color: headerTextColor ?? '#1F2533',
                  width: '*',
                  fontSize: 16,
                  bold: true,
                  alignment: 'left',
                  margin: [35, 25, 0, 15]

                },
                {
                  columns: [
                    {
                      text: `${t('invoices.createInvoice.dateIssued')}`,
                      color: headerTextColor ?? '#1F2533',
                      width: '*',
                      fontSize: 9,
                      bold: true,
                      alignment: 'left',
                      margin: [35, 0, 0, 0]
                    },
                    {
                      text: `${t('invoices.createInvoice.dateDue')}`,
                      color: headerTextColor ?? '#1F2533',
                      width: '*',
                      fontSize: 9,
                      bold: true,
                      alignment: 'left'
                    }
                  ]
                },
                {
                  columns: [
                    {
                      text: `${(formatToDateWithSlashes(new Date(businessDocument.issuedDate.unixSeconds * 1000)))}`,
                      color: headerTextColor ?? '#1F2533',
                      fontSize: 9,
                      alignment: 'left',
                      width: '*',
                      margin: [35, 0, 0, 20]
                    },
                    {
                      text: `${(formatToDateWithSlashes(new Date(businessDocument.dueDate.unixSeconds * 1000)))}`,
                      color: headerTextColor ?? '#1F2533',
                      fontSize: 9,
                      alignment: 'left',
                      width: '*'
                    }
                  ]
                }
              ]
            ]
          }]]
        }
      }
    }
  },
  content: generateInvoice({
    isPaidWhenFinalized,
    businessDocument,
    accountCompanyParsedFRSIREN,
    customerTVANumber
  }),
  footer: function (currentPage, pageCount) {
    const legalMentionVAT = businessDocument?.accountCompany?.regulatory?.euRegulScope?.euIntraVat
      ? t('invoices.createInvoice.vatNumberWithDataLabel', { tva: businessDocument?.accountCompany?.regulatory?.euRegulScope?.euIntraVat })
      : t('invoices.createInvoice.notEligibleToVAT')

    const legalMentionRCS = businessDocument?.accountCompany?.regulatory?.frRegulScope?.rcs ?? ''

    const legalMentions: string = [businessDocument.legalMentions, legalMentionVAT, legalMentionRCS].join('. ')

    if (businessDocument.businessDocumentKind === BusinessDocumentKind.INVOICE) {
      return {
        margin: [0, -footerMargin, 0, 0],
        table: {
          height: 180,
          headerRows: 1,
          widths: ['*'],
          body: [[{
            fontSize: 10,
            border: [false, false, false, false],
            fillColor: backgroundImage ? '' : '#F6F5F9',
            alignment: 'left',
            columns: [
              [
                {
                  text: `${t('invoices.createInvoice.paymentInformation')}`,
                  bold: true,
                  alignment: 'left',
                  margin: [40, 10, 0, 0],
                  fontSize: 8
                },
                {
                  table: {
                    widths: ['auto', 'auto'],
                    headerRows: 1,
                    body: generateBankList({ businessDocument }),
                    alignment: 'left'
                  },
                  layout: {
                    defaultBorder: false,
                    paddingLeft: function (): number {
                      return 10
                    },
                    paddingRight: function (): number {
                      return 10
                    },
                    paddingTop: function (): number {
                      return 4
                    },
                    paddingBottom: function (): number {
                      return 4
                    }
                  },
                  margin: [30, 0, 0, 0]
                }
              ],
              [
                makePayButton(showPaymentLink, 'Payer en ligne', 150, `${paymentURL}`)
              ]
            ]

          }],
          [{
            fontSize: 10,
            border: [false, false, false, false],
            fillColor: backgroundImage ? '' : '#F6F5F9',
            alignment: 'left',
            columns: [{
              text: legalMentions,
              alignment: 'left',
              fontSize: 7,
              bold: true,
              color: '#1F2533',
              width: 'auto',
              margin: [40, 0, 0, 0]
            }]
          }],
          [{
            fontSize: 10,
            border: [false, false, false, false],
            fillColor: backgroundImage ? '' : '#F6F5F9',
            alignment: 'left',
            columns: [{
              text: `${businessDocument.paymentConditions}`,
              alignment: 'left',
              fontSize: 7,
              color: '#606F82',
              width: 'auto',
              margin: [40, 0, 40, 0]
            }]
          }],
          [{
            fontSize: 10,
            border: [false, false, false, false],
            fillColor: backgroundImage ? '' : '#F6F5F9',
            alignment: 'left',
            columns: [
              {
                image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAA7EAAAOxAGVKw4bAAADcElEQVRYhbXXXYhVVRQH8N8cZCg9RGT4MEhIRIlYRD5FH0RQvUhlYF8mEmeIokAoPeZD4UNB7CKILBJnQ1RED2WpoRXRB/nQ11NFgVTEGGYxiMSZQaZhpodzrl1nzjn3XqX/091nr/9a/732x1p3yICY3DaazM3NjWAFLqg+n8A4Q+NpGJsdxN9QP0ZFng3jVqzHjbiwwfQ4PsM72JOGePKsBFSBH8B2jPQjtgt/4VnsbBPSKKDIsyvxOlYPGHg+DmNTGuKXfQso8uxeRJzTpA8v40PM4FpsxrIG+xk8lIY41lNAkWej2N3gCH7DTWmIP8/jLcNBXNXCfSwN8flGAUWercVeJA0OTmJNGuKPdZNFno3ge//djjpsSEN8szM4FajIs4uUe94UnPJA1QaHNMSjeLqFD7uLPFu5QABewvkNpCkcw64ezuG1yrZomF+MXUWeJVRbUOTZDfi0gTCOy9MQ/+4j+CkUebYY32BVg8ltaYj7Ohn4ARvxRqX+NAGDBoc0xCn82mKyjZpbMJlnyRxX4GbcguuxE3/ggPIg3lGZ142ncTuW4lEsahFxWc+nuMizLcoXDe7HBPZX403KOrC3Gm9U7v27vfxW2Np24js43qezM8E1temZfPzBZG72n9W4C4/8jwJWn9qCuR07ksmpI6uqoHfi0i7DV5RnYJ/ySt5dfX9PeQa6x9MVf2klvi3LU0NFnnUHXVljdCgN8brBFlaiyLP9WNtmk+BzPNkQHJZXd3rQ4MPKpqUN04mybrdhBY5M5qP9HNhu/KR3KZ9IlPW6F2aX1LRaRZ4tKvKsqWT305odTvBVH4ZNWfoCvzSI6JVZ+DrBB2chYJWyVasrYv0IOJgM8R0aS2yFiT6cDcr5HYeSJSHO4oUexk2rWaesFycG4HTwYhriTOclfBVbcUmDce1q0hA/aQnwZ8vcUWVPWb5SaYjTePgMnTWhbQs2pyEWdJXKNMSPijx7DltqCAvSWeTZxcqqeB7W17TdTVswlob4dmcw/3HZjj01pLrV3Ke8BcuVLXk/nI/Ny/RpAtIQZ3AP3ppHrFvNuV2/h2vm53PeV7Zh040CKhHT2KDMxkyLgF7oZGAWT2Fd1aadhtr3PQ1xNg3xGaxRpm3gpqRayAFcnYb4RJXdBejr33Edqt6+c22PpSF+eyZ+/gV1fwtPK+U3hQAAAABJRU5ErkJggg==',
                fit: [12, 12],
                border: [false, true, false, false],
                alignment: 'left',
                margin: [40, 5, 0, 0],
                width: 'auto'
              },
              {

                text: `${t('invoices.createInvoice.poweredWithDundy')}`,
                border: [false, true, false, false],
                alignment: 'left',
                color: footerTextColor ?? '#606F82',
                margin: [5, 7, 0, 5],
                fontSize: 7
              },
              {
                text: 'Page ' + currentPage.toString() + ' / ' + pageCount,
                border: [false, true, false, false],
                alignment: 'right',
                color: footerTextColor ?? '#606F82',
                margin: [0, 7, 40, 0],
                fontSize: 7
              }]
          }]
          ]
        },
        layout: {
          defaultBorder: false,
          paddingLeft: function (): number {
            return 0
          },
          paddingRight: function (): number {
            return 0
          },
          paddingTop: function (): number {
            return 4
          },
          paddingBottom: function (): number {
            return 4
          },
          hLineColor: function (i: number): string {
            if (i === 1 || i === 0) {
              return '#eaeaea'
            }

            return '#eaeaea'
          }
        }
      }
    } else {
      return {
        margin: [0, 80, 0, 0],
        table: {
          fillColor: backgroundImage ? '' : '#f2f2f2',
          height: ['*'],
          headerRows: 1,
          widths: ['*'],
          body: [[{
            fontSize: 10,
            border: [false, false, false, false],
            fillColor: backgroundImage ? '' : '#F6F5F9',
            alignment: 'left',
            columns: [{
              text: legalMentions,
              alignment: 'left',
              fontSize: 7,
              bold: true,
              color: footerTextColor ?? '#1F2533',
              width: 'auto',
              margin: [40, 0, 0, 0]
            }]
          }],
          [{
            fontSize: 10,
            border: [false, false, false, false],
            fillColor: backgroundImage ? '' : '#F6F5F9',
            alignment: 'left',
            columns: [{
              text: `${businessDocument.paymentConditions}`,
              alignment: 'left',
              fontSize: 7,
              color: footerTextColor ?? '#606F82',
              width: 'auto',
              margin: [40, 0, 40, 0]
            }]
          }],
          [{
            fontSize: 10,
            border: [false, false, false, false],
            fillColor: backgroundImage ? '' : '#F6F5F9',
            alignment: 'left',
            columns: [
              {
                image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAA7EAAAOxAGVKw4bAAADcElEQVRYhbXXXYhVVRQH8N8cZCg9RGT4MEhIRIlYRD5FH0RQvUhlYF8mEmeIokAoPeZD4UNB7CKILBJnQ1RED2WpoRXRB/nQ11NFgVTEGGYxiMSZQaZhpodzrl1nzjn3XqX/091nr/9a/732x1p3yICY3DaazM3NjWAFLqg+n8A4Q+NpGJsdxN9QP0ZFng3jVqzHjbiwwfQ4PsM72JOGePKsBFSBH8B2jPQjtgt/4VnsbBPSKKDIsyvxOlYPGHg+DmNTGuKXfQso8uxeRJzTpA8v40PM4FpsxrIG+xk8lIY41lNAkWej2N3gCH7DTWmIP8/jLcNBXNXCfSwN8flGAUWercVeJA0OTmJNGuKPdZNFno3ge//djjpsSEN8szM4FajIs4uUe94UnPJA1QaHNMSjeLqFD7uLPFu5QABewvkNpCkcw64ezuG1yrZomF+MXUWeJVRbUOTZDfi0gTCOy9MQ/+4j+CkUebYY32BVg8ltaYj7Ohn4ARvxRqX+NAGDBoc0xCn82mKyjZpbMJlnyRxX4GbcguuxE3/ggPIg3lGZ142ncTuW4lEsahFxWc+nuMizLcoXDe7HBPZX403KOrC3Gm9U7v27vfxW2Np24js43qezM8E1temZfPzBZG72n9W4C4/8jwJWn9qCuR07ksmpI6uqoHfi0i7DV5RnYJ/ySt5dfX9PeQa6x9MVf2klvi3LU0NFnnUHXVljdCgN8brBFlaiyLP9WNtmk+BzPNkQHJZXd3rQ4MPKpqUN04mybrdhBY5M5qP9HNhu/KR3KZ9IlPW6F2aX1LRaRZ4tKvKsqWT305odTvBVH4ZNWfoCvzSI6JVZ+DrBB2chYJWyVasrYv0IOJgM8R0aS2yFiT6cDcr5HYeSJSHO4oUexk2rWaesFycG4HTwYhriTOclfBVbcUmDce1q0hA/aQnwZ8vcUWVPWb5SaYjTePgMnTWhbQs2pyEWdJXKNMSPijx7DltqCAvSWeTZxcqqeB7W17TdTVswlob4dmcw/3HZjj01pLrV3Ke8BcuVLXk/nI/Ny/RpAtIQZ3AP3ppHrFvNuV2/h2vm53PeV7Zh040CKhHT2KDMxkyLgF7oZGAWT2Fd1aadhtr3PQ1xNg3xGaxRpm3gpqRayAFcnYb4RJXdBejr33Edqt6+c22PpSF+eyZ+/gV1fwtPK+U3hQAAAABJRU5ErkJggg==',
                fit: [12, 12],
                border: [false, true, false, false],
                alignment: 'left',
                margin: [40, 5, 0, 0],
                width: 'auto'
              },
              {

                text: `${t('invoices.createInvoice.poweredWithDundy')}`,
                border: [false, true, false, false],
                alignment: 'left',
                color: footerTextColor ?? '#606F82',
                margin: [5, 7, 0, 5],
                fontSize: 7
              },
              {
                text: 'Page ' + currentPage.toString() + ' / ' + pageCount,
                border: [false, true, false, false],
                alignment: 'right',
                color: footerTextColor ?? '#606F82',
                margin: [0, 7, 40, 0],
                fontSize: 7
              }]
          }]
          ]
        },
        layout: {
          defaultBorder: false,
          paddingLeft: function (): number {
            return 0
          },
          paddingRight: function (): number {
            return 0
          },
          paddingTop: function (): number {
            return 4
          },
          paddingBottom: function (): number {
            return 4
          },
          hLineColor: function (i: number): string {
            if (i === 1 || i === 0) {
              return '#eaeaea'
            }

            return '#eaeaea'
          }
        }
      }
    }
  }
})
