<script lang="ts">
  export let title:Readonly<string> = ''
  export let expand:boolean = false

  $:id = title?.replaceAll(' ', '_')
</script>

<div class="accordion-item">
  <input type="checkbox" {id} class="accordion-toggle hidden" bind:checked={expand}>
  <label for={id} class="accordion-label">
    {title}
    <div class="text-2xl text-black {expand ? 'icon-chevron-up' : 'icon-chevron-down'}"></div>
  </label>
  <div class="accordion-content">
    <div class="p-2">
    <slot />
    </div>
  </div>
</div>

<style lang="postcss">
  .accordion-label {
    @apply py-2 px-3 rounded-t-md bg-whisper flex justify-between cursor-pointer;

    &:hover {
      @apply bg-athensGray; 
    }
  }

  .accordion-content {
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
    @apply w-full overflow-hidden border-gray-200;
  }

  .accordion-toggle:checked + .accordion-label + .accordion-content {
    max-height: 1000px; /* Arbitrary large value for smooth expansion */
  }

  .accordion-toggle:checked + .accordion-label {
    @apply border-b;
  }
</style>