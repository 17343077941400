<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { stringNormalize } from '$src/shared/utils/string'
  import Checkbox from './Checkbox.svelte'
  import Loader from './Loader.svelte'
  import TextInput from './TextInput.svelte'

  export let list:any[] = []
  export let displayValue:string = 'label'
  export let checked:string = 'checked'

  export let height:string = 'h-full'
  export let loading:boolean = false
  export let displaySearch:boolean = true
  export let sortBySelect:boolean = true

  const dispatch = createEventDispatcher()
  let search:string = ''

  $:sortedList = sortBySelect ? list.sort((a, b) => b[checked] - a[checked]) : list
  $:filteredSortedList = sortedList.filter(item => stringNormalize(item[displayValue]).includes(stringNormalize(search)))

  const onCheckboxChange = () => {
    setTimeout(() => dispatch('change', list))
  }
</script>

{#if displaySearch}
  <TextInput
    class="w-full mr-5 mb-1"
    showValidationTick={false}
    bind:value={search}
  />
{/if}

<div class="relative overflow-auto w-full {height} border rounded-md border-loblolly mr-5 p-2" class:pointer-events-none={loading}>
  {#if list.length > 0}
    {#if loading}
      <div class="absolute top-1/2 left-1/2 -translate-y-[50%] -translate-x-[50%]">
        <Loader />
      </div>
    {/if}

    {#each filteredSortedList as item}
      <Checkbox
        labelText={item[displayValue]}
        bind:checked={item[checked]}
        on:change={() => onCheckboxChange()}
      />
    {/each}
  {:else}
    <div class="absolute top-1/2 left-1/2 -translate-y-[50%] -translate-x-[50%]">
      <Loader />
    </div>
  {/if}
</div>
