<script lang="ts">
  import { DunningMessageKind } from '$dundy/models/dunning-message-template'
  import { feedbackService } from '$src/core-app/services/feedback.service'
  import { getContacts, saveDunningTemplate } from '$dundy/services/dunning-template.service'
  import { historyChange, historyClean, historyLoad } from '$shared/utils/misc'
  import { isObject } from '$src/shared/utils/object'
  import { navigate } from 'svelte-routing'
  import { onDestroy, onMount } from 'svelte'
  import { placeholders } from '$src/dundy-app/data/placeholder'
  import { t } from '$core/lib/i18n/i18nextWrapper'
  import { ToastType } from '$src/core-app/enums/feedback-enums'
  import { workflowSteps } from '$src/dundy-app/data/workflow'
  import Accordion from '$src/shared/components/accordion/Accordion.svelte'
  import AccordionItem from '$src/shared/components/accordion/AccordionItem.svelte'
  import PageModal from '$shared/components/templates/PageModal.svelte'
  import type { Feedback } from '$src/core-app/models/feedback'
  import type { FieldsErrors } from '$src/dundy-app/data/template'
  import Variable from '$shared/components/list/Variable.svelte'
  import TemplateEditor from '../template/TemplateEditor.svelte'

  export let stepId:Readonly<string> = ''
  export let customerId:Readonly<string> = ''
  export let customerName:Readonly<string> = ''
  
  const toast = (msg: string, type: ToastType) => feedbackService.displayFeedback({
    feedbackMessage: msg,
    feedbackLevel: type
  } as Feedback)
  
  let allowSave:boolean = true
  
  // template editor
  let templateEditor:any
  let media:string = ''
  let language:string = ''
  let template:any = {}
  let inputKey:string = ''
  let formErrors:FieldsErrors = {}
  
  // local variables
  const stepName = workflowSteps.find(s => s.id === stepId)?.name
  const closeUrl = `/workflow${customerId ? '?cid=' + customerId : ''}`
  
  let inputAllowInject:boolean = true
  let variableSearchValue:string = ''
  let variablesVisible:boolean = true

  $:contacts = getContacts(customerId, media)
  $:placeholdersFiltered = placeholders.filter(p => p.fields?.includes(inputKey) || !p.fields)
  $:variables = inputKey
    ? [...placeholdersFiltered, ...contacts].sort((a, b) => a.value.localeCompare(b.value))
    : []

  // TEMPLATE EDITOR FUNCTIONS
  const onTemplateInputKeyChange = (value:string) => inputKey = value
  const onTemplateSearchChange = (value:string) => variableSearchValue = value
  const onTemplateChange = (value:any) => template = value
  const onTemplateMediaChange = (value:string) => {
    historyChange({ media: value }, 'workflow-template', { customerId, media: value })
  }

  // VARIABLES
  const onVariableSelected = (v:Variable) => {
    const isContactInput = [, 'emailsTo', 'emailsCC', 'emailsCCI', 'contactDestination', 'mailAddressDestination'].includes(inputKey)
    const separator = isContactInput ? ',' : ''
    const replaceWord = isContactInput && Boolean(variableSearchValue)
    
    if (templateEditor) templateEditor.injectPlaceholder(v.key, separator, replaceWord)
  }
 
  const onClose = () => navigate(closeUrl)
  
  const onSave = async () => {
    const { prefKey, prefValue, formFields } = template

    if (prefKey) template.prefKey.customerId = customerId || '*'
    if (prefValue) template.prefValue.kind = DunningMessageKind[media as keyof typeof DunningMessageKind]

    const res = await saveDunningTemplate(prefKey, prefValue, formFields, language)
    
    if (typeof res === 'string' && res) {
      toast(res, ToastType.Error)
    } else if (isObject(res)) {
      formErrors = { ...res as any }
    } else {
      toast(t('template.modal.saved'), ToastType.Success)
      onClose()
    }
  }

  onMount(async () => {
    const searchParams = new URLSearchParams(window.location.search)
    media = searchParams.get('media') ?? ''
    customerId = searchParams.get('cid') ?? ''

    const history = historyLoad('workflow-template')
    if (history) {
      media = history.data.media
      customerId = history.data.customerId
    }
  })

  onDestroy(() => historyClean('workflow-template'))
</script>

<PageModal title={t('workflow.editItem')} closeNavigation={closeUrl} width={'w-10/12'}>

  <svelte:fragment slot="left-side">
    
    {#if variablesVisible && placeholders.length > 0}
      <div class="text-sm font-semibold flex flex-col mt-4">
        <Accordion>
          <AccordionItem title={t('template.variable.title')} expand={false}>
            <Variable
              bind:searchValue={variableSearchValue}
              {variables}
              placeholder={t('template.variable.filter')}
              disabled={!inputAllowInject}
              on:select={(v) => onVariableSelected(v.detail)}
            />
          </AccordionItem>
        </Accordion>
      </div>
    {/if}
  </svelte:fragment>

  <svelte:fragment slot="right-side">
    <div class="mb-4  text-s text-[#461ebd]">
      <span class="bg-[#F5EEFF] px-3 py-1 mr-1 rounded">
        {customerId ? t('template.modal.breadcrumbCustomer', { name: customerName }) : t('template.modal.breadcrumbWorkspace')}
      </span>
      /
      <span class="ml-1">{t('workflow.step')} - {stepName}</span>
    </div>

    <div class="relative size-full flex flex-col bg-white border border-loblolly rounded p-6">

      <TemplateEditor
        {stepId}
        {customerId}
        {contacts}
        {placeholders}
        bind:this={templateEditor}
        bind:media={media}
        bind:language={language}
        bind:inputAllowInject={inputAllowInject}
        bind:formErrors={formErrors}
        on:mediaChange={(value) => onTemplateMediaChange(value.detail)}
        on:inputKeyChange={(value) => onTemplateInputKeyChange(value.detail)}
        on:searchChange={(value) => onTemplateSearchChange(value.detail)}
        on:templateChange={(value) => onTemplateChange(value.detail)}
      />

    </div>
  </svelte:fragment>

  <svelte:fragment slot="footer">
      <button class="btn action-cancel mr-8" on:click|preventDefault|stopPropagation={() => onClose()}>
        {t('template.modal.cancel')}
      </button>
      <button class="btn action-bla primary" disabled={!allowSave} on:click|preventDefault|stopPropagation={() => onSave()}>
        {t('template.modal.save')}
      </button>
  </svelte:fragment>

</PageModal>
