<script lang="ts">
    import { t } from '$core/lib/i18n/i18nextWrapper'
    import { onDestroy, onMount } from 'svelte'
    import { get, type Unsubscriber } from 'svelte/store'
    import { navigate, Route, Router } from 'svelte-routing'
    import { TodosStore } from '../stores/todos.store'
    import { ProfileStore } from '$core/stores/profile.store'
    import { DunningInvoicesStore } from '../stores/dunning-invoices.store'
    import { CustomersStore } from '$crm/stores/customers.store'
    import { ContactsStore } from '$crm/stores/contacts.store'
    import { ComputedDataInvoicesStore } from '../stores/computed-data-invoice.store'
    import { WorkspaceStore } from '$crm/stores/workspace.store'
    import { Feedback } from '$core/models/feedback'
    import type { TodoListItem } from '../models/todo-list-item'
    import { TodoItemActionKind, TodoItemMessageKind } from '../models/todo-list-item'
    import type { Customer } from '$crm/models/customer'
    import type { Contact } from '$crm/models/contact'
    import type Invoice from '../models/invoice'
    import { eventsManager } from '$core/events/event-manager'
    import { EventType } from '$core/events/event-type'
    import { feedbackService } from '$core/services/feedback.service'
    import TodoActionNav from '../lib/todo/TodoActionNav.svelte'
    import TodoActionEmail from '../lib/action-receivable-invoice/ViewActionReceivableEmailUI.svelte'
    import TodoActionPhone from '../lib/action-receivable-invoice/ViewActionReceivablePhoneUI.svelte'
    import TodoActionLetter from '../lib/action-receivable-invoice/ViewActionReceivableLetterUI.svelte'
    import TodoActionLegal from '../lib/todo/TodoActionLegal.svelte'
    import { emailService } from '../services/email.service'
    import { confetti } from '@neoconfetti/svelte'
    import Switch from '../../core-app/lib/ui-kit/Switch.svelte'
    import Exporter from '../lib/pdf-letter-exporter/Exporter.svelte'
    import { substTiphaine } from '$voxy/services/specialOptalis/tiphaine.service'
    import {
      generatePaymentLinkForInvoiceAndCustomer,
      getTodoFromInvoiceId,
      populateActionBody,
      populateEmailHeader,
      populateSubjectMessage
    } from '../services/receivable-action.service'
    import TodoEscalationNav from '../lib/todo/TodoEscalationNav.svelte'
    import { TodoUIDunningEscalationTabs } from '../models/todo-ui-dunning-escalation-tabs'
    import {
      determineNextActionKindBasedOnActiveDunningEscalationTabAndInvoiceHistory,
      setCurrentEscalationTabAccordingToTodoEscalationOrUserSetting
    } from '../services/ui-receivable-action.service'
    import ReceivableActionInvoiceNavigation
      from '../lib/action-receivable-invoice/ReceivableActionInvoiceNavigation.svelte'
    import ViewActionReceivableInvoiceTip from '../lib/action-receivable-invoice/ViewActionReceivableInvoiceTip.svelte'
    import ViewActionReceivableInvoiceTimeline
      from '../lib/action-receivable-invoice/ViewActionReceivableInvoiceTimeline.svelte'
    import ViewActionReceivableInvoiceHeader
      from '../lib/action-receivable-invoice/ViewActionReceivableInvoiceHeader.svelte'
    import {
      returnSuggestionForTodoItemMessageKindBasedOnActiveDunningEscalationTab
    } from '../services/ui-receivable-action.service.js'
    import StorageBucketObject from '../../file-mgt-domain/models/storage-bucket-object'
    import { escalationTabToMessageKind } from '../services/dundy-config'
    import { InvoicesHistoryStore } from '../stores/invoices-history.store.js'
    import { invoiceAttachmentDownload } from '../services/dundy-file-mgt/dundy-pdf-attachment.api'
    import { InvoiceStatus } from '../enums/invoice-status.js'
    import mixpanel from 'mixpanel-browser'
    import { type EmailAttachment, EmailAttachmentTag } from '../../dundy-app/models/email-attachment'
  import { markTaskDone } from '../services/todo.service'

  /** Exported Var **/
  export let customerId: string
  export let workspaceId: string
  export let invoiceId: string

  /** Local declarations **/
  let todo: TodoListItem
  let customer: Customer
  let invoice: Invoice
  let activeDunningEscalationTab: TodoUIDunningEscalationTabs
  let activeItemMessageTab: TodoItemMessageKind
  let programmedActionKind: TodoItemActionKind
  let customerContacts: Array<Contact>
  let allContacts: Array<Contact>
  let selectedContacts: Array<Contact> = []
  let selectedCcContacts: Array<Contact> = []
  let selectedBccContacts: Array<Contact> = []
  let selectedContact: Contact = null
  let renderedSubjectMessage: string = null
  let renderedBodyMessage: string = null
  let paymentLink: string
  let paymentLinkError: string
  let renderedHeader: any = null
  let fromName: string = null
  let replyToEmail: string = null
  let replyToFullName: string = null
  let showPaymentLink: boolean = false
  let showInvoiceAttachment: boolean = false
  let showConfetti: boolean = false
  let currentInvoiceHasAttachment: boolean
  let sendingReminder: boolean = false
  let unsubscribeTodosStore: Unsubscriber
  let isUIReady: boolean = false
  let attachments: Array<EmailAttachment>

  /**
   * Reload the body message when the user changes the copy
   */
  const reloadBodyMessage = () => {
    renderedBodyMessage = populateActionBody(programmedActionKind, activeItemMessageTab, selectedContacts[0], customer, todo, invoice, paymentLink, showPaymentLink, showInvoiceAttachment)
  }

  /**
   * Reload the subject message when the user changes the copy
   */
  const reloadSubjectMessage = () => {
    renderedSubjectMessage = populateSubjectMessage(todo, invoice, programmedActionKind, activeItemMessageTab, get(WorkspaceStore))
  }

  /**
   * Mark the task as done
   * Called when the user clicks on the "Mark as Done" button
   */
  const onReminderSent = () => {
    markTaskDone(todo, customer)

    showConfetti = true
    sendingReminder = false
    setTimeout(() => {
      eventsManager.emit(EventType.TODO_MARKED_DONE, todo, 'ReceivableAction.svelte')
      showConfetti = false
      navigate('/today')

      feedbackService.displayFeedback(<Feedback>{
        feedbackMessage: t('todoAction.markTodoDoneSuccess'),
        feedbackLevel: 'Success'
      })
    }, 2000)
  }

  /**
   * Send the reminder email
   *  - Download the invoice attachment if needed
   *  - Called when the user clicks on the "Send" button
   */
  const sendReminder = (): void => {
    sendingReminder = true
    let promise: Promise<void> = Promise.resolve()
    if (!replyToEmail || replyToEmail === '') {
      const errMsg = 'Missing reply email (replyToEmail) not filled, aborting email. Please go to your profile page, make any change and save it again to try fixing the issue.'
      /* console.error(errMsg, 'fromName=', fromName, 'replyToEmail=', replyToEmail, 'replyToFullName=', replyToFullName, 'renderedHeader=', renderedHeader) */
      // throw new Error(errMsg);
      feedbackService.displayFeedback(<Feedback>{
        feedbackLevel: 'Error',
        feedbackMessage: (new Error(errMsg)).message
      })

      return
    }
    if (!showInvoiceAttachment) {
      attachments = attachments.filter(attachment => attachment.tags.some(tag => tag === EmailAttachmentTag.Manual))
    }
    promise.then(() => emailService.sendTodoActionEmail(
      emailService.fromSelectedContactsToSendGridDestinationList(selectedContacts),
      emailService.fromSelectedContactsToSendGridDestinationList(selectedCcContacts),
      emailService.fromSelectedContactsToSendGridDestinationList(selectedBccContacts),
      fromName,
      replyToEmail,
      replyToFullName,
      renderedHeader,
      renderedSubjectMessage,
      renderedBodyMessage,
      attachments && attachments.length ? attachments : []
    ))
      .then(() => {
        onReminderSent()
      })
      .catch((e) => feedbackService.displayFeedback(<Feedback>{
        feedbackLevel: 'Error',
        feedbackMessage: e.message || e.detail || e
      }))
  }

  /**
   * Update the selected contacts
   * @param contact
   */
  function updateSelectedContacts(contacts: Contact[]): void {
    selectedContacts = contacts
  }

  /** Reactive declarations
   *  TODO Allow custom reply-to by preferences // Remove the substTiphaine
   **/

  $: fromName = substTiphaine(get(WorkspaceStore).company.companyId, 'dunningMessageFromFullName', `${get(ProfileStore)?.firstName} ${get(ProfileStore).lastName} ${t('todoAction.emailFrom')} ${get(WorkspaceStore).company?.formalName}`)
  $: replyToEmail = substTiphaine(get(WorkspaceStore).company.companyId, 'dunningMessageReplyToEmail', $ProfileStore.email)
  $: replyToFullName = substTiphaine(get(WorkspaceStore).company.companyId, 'dunningMessageReplyToFullName', $ProfileStore.firstName + ' ' + $ProfileStore.lastName)
  $: currentInvoiceHasAttachment = !!($ComputedDataInvoicesStore.find(computedInvoice => invoice && computedInvoice?.invoiceNumber === invoice?.invoiceNumber)?.invoiceMetadata?.businessDocumentAttachmentMetadata?.fileName)
  $: showInvoiceAttachment = currentInvoiceHasAttachment
  $: updateSelectedContacts(customerContacts)
  // Whenever activeDunningEscalationTab changes, activeItemMessageTab is reset to EMAIL
  $: if (activeDunningEscalationTab) {
    activeItemMessageTab = TodoItemMessageKind.EMAIL
    reloadBodyMessage()
    reloadSubjectMessage()
  }
  // If LEGAL & COLLECTION are selected, then the activeItemMessageTab is set to LAWYER_AND_OTHER_LEGAL_SERVICES
  $: if (activeDunningEscalationTab === TodoUIDunningEscalationTabs.LEGAL && TodoItemActionKind.COLLECTION_LITIGATION) {
    activeItemMessageTab = TodoItemMessageKind.LAWYER_AND_OTHER_LEGAL_SERVICES
    reloadBodyMessage()
    reloadSubjectMessage()
  }

  function updateCcSelectedContacts(contacts: Contact[]): void {
    selectedCcContacts = contacts
  }

  function updateBccSelectedContacts(contacts: Contact[]): void {
    selectedBccContacts = contacts
  }

  /**
   * Generate Payment Link First If possible then populate variables
   * @param todoListItem
   * @param todoItemActionKind
   * @param todoItemMessageKind
   * @param invoiceToGenerateLink
   * @param customerToGenerateLink
   * @param showInvoiceAttachmentToGenerateLink
   */
  function generatePaymentLinkThenMessages(
    todoListItem: TodoListItem,
    todoItemActionKind: TodoItemActionKind,
    todoItemMessageKind: TodoItemMessageKind,
    invoiceToGenerateLink: Invoice,
    customerToGenerateLink: Customer,
    showInvoiceAttachmentToGenerateLink: boolean): void {
    if ($WorkspaceStore.bankConfig?.bankConnectionCurrentlyKnownActive && $WorkspaceStore.bankConfig.selectedAccountId && invoiceToGenerateLink.amountIncludingTaxes > 0 && !paymentLink) {
      generatePaymentLinkForInvoiceAndCustomer(invoiceToGenerateLink, customerToGenerateLink)
        .then(link => {
          /* console.log('%c TodoAction populateVariables() link', 'color: purple', link) */
          paymentLink = link
          showPaymentLink = true
          paymentLinkError = null
          renderedHeader = populateEmailHeader(get(WorkspaceStore))
          renderedSubjectMessage = populateSubjectMessage(todoListItem, invoiceToGenerateLink, todoItemActionKind, todoItemMessageKind, get(WorkspaceStore))
          renderedBodyMessage = populateActionBody(todoItemActionKind, todoItemMessageKind, selectedContacts[0], customerToGenerateLink, todoListItem, invoiceToGenerateLink, paymentLink, showPaymentLink, showInvoiceAttachmentToGenerateLink)
        })
        .catch((err) => {
          paymentLinkError = err
          renderedHeader = populateEmailHeader(get(WorkspaceStore))
          renderedSubjectMessage = populateSubjectMessage(todoListItem, invoiceToGenerateLink, todoItemActionKind, todoItemMessageKind, get(WorkspaceStore))
          renderedBodyMessage = populateActionBody(todoItemActionKind, todoItemMessageKind, selectedContacts[0], customerToGenerateLink, todoListItem, invoiceToGenerateLink, paymentLink, showPaymentLink, showInvoiceAttachmentToGenerateLink)
        })
    } else {
      renderedHeader = populateEmailHeader(get(WorkspaceStore))
      renderedSubjectMessage = populateSubjectMessage(todoListItem, invoiceToGenerateLink, todoItemActionKind, todoItemMessageKind, get(WorkspaceStore))
      renderedBodyMessage = populateActionBody(todoItemActionKind, todoItemMessageKind, !!selectedContacts && selectedContacts.length > 0 ? selectedContacts[0] : null, customerToGenerateLink, todoListItem, invoiceToGenerateLink, paymentLink, showPaymentLink, showInvoiceAttachmentToGenerateLink)
    }
  }

    /**
     * OnMount Call
     * Subscribe to the TodosStore
     * Load todo, customer, invoice, customerContacts, activeDunningEscalationTab, activeItemMessageTab, programmedActionKind
     */
    onMount(() => {
      mixpanel.track('LA10 Receivable Action Page', {
        'Description': 'Open ReceivableAction.svelte'
      })
      isUIReady = false

      if (!!unsubscribeTodosStore) {
        unsubscribeTodosStore()
      }

      unsubscribeTodosStore = TodosStore.subscribe((newTodosStore: TodoListItem[]) => {
        if (!newTodosStore || newTodosStore?.length < 0) return

        /*//todo fix the bug where activeDunningEscalationTab is set twice*/
        todo = getTodoFromInvoiceId(invoiceId)
        customer = get(CustomersStore)?.find(c => c.company.companyId === customerId)

        if (todo) {
          invoice = get(DunningInvoicesStore)?.find(iv => iv.invoiceNumber === todo?.scopeInvoiceNumbers[0] && iv.toId === customer?.company?.companyId)
        } else {
          invoice = get(DunningInvoicesStore)?.find(iv => iv.dataId === invoiceId && iv.toId === customer?.company?.companyId)
        }

        customerContacts = get(ContactsStore)?.filter(c => c.companyId === customerId)
        allContacts = get(ContactsStore)?.filter(c => c.companyId === customerId || c.companyId === workspaceId)
        activeDunningEscalationTab = setCurrentEscalationTabAccordingToTodoEscalationOrUserSetting(todo, activeDunningEscalationTab)
        activeItemMessageTab = returnSuggestionForTodoItemMessageKindBasedOnActiveDunningEscalationTab(todo, activeDunningEscalationTab, escalationTabToMessageKind)
        programmedActionKind = todo ? todo?.actionKind : TodoItemActionKind.INVOICE_ISSUANCE

        generatePaymentLinkThenMessages(todo, programmedActionKind, activeItemMessageTab, invoice, customer, showInvoiceAttachment)

        setTimeout(() => {
          isUIReady = true
        }, 2000)
      })
    })

  onDestroy(() => {
    if (!!unsubscribeTodosStore) {
      unsubscribeTodosStore()
    }
  })
</script>

<svelte:head>
  <style lang="postcss">
    body {
      @apply bg-whisper;
    }
  </style>
</svelte:head>

<main class="container mx-auto">

  <ViewActionReceivableInvoiceHeader
    todo={todo}
    customer={customer}
    invoice={invoice}
  />

  <section class="flex justify-between mt-8 space-x-4 relative">
    <div class="w-full sm:w-8/12 bg-white border border-athensGray rounded px-5 py-5">

      <TodoEscalationNav
        isUIReady={isUIReady}
        selectedTab={activeDunningEscalationTab}
        on:escalationChanged={(e) => {
          activeDunningEscalationTab = e.detail.tabSelected
          programmedActionKind = determineNextActionKindBasedOnActiveDunningEscalationTabAndInvoiceHistory(invoice?.invoiceNumber, activeDunningEscalationTab, get(InvoicesHistoryStore))
          activeItemMessageTab = returnSuggestionForTodoItemMessageKindBasedOnActiveDunningEscalationTab(todo, activeDunningEscalationTab, escalationTabToMessageKind)
          renderedSubjectMessage = populateSubjectMessage(todo, invoice, programmedActionKind, activeItemMessageTab, get(WorkspaceStore))
          renderedBodyMessage = populateActionBody(programmedActionKind, activeItemMessageTab, selectedContacts[0], customer, todo, invoice, paymentLink, showPaymentLink, showInvoiceAttachment)
        }}
      />

      <TodoActionNav
        isUIReady={isUIReady}
        bind:activeItemMessageTab={activeItemMessageTab}
        activeDunningEscalationTab={activeDunningEscalationTab}
        on:actionKindChanged={(e) => {
          activeItemMessageTab = e.detail.tabSelected
          renderedSubjectMessage = populateSubjectMessage(todo, invoice, programmedActionKind, activeItemMessageTab, get(WorkspaceStore))
          renderedBodyMessage = populateActionBody(programmedActionKind, activeItemMessageTab, selectedContacts[0], customer, todo, invoice, paymentLink, showPaymentLink, showInvoiceAttachment)
        }}
      />

      {#if activeDunningEscalationTab !== TodoUIDunningEscalationTabs.LEGAL}

        {#if activeItemMessageTab === TodoItemMessageKind.EMAIL}
          <TodoActionEmail
            {invoice}
            {showInvoiceAttachment}
            bind:renderedBodyMessage={renderedBodyMessage}
            bind:renderedSubjectMessage={renderedSubjectMessage}
            bind:attachments={attachments}
            {allContacts}
            {customerContacts}
            selectedContact={selectedContacts[0]}
            on:selectedContacts={ (e) => {
              updateSelectedContacts(e.detail)
              reloadBodyMessage()
            }}
            on:selectedCcContacts={ (e) => {
              updateCcSelectedContacts(e.detail)
            }}
            on:selectedBccContacts={ (e) => {
              updateBccSelectedContacts(e.detail)
            }}
            on:bodyMessageReset={() => reloadBodyMessage()}
          />

        {:else if activeItemMessageTab === TodoItemMessageKind.PHONE_CALL}
          <TodoActionPhone
            {todo}
            {renderedBodyMessage}
            {customerContacts}
            {customer}
            {selectedContact}
            on:selectedContactChanged={(e) => {
              selectedContact = e.detail
            }}
          />

        {:else if activeItemMessageTab === TodoItemMessageKind.EMAIL_AND_REGULAR_POST_MAIL}
          <TodoActionLetter
            bind:renderedBodyMessage={renderedBodyMessage}
            bind:renderedSubjectMessage={renderedSubjectMessage}
            {customerContacts}
            {customer}
            {selectedContact}
            on:selectedContactChanged={(e) => {
              selectedContact = e.detail
            }}
          />
        {/if}

      {:else}
        <TodoActionLegal
          bind:renderedBodyMessage={renderedBodyMessage}
        />
      {/if}
      {#if paymentLinkError}
        <p class="text-mojo mx-auto text-center">{paymentLinkError?.message}</p>
      {/if}
    </div>
    <aside class="w-full md:w-96 sticky top-24" style="max-height: 180px">
      <div class="w-full mb-10">

        {#if activeItemMessageTab === TodoItemMessageKind.EMAIL}
          <button
            disabled={!$WorkspaceStore.bankConfig?.selectedAccountBankIBAN || sendingReminder || !selectedContacts || selectedContacts.length === 0 || invoice?.status === InvoiceStatus.PAID}
            class="btn big-primary uppercase ml-auto w-full"
            on:click={() => sendReminder()}>
            {#if !sendingReminder}
              <svg class="mr-2" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M4.75 19.25L12 4.75L19.25 19.25L12 15.75L4.75 19.25Z"></path>
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="1.5" d="M12 15.5V12.75"></path>
              </svg>

              {#if programmedActionKind === TodoItemActionKind.INVOICE_ISSUANCE || todo?.actionKind === TodoItemActionKind.INVOICE_ISSUANCE}
                {t('todoAction.sendInvoice')}
              {:else}
                {t('todoAction.sendReminder')}
              {/if}
            {:else}
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-dundyOrange"
                   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {t('todoAction.sendingReminder')}
            {/if}
          </button>

        {:else}
          <button
            disabled={!$WorkspaceStore.bankConfig?.selectedAccountBankIBAN || sendingReminder || invoice?.status === InvoiceStatus.PAID}
            class="btn big-primary uppercase ml-auto w-full"
            on:click={() => onReminderSent()}>
            {#if !sendingReminder}
              <i class="icon-square-check text-white text-3xl mr-2"></i>
              {t('todoAction.markTodoDone')}
            {:else}
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-dundyOrange"
                   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {t('todoAction.markingAsDone')}
            {/if}
          </button>
        {/if}

        {#if showConfetti}
          <div class="confetti-container">
            <div use:confetti/>
          </div>
        {/if}
      </div>

      {#if activeItemMessageTab === TodoItemMessageKind.EMAIL}
        <div class="w-full flex my-5 justify-between">
          <p class="text-s font-bold">{t('todoAction.includePDF')}</p>
          <Switch bind:toggled={showInvoiceAttachment}
                  on:toggle={() => {
                    reloadBodyMessage()
                  }}
                  disabled={!currentInvoiceHasAttachment}/>
        </div>

        <div class="w-full flex my-5 justify-between">
          <p class="text-s font-bold">{t('todoAction.includePaymentLink')}</p>
          <Switch bind:toggled={showPaymentLink}
                  on:toggle={() => {
                    reloadBodyMessage()
                  }}
                  disabled={!paymentLink}/>
        </div>
      {/if}

      {#if activeItemMessageTab === TodoItemMessageKind.EMAIL_AND_REGULAR_POST_MAIL}
        <Exporter {todo}
                  {customer}
                  selectedContact={selectedContacts[0]}
                  {invoice}
                  {renderedSubjectMessage}
                  {renderedBodyMessage}/>
      {/if}

      <ReceivableActionInvoiceNavigation
        workspaceId={workspaceId}
        customerId={customerId}
        invoiceId={invoiceId}/>

      <Router>
        <Route path="overview">
          <ViewActionReceivableInvoiceTimeline
            invoiceId={invoiceId}/>
        </Route>
        <Route path="tips">
          <ViewActionReceivableInvoiceTip
            {programmedActionKind}/>
        </Route>
      </Router>

    </aside>
  </section>

</main>