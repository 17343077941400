<script lang="ts">
  import { t } from '$core/lib/i18n/i18nextWrapper'
  import { createEventDispatcher, onMount } from 'svelte'
  import { navigate, Route, Router } from 'svelte-routing'
  import { get, type Writable, writable } from 'svelte/store'
  import Loader from '../../core-app/lib/ui-kit/Loader.svelte'
  import { eventsManager } from '$core/events/event-manager'
  import { EventType } from '$core/events/event-type'
  import FullSizeModal from '../../core-app/lib/ui-kit/FullSizeModal.svelte'
  import { feedbackService } from '$core/services/feedback.service'
  import { Feedback } from '$core/models/feedback'
  import { DocumentSourceKind } from '$src/order-to-cash-lib/models/document-source-kind'
  import { Tooltip } from '../../core-app/lib/ui-kit/Tooltip'
  import { BusinessDocument } from '../models/business-document'
  import { BusinessDocumentStatus } from '../enums/business-document-status'
  import { VoxyHelper } from '../helpers/voxy-helper'
  import { BusinessDocumentKind } from '../enums/business-document-kind'
  import ConfirmationModal from '../../core-app/lib/ui-kit/ConfirmationModal.svelte'
  import ViewBusinessDocumentCompanyLogoAndName from '../lib/invoice-view/ViewBusinessDocumentCompanyLogoAndName.svelte'
  import ViewBusinessDocumentNavigation from '../lib/invoice-view/ViewBusinessDocumentNavigation.svelte'
  import ViewBusinessDocumentDetails from '../lib/invoice-view/ViewBusinessDocumentDetails.svelte'
  import ViewBusinessDocumentKeyInformation from '../lib/invoice-view/ViewBusinessDocumentKeyInformation.svelte'
  import BusinessDocumentRelatedDocumentsPanel from '../lib/invoice-creation/form/BusinessDocumentRelatedDocumentsPanel.svelte'
  import ViewBusinessDocumentActivity from '../lib/invoice-view/ViewBusinessDocumentActivity.svelte'
  import ViewTodoDunningButton from '../../order-to-cash-lib/components/ViewTodoDunningButton.svelte'
  import { updateNewNavigationHistoryItem } from '$core/stores/navigationHistory.store'
  import { NavigationHistoryItem } from '$core/models/navigation-history-item'
  import {
    getInvoiceWithCollateralData,
    saveCollateralDataToServer,
    saveInvoiceToServer
  } from '../helpers/business-document-data-passthrough'
  import { duplicateInvoiceData } from '../helpers/business-document-duplicate-pure-functions'
  import { voidInvoiceData } from '../services/business-document-voiding/business-document-voiding.service'
  import { getBusinessDocumentMainTitle } from '../services/business-documents-functions'
  import { getBusinessDocumentById } from '../services/business-document-api/business-document-repository.api'
  import DownloadVoxyPDFButton from '../lib/voxy-pdf-file-mgt/DownloadVoxyPDFButton.svelte'
  import BusinessDocumentPDFViewer from '../lib/voxy-pdf-file-mgt/BusinessDocumentPDFViewer.svelte'
  import { isBusinessDocumentVoidedByAnotherOne } from '../services/business-document-voiding/is-business-document-voided.service'
  import { SimpleDocumentsStore } from '../stores/business-documents.store'
  import { DunningInvoicesStore } from '$dundy/stores/dunning-invoices.store'
  import Invoice from '../../dundy-app/models/invoice'
  import { featureToggling } from '$config/feature-toggling'
  import { InvoiceStatus } from '$dundy/enums/invoice-status'
  import mixpanel from 'mixpanel-browser'
  import { businessDocumentAttachmentDownload } from '../services/pdf-file-mgt/business-document-pdf-attachment.api'
  import { WorkspaceStore } from '$crm/stores/workspace.store'
  import type StorageBucketObject from '$src/file-mgt-domain/models/storage-bucket-object'
  import { remoteDebuggingService } from '$src/core-app/services/remote-debugging.service'
  import { isFeatureEnabled } from '$src/config/features.service'

  /** Init Const **/
  const dispatch = createEventDispatcher()

  /** Exported Var **/
  export let businessDocumentId: string
  export let returnToPathOnClose: NavigationHistoryItem

  /** Local declarations **/
  let businessDocument: BusinessDocument = VoxyHelper.newVoxyInvoice(BusinessDocumentKind.INVOICE)
  let isContentLoading: boolean = false
  let isMenuAccessible: boolean
  let isCloseable: boolean
  let displayVoidInvoiceConfirmation: boolean
  let isBusinessDocumentVoided: boolean
  let invoice: Invoice | undefined
  let isDownloadingAttachment: boolean = true
  let attachment:StorageBucketObject = null

  $:dunv0 = isFeatureEnabled('dunv0')
  $:cstpl = isFeatureEnabled('cstpl')

  /** Dundy Integration **/
  if (featureToggling().isDundyDunningEnabled) {
    invoice = $DunningInvoicesStore.find(
      (invoice: Invoice) => invoice?.invoiceNumber === businessDocument.businessDocumentNumber,
    )
  }

  const close = () => {
    dispatch('close', returnToPathOnClose)
  }

  /** Writable declarations **/
  const isDuplicating: Writable<boolean> = writable(false)
  const isVoiding: Writable<boolean> = writable(false)

  /** Reactive declarations **/
  $: isCloseable = !$isDuplicating && !$isVoiding
  $: isMenuAccessible = !$isDuplicating && !$isVoiding
  $: isBusinessDocumentVoided = !!isBusinessDocumentVoidedByAnotherOne(
    businessDocument.businessDocumentSource.sourceKind as DocumentSourceKind,
    businessDocument,
    get(SimpleDocumentsStore),
  )
  
  /** Helper Functions **/
  function setIsDuplicating(value: boolean) {
    isDuplicating.set(value)
  }

  function setIsVoiding(value: boolean) {
    isVoiding.set(value)
  }

  /**
   * Route & Duplicate Invoice
   * uses the businessDocumentId to get the invoice from the server
   * then duplicates the invoice data
   * then saves the duplicated invoice to the server
   * then saves the duplicated collateral data to the server
   * @param businessDocument
   * @param setIsDuplicating
   */
  const duplicateInvoice = async (businessDocument: BusinessDocument, setIsDuplicating: (value: boolean) => void) => {
    try {
      setIsDuplicating(true)

      if (businessDocument.businessDocumentSource.sourceKind === DocumentSourceKind.FROMVOXYINVOICING) {
        getInvoiceWithCollateralData(businessDocument)
          .then((businessDocumentAllDataPersisted) => duplicateInvoiceData(businessDocumentAllDataPersisted))
          .then((duplicatedBusinessDocumentAllDataPersisted) =>
            Promise.all([
              saveInvoiceToServer(duplicatedBusinessDocumentAllDataPersisted),
              saveCollateralDataToServer(duplicatedBusinessDocumentAllDataPersisted)
            ]).then(() => {
              setTimeout(() => {
                eventsManager.emit(
                  EventType.REQUEST_INVOICE_LIST_AND_COMPUTED_INVOICE_LIST_TO_REFRESH,
                  null,
                  'VoxyInvoiceViewFullModal.svelte',
                )

                navigate(
                  `/receivables/edit/${duplicatedBusinessDocumentAllDataPersisted.coreDocument.businessDocument.businessDocumentId}/details`,
                )
                setIsDuplicating(false)
              }, 7000)
            }),
          )
          .catch((error) => {
            /* console.error(error) */
          })
      } else {
        /* console.error('Invalid sourceKind') */
      }
    } catch (error) {
      /* console.error('Error:', error) */
      setIsDuplicating(false)
      feedbackService.displayFeedback(<Feedback>{
        numberOfInvalidItems: 0, // Put the appropriate value
        feedbackDetails: [], // Put the appropriate value
        feedbackLevel: 'Error',
        feedbackMessage: t('invoices.duplicateInvoice.duplicateError'),
        responseStatus: 0 // Put the appropriate value
      })
    }
  }

  /**
   * Void Invoice
   */
  const voidInvoice = async (businessDocument: BusinessDocument, setIsVoiding: (value: boolean) => void) => {
    try {
      setIsVoiding(true)

      if (businessDocument.businessDocumentSource.sourceKind === DocumentSourceKind.FROMVOXYINVOICING) {
        getInvoiceWithCollateralData(businessDocument)
          .then((businessDocumentAllDataPersisted) => voidInvoiceData(businessDocumentAllDataPersisted))
          .then((voidedBusinessDocumentAllDataPersisted) =>
            Promise.all([
              saveInvoiceToServer(voidedBusinessDocumentAllDataPersisted),
              saveCollateralDataToServer(voidedBusinessDocumentAllDataPersisted)
            ]).then(() => {              
              eventsManager.emit(
                EventType.REQUEST_INVOICE_LIST_AND_COMPUTED_INVOICE_LIST_TO_REFRESH,
                null,
                'VoxyInvoiceViewFullModal.svelte',
              )
              eventsManager.emit(
                EventType.BUSINESS_DOCUMENTS_LIST_CHANGED,
                null,
                'VoxyInvoiceViewFullModal.svelte',
              )

              // setTimeout(() => {
              setIsVoiding(false)
              navigate(
                `/receivables/edit/${voidedBusinessDocumentAllDataPersisted.coreDocument.businessDocument.businessDocumentId}/details`,
              )
              // }, 4000);
            }),
          )
          .catch((error) => {
            console.error(error)
            throw new Error('Invalid sourceKind')
          })
      } else {
        /* console.log('Invalid sourceKind') */
      }
    } catch (error) {
      /* console.error('Error:', error) */
      setIsVoiding(false)
      feedbackService.displayFeedback(<Feedback>{
        numberOfInvalidItems: 0, // Put the appropriate value
        feedbackDetails: [], // Put the appropriate value
        feedbackLevel: 'Error',
        feedbackMessage: t('invoices.duplicateInvoice.voidError'),
        responseStatus: 0 // Put the appropriate value
      })
    }
  }

  const downloadAttachment = () => {
    businessDocumentAttachmentDownload(businessDocument.businessDocumentNumber, businessDocument.businessDocumentId, get(WorkspaceStore).workspaceId)
      .then((sbo:StorageBucketObject) => {
        attachment = sbo
        isDownloadingAttachment = false
      })
      .catch((e) => {
        remoteDebuggingService.addInfo(e, 'error')
        feedbackService.displayFeedback(<Feedback>{
          feedbackLevel: 'Error',
          feedbackMessage: e.message || e.detail || e
        })

        isDownloadingAttachment = false
      })
  }

  /**
   * Checks if the businessDocumentId is set and executes the API calls to get the businessDocument from the server.
   * @param businessDocumentId
   */
  function checkIfBusinessDocumentIdExistsAndExecuteAPICalls(businessDocumentId?: string | null) {
    if (businessDocumentId) {
      getBusinessDocumentById(businessDocumentId ? <string>businessDocumentId : '')
        .then((businessDocumentFromServer: BusinessDocument) => {
          businessDocument = businessDocumentFromServer
          if (businessDocument.businessDocumentStatus === BusinessDocumentStatus.DRAFT) {
            feedbackService.displayFeedback(<Feedback>{
              feedbackLevel: 'Error',
              feedbackMessage: t('invoices.editInvoice.invoiceNotFinalized')
            })
            navigate('/invoices')
          }

          downloadAttachment()

          isContentLoading = false
        })
        .catch((err) => {
          remoteDebuggingService.addInfo(err, 'error')
          isContentLoading = false
        })
    } else {
      isContentLoading = false
      navigate('/invoices')
    }
  }

  /**
   * We need to use a reactive Statement to check if the businessDocumentId is set as SvelteRouting intentionally does not
   * reload the component when the route changes.
   * https://github.com/EmilTholin/svelte-routing/issues/54
   *  */
  $: checkIfBusinessDocumentIdExistsAndExecuteAPICalls(businessDocumentId)

  onMount(() => {
    mixpanel.track('IG10 Voxy Invoices View Modal', {
      'Description': 'Open VoxyInvoiceViewFullModal.svelte'
    })

    updateNewNavigationHistoryItem('VoxyInvoiceViewFullModal.svelte')
    isContentLoading = true
  })
</script>

<FullSizeModal class="w-full overflow-y-auto h-full" {isCloseable} on:close={close} showFooter={false}>
  <div
    class="grid w-full h-full"
    class:grid-cols-2={!isContentLoading}
    slot="content"
    style={isContentLoading ? '' : 'grid-template-rows: 72px 1fr; grid-template-columns: 1fr 455px;'}
  >
    <ConfirmationModal
      modalHeading={t('invoices.viewInvoice.voidVoxyInvoiceConfirmationTitle')}
      on:cancelAndClose={() => {
        displayVoidInvoiceConfirmation = false
      }}
      on:confirmAction={() => voidInvoice(businessDocument, setIsVoiding)}
      open={displayVoidInvoiceConfirmation}
      primaryButtonText={t('invoices.viewInvoice.voidVoxyInvoiceConfirmButtonModal')}
      secondaryButtonText={t('invoices.viewInvoice.voidVoxyInvoiceCancelButtonModal')}
      >{t('invoices.viewInvoice.voidVoxyInvoiceBody')}</ConfirmationModal
    >

    {#if isContentLoading}
      <div class="grid h-screen place-items-center w-full z-[100]">
        <Loader />
      </div>
      <div class="absolute top-0 left-0 w-full h-full opacity-20 bg-whisper z-50 min-h-screen"></div>
    {:else}
      <header
        class="border-b border-athensGray flex align-middle items-center justify-center z-50"
        style="grid-column: 1/span 2; justify-content: flex-end; padding: 16px;"
      >
        <h2 class="font-bold text-xl mr-auto">
          {t(
            getBusinessDocumentMainTitle(
              businessDocument.businessDocumentKind,
              businessDocument.taxonomyTags,
              businessDocument.hasSpecificInstallment,
            ),
          )}
          {businessDocument?.businessDocumentNumber}
        </h2>
        <div class="flex items-center space-x-3 mr-16">
          <div class="relative flex flex-col items-center group">
            <DownloadVoxyPDFButton {businessDocument} {attachment} {isDownloadingAttachment} />
          </div>
          {#if !isBusinessDocumentVoided}
            <Tooltip>
              <div slot="activator">
                <button
                  disabled={!isMenuAccessible}
                  on:click|preventDefault|stopPropagation={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    displayVoidInvoiceConfirmation = true
                  }}
                  class="h-12 w-12 flex items-center justify-center rounded hover:border hover:border-black hover:text-black focus:z-10 focus:ring-2 focus:ring-whisper"
                  aria-label={t('invoices.viewInvoice.buttons.voidButton')}
                  type="button"
                >
                  <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M12.25 19.25H7.75C6.64543 19.25 5.75 18.3546 5.75 17.25V6.75C5.75 5.64543 6.64543 4.75 7.75 4.75H14L18.25 9V13.25"
                    />
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M19.25 17.25L15.75 17.25"
                    />
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M18 9.25H13.75V5"
                    />
                  </svg>
                </button>
              </div>
              {t('invoices.viewInvoice.buttons.voidButton')}
            </Tooltip>
          {/if}

          <Tooltip>
            <div slot="activator">
              <button
                disabled={!isMenuAccessible}
                on:click={() => {
                  duplicateInvoice(businessDocument, setIsDuplicating)
                }}
                class="h-12 w-12 flex items-center justify-center rounded hover:border hover:border-black hover:text-black focus:z-10 focus:ring-2 focus:ring-whisper"
                aria-label={t('invoices.viewInvoice.buttons.copyButton')}
                type="button"
              >
                {#if !$isDuplicating}
                  <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M6.5 15.25V15.25C5.5335 15.25 4.75 14.4665 4.75 13.5V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H13.5C14.4665 4.75 15.25 5.5335 15.25 6.5V6.5"
                    ></path>
                    <rect
                      width="10.5"
                      height="10.5"
                      x="8.75"
                      y="8.75"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      rx="2"
                    ></rect>
                  </svg>
                {:else}
                  <svg
                    class="animate-spin h-5 w-5 text-dundyOrange"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                {/if}
              </button>
            </div>
            {t('invoices.viewInvoice.buttons.copyButton')}
          </Tooltip>

          {#if dunv0 && !isBusinessDocumentVoided && invoice?.status !== InvoiceStatus.PAID}
            <ViewTodoDunningButton
              isDisabled={!isMenuAccessible}
              invoiceNumber={businessDocument?.businessDocumentNumber}
              customerId={businessDocument?.customerCustomer?.company?.companyId}
              invoiceId={businessDocument?.businessDocumentId}
            />
          {/if}

          {#if cstpl}
            <ViewTodoDunningButton
              isDisabled={!isMenuAccessible}
              invoiceNumber={businessDocument?.businessDocumentNumber}
              customerId={businessDocument?.customerCustomer?.company?.companyId}
              invoiceId={businessDocument?.businessDocumentId}
              customTemplate={true}
            />
          {/if}
        </div>
      </header>

      <main>
        <BusinessDocumentPDFViewer {businessDocument} {attachment} {isDownloadingAttachment} />
      </main>

      <aside class="w-full border-l border-athensGray overflow-y-auto">
        <ViewBusinessDocumentCompanyLogoAndName bind:businessDocument />

        <div class="py-3">
          <ViewBusinessDocumentKeyInformation bind:businessDocument />
        </div>

        <ViewBusinessDocumentNavigation bind:businessDocument />

        <Router>
          <Route path="details">
            <ViewBusinessDocumentDetails {businessDocument} />
          </Route>
          <Route path="activity">
            <ViewBusinessDocumentActivity {businessDocument} />
          </Route>
          <Route path="documents">
            {#if businessDocument?.relatedBusinessDocuments.length}
              <BusinessDocumentRelatedDocumentsPanel bind:businessDocument />
            {/if}
          </Route>
        </Router>
      </aside>
    {/if}
  </div>
</FullSizeModal>
