<script lang="ts">
  import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
  import { createEventDispatcher } from 'svelte'

  export let visible:boolean = false
  export let disabled:boolean = false
  export let loading:boolean = true
  export let displayConfirmButton:boolean = true
  export let displayCancelButton:boolean = true

  export let title:string = ''
  export let text:string = ''
  export let confirmButtonLabel:string = t('actions.confirm')
  export let cancelButtonLabel:string = t('actions.cancel')

  const dispatch = createEventDispatcher()

  $:displayHeader = title || $$slots?.header
  $:displayFooter = displayConfirmButton || displayCancelButton || $$slots?.footer

  const onCancel = () => {
    visible = false
    dispatch('cancel')
  }

  const onConfirm = () => {
    dispatch('confirm')
  }

</script>

{#if visible}
  <div class="z-[20]">
    <div class="absolute top-0 right-0 bottom-0 left-0 bg-slate-500 opacity-60" />
    <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded">
      {#if displayHeader}
        <div class="border-b py-4 px-8 bg-whisper rounded-t">
          <h1 class="text-2xl capitalize font-bold">
            {title}
            <slot name="header" />
          </h1>
        </div>
      {/if}

      <div class="py-6 px-8">
        {@html text}
        <slot />
      </div>
      
      {#if displayFooter}
        <div class="relative border-t py-4 px-8 flex items-center justify-center">
          {#if displayCancelButton}
            <button class="btn action-default h-10 mr-5" on:click={onCancel} disabled={disabled || loading}>
              {cancelButtonLabel}
            </button>
          {/if}
          {#if displayConfirmButton}
            <button class="btn action-bla primary" on:click={onConfirm} disabled={disabled || loading}>
              {confirmButtonLabel}
            </button>
          {/if}
          <slot name="footer" />

          {#if loading}
            <div class="loading"/>
          {/if}
        </div>
      {/if}

    </div>
  </div>
{/if}

<style lang="postcss">
  .loading {
    @apply pointer-events-none absolute right-10 top-6;

    &::before {
      content: '';
      @apply absolute w-5 h-5 z-10 -ml-px rounded-full border-2 border-r-dundyOrange;
      animation: disabled-loader 1s infinite linear;
    }
  }
</style>