import { t } from '$core/lib/i18n/i18nextWrapper'
import { type BarChoice } from '$shared/components/bar/bar.d'
import { DunningMessageKind } from '../models/dunning-message-template'

export type Field = {
  key:string,
  label?:string,
  hasLocale?:boolean,
  multiline?:boolean,
  editable?:boolean,
  clearBeforeInject?:boolean,
}

export type FormField = Field & {
  value:any,
  placeholder:any
}

export type Media = 'dunningEmail' | 'dunningPhoneCall' | 'dunningPostalLetter' | 'dunningLegalAction' | 'dunningInformation'
export type Fields = Record<Media, Field[]>
export type FieldsErrors = { [key: string]: string }

export const fields:Fields = {
  dunningEmail: [
    { key: 'emailFrom', label: t('template.modal.from'), editable: false, clearBeforeInject: true },
    { key: 'emailsTo', label: t('template.modal.to') },
    { key: 'emailsCC', label: t('template.modal.cc') },
    { key: 'emailsCCI', label: t('template.modal.cci') },
    { key: 'subject', label: t('template.modal.subject'), hasLocale: true },
    { key: 'body', hasLocale: true, multiline: true }
  ],
  dunningPhoneCall: [
    { key: 'contactDestination', label: t('template.modal.to') },
    { key: 'callCanevas', hasLocale: true, multiline: true }
  ],
  dunningPostalLetter: [
    { key: 'contactDestination', label: t('template.modal.attentionOf') },
    { key: 'mailAddressDestination', label: t('template.modal.mailTo') },
    { key: 'letterSubject', label: t('template.modal.subject'), hasLocale: true },
    { key: 'letterBody', hasLocale: true, multiline: true }
  ],
  dunningLegalAction: [
    { key: 'content', hasLocale: true, multiline: true }
  ],
  dunningInformation: [
    { key: 'content', hasLocale: true, multiline: true }
  ]
}

export const medias:BarChoice[] = [
  { key: DunningMessageKind.DunningEmail, label: t('todoAction.dunningMeansTab.email'), icon: '/img/icons/mail.svg' },
  { key: DunningMessageKind.DunningPhoneCall, label: t('todoAction.dunningMeansTab.phoneCall'), icon: '/img/icons/phone.svg' },
  { key: DunningMessageKind.DunningPostalLetter, label: t('todoAction.dunningMeansTab.postalLetter'), icon: '/img/icons/mailbox.svg' },
  { key: DunningMessageKind.DunningLegalAction, label: t('todoAction.dunningMeansTab.legalAction'), icon: '/img/icons/legal.svg' },
  { key: DunningMessageKind.DunningInformation, label: t('todoAction.dunningMeansTab.information'), icon: '/img/icons/information.svg' }
]
  
export const modes:BarChoice[] = [
  { key: 'edit', label: t('actions.edit'), icon: '/img/icons/edit.svg' },
  { key: 'preview', label: t('actions.preview'), icon: '/img/icons/view.svg' }
]


export const getEmailWrapper = (language:string = 'fr', title:string = '', content:string = '', senderName:string = '', senderSiren:string = '', senderAddress:string = '', senderLogoUrl:string = '') => 
  `<!DOCTYPE html>
<html lang="${language}">

  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <title>${title}</title>
    <style>
      body {
        margin: 0;
        padding: 0;
        background-color: white;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
      }
      table {
        border-spacing: 0;
        border-collapse: collapse;
      }
      td {
        padding: 0;
      }
      img {
        border:none;
        display: block;
      }
      a {
        outline: none;
        color: #9b81f6;
        text-decoration: underline;
      }
      a:hover {
        text-decoration: none !important;
      }
      .wrapper {
        width: 100%;
      }
      .container {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        background-color: #ffffff;
        font-weight: normal;
        border-collapse: separate;
      }
      .header, .footer {
        padding: 20px;
        color:#606F82;
        font-size:12px;
      }
      .header {
        text-align: right;
      }
      .header table {
        float: right;
        font-weight: normal;
      }
      .footer {
        text-align: center;
      }
      .footer table {
        text-align: center;
        margin: 0 auto;
      }
      .content {
        padding: 20px;
        font-family: Arial, sans-serif;
        font-size: 14px;
        line-height: 1.6;
        color: #333333;
        border: 1px solid #dcdcd9;
        border-radius: 8px;
        text-align: left;
      }
      .ligne {
        padding: 10px 0;
      }
    </style>
  </head>

  <body>
    <table class="wrapper">
      <tr>
        <th>
          <table class="container">
            <tr>
              <th class="header">
                ${senderLogoUrl
    ? `<img src=${senderLogoUrl} alt="${senderName}" width="64"/>`
    : ''
}
              </th>
              <th class="header">
                <table>
                  <tr>
                    <th style="font-weight:bold; color:#1F2533">
                      ${senderName}
                    </th>
                  </tr>
                  ${senderSiren
    ? `<tr><td>${t('todoAction.email.content.siren', { senderSiren })}</td></tr>`
    : ''
}
                  ${senderAddress}
                </table>
              </th>
            </tr>

            <tr>
              <td class="content" colspan="2">
                <table>
                  ${content}
                </table>
              </td>
            </tr>

            <tr>
              <td class="footer" colspan="2">
                <table>
                  <tr>
                    <th>
                        <img src="http://cdn.mcauto-images-production.sendgrid.net/d4710199857cf96f/642e548b-7725-49bb-9c03-595a05a9b94a/128x128.png" alt="Dundy" width="32" style="margin: 0 auto"/>
                    </th>
                  </tr>
                  <tr><td class="ligne"></td></tr>
                  <tr>
                    <td style="font-weight:bold; color:#1F2533">
                      ${t('todoAction.email.footer.title')}
                    </td>
                  </tr>
                  <tr><td class="ligne"></td></tr>
                  <tr>
                    <td>
                      ${t('todoAction.email.footer.content', { senderName })}
                    </td>
                  </tr>
                  <tr><td class="ligne"></td></tr>
                  <tr>
                    <td>
                      Dundy (U-Trade SAS),<br>18 avenue de la Bourdonnais, 75007 Paris, FRANCE
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </th>
      </tr>
    </table>
  </body>

</html>`

