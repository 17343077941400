<script lang="ts">
  import { addNewContact, updateExistingContact, deleteContactFromContactsStore } from '$crm/lib/customer/create-update-delete-contact'
  import { addNewCustomer, deleteCustomerFromCustomerStore, updateExistingCustomer } from '$crm/lib/customer/create-update-delete-customer'
  import { Contact } from '$crm/models/contact'
  import { ContactsStore } from '$crm/stores/contacts.store'
  import { Customer } from '$crm/models/customer'
  import { CustomersHelper } from '$crm/helpers/customers-helper'
  import { CustomersStore } from '$crm/stores/customers.store'
  import { CustomerType, ItemType } from '$crm/enums/customer-manage'
  import { ExchangeDate } from '$core/models/exchange-date'
  import { feedbackService } from '$core/services/feedback.service'
  import { get } from 'svelte/store'
  import { getDunningWorkflow, saveDunningWorkflow, type DunningWorkflow } from '$src/dundy-app/services/dunning-workflow.service'
  import { navigate } from 'svelte-routing'
  import { onMount } from 'svelte'
  import { Severity, remoteDebuggingService } from '$core/services/remote-debugging.service'
  import { t } from '$core/lib/i18n/i18nextWrapper'
  import { ToastType } from '$core/enums/feedback-enums'
  import Company from '../../models/company'
  import CompanyEdition from '$core/components/Onboarding/CompanyEdition.svelte'
  import CompanySearch from '$core/components/Onboarding/CompanySearch.svelte'
  import ConfirmationModal from '$core/lib/ui-kit/ConfirmationModal.svelte'
  import ContactForm from '$crm/components/ui-kit/ContactForm.svelte'
  import ContactList from '$crm/components/ui-kit/ContactList.svelte'
  import CustomerPreview from '$crm/components/ui-kit/CustomerPreview.svelte'
  import FullSizeModal from '$core/lib/ui-kit/FullSizeModal.svelte'
  import languages from '$core/lib/i18n/languages-all'
  import StyledSelect from '$core/lib/ui-kit/StyledSelect.svelte'
  import type { Feedback } from '$core/models/feedback'

  export let customerId: string = ''
  export let contactId: string = ''

  const prodEnv = process.env.APP_ENV === 'prod remote'

  const toast = (msg: string, type: ToastType) => feedbackService.displayFeedback({
    feedbackMessage: msg,
    feedbackLevel: type
  } as Feedback)

  let isFetching: boolean = false
  let customerRadio: string = CustomerType.FrenchCompany
  let customer: Customer
  let initCompany: Company
  let company: Company
  let initContact: Contact = new Contact()
  let contact: Contact = new Contact()
  let isEditing: boolean = true
  let isManual: boolean = false
  let isPrivateIndividual: boolean = false
  let isEditingContact: boolean = false
  let displayModal: boolean = false
  let modalProps: {
    modalHeading: string,
    primaryButtonText: string
    secondaryButtonText: string
    primaryColor: string
  }
  let itemToDelete: ItemType
  let dunningWorkflowPreference: DunningWorkflow

  $:contacts = get(ContactsStore).filter(c => c.companyId === company?.companyId)

  $:displayCompanyEdition =
    customerId ||
    (customerRadio === CustomerType.FrenchCompany.toString() && !!company?.formalName) ||
    (customerRadio !== CustomerType.FrenchCompany.toString() && company?.companyId && contact)

  $:locales = dunningWorkflowPreference ? languages.filter(l => dunningWorkflowPreference.prefItem.dunningWorkflowL10ns.includes(l.value)) : languages

  const close = (forceToList: boolean = false) => {
    const searchParams = new URLSearchParams(window.location.search)
    const from = searchParams.get('from')

    if (forceToList) {
      navigate('/clients/manage')

      return
    }

    switch (from) {
      case 'invoice': {
        const client = searchParams.get('client')
        if (client) navigate(`/receivables/new/commercial-invoice/details?client=${client}`)
      }
        break
      case 'card' :
        navigate(`/clients/${customerId}`)
        break
      case 'list':
      default:
        navigate('/clients/manage')
        break
    }
  }

  const initNew = () => {
    customer = CustomersHelper.newCustomer()
    company = customer.company
    company.registrationPending = false
    contact = Contact.empty('', company.companyId)
  }

  const contactSave = async (displayFeedback: boolean = false) => {
    if (!contact || !customer) return false

    try {
      const save = ((isPrivateIndividual && customerId) || initContact.companyId) 
        ? updateExistingContact(contact, $ContactsStore)
        : addNewContact(contact, customer as Customer, $ContactsStore)

      await save

      if (displayFeedback) toast(customerId ? t('editCustomer.contactUpdated') : t('editCustomer.contactAdded'), ToastType.Success)

      const client = new URL(window.location.toString()).searchParams?.get('client')
      if (client) close()
      
      return true
    } catch (err) {
      if (displayFeedback) toast(t('editCustomer.error'), ToastType.Error)
      remoteDebuggingService.addInfo(err, Severity.Error)

      return false
    }
  }

  const customerSave = async () => {
    const successMsg = customerId ? t('editCustomer.customerUpdated') : t('editCustomer.customerAdded')
    const errorMsg = t('editCustomer.error')

    try {
      if (isPrivateIndividual) {
        customer.isPrivateIndividual = true
        company.formalName = contact.firstName + ' ' + contact.lastName
        company.usualName = ''
        company.shareCapital = ''
        company.registrationDate.rfc3339 = ''
        company.registrationDate.unixSeconds = 0
        company.regulatory.isEuRegulated = false
        company.fyDetails = null
        company.regulatory.frRegulScope = null
        company.regulatory.euRegulScope = null
      }

      if (company.mailAddress.countryCode === 'FR' && company.regulatory.frRegulScope) company.regulatory.frRegulScope.adminName = company.formalName
      customer.company = company

      if (Boolean(customerId)) {
        company.modifiedDate = ExchangeDate.newDate(new Date())
        await updateExistingCustomer(customer)
      } else {
        company.createdDate = ExchangeDate.newDate(new Date())
        await addNewCustomer(customer)
      }

      // Private individual Customer
      if (isPrivateIndividual) {
        const success: Boolean = await contactSave(false)
        if (success) {
          toast(successMsg, ToastType.Success)
          close()
        } else {
          toast(errorMsg, ToastType.Error)
        }
      } else {
        // French or Foreign Customer
        toast(successMsg, ToastType.Success)
      }

    } catch (err) {
      remoteDebuggingService.addInfo(err, Severity.Error)
      toast(errorMsg, ToastType.Error)
    }
  }

  const onRadioChange = (customerType: CustomerType) => {
    isEditing = true

    // do not clear fields if action = update customer
    if (!customerId) initNew()
    if (customerRadio === CustomerType.ForeignCompany.toString()) {
      company.mailAddress.country = ''
      company.mailAddress.countryCode = ''
    }

    isPrivateIndividual = customerType === CustomerType.PrivateIndividual
    isManual = customerRadio !== CustomerType.FrenchCompany.toString()
  }

  const onCompanySelected = async (c: any) => {
    c.companyId = company.companyId
    company = c
  }

  // const onManualRegisterSelected = async (value: string) => {
  //   isEditing = true
  //   isManual = true

  //   company = Company.empty()
  //   manualRegistration = Boolean(value)
  //   company.registrationPending = false
    
  //   customerRadio = CustomerType.FrenchCompany
  // }

  const onContactNew = () => {
    initContact = Contact.empty()
    contact = Contact.empty('', company.companyId)

    isEditingContact = true
  }

  const onContactCancel = () => {
    initContact = Contact.empty()
    contact = Contact.empty()
    isEditingContact = false
  }

  const onContactSelect = (c: Contact) => {
    isEditingContact = true
    initContact = JSON.parse(JSON.stringify(c))
    contact = c
  }

  const onContactSave = async () => {
    await contactSave(true)
    isEditingContact = false
  }

  const onContactDelete = async () => {
    itemToDelete = ItemType.ItemContact
    modalProps = {
      modalHeading: t('customerList.modal.deleteHeading'),
      primaryButtonText: t('customerList.modal.deletePrimaryButton'),
      secondaryButtonText: t('customerList.modal.deleteSecondaryButton'),
      primaryColor: 'mojo'
    }
    displayModal = true
  }

  const onCompanyCancel = () => {
    // if (manualRegistration) {
    //   manualRegistration = false
    //   customerRadio = CustomerType.FrenchCompany
    // }

    isEditing = true
    company = Company.empty()

    if (customerId) close()
  }

  const onCompanySave = async () => {
    isFetching = true

    const req = [customerSave()]
    if (customerId) req.push(saveDunningWorkflow(dunningWorkflowPreference, customerId))

    await Promise.all(req)

    isFetching = false

    // close only if updating
    if (customerId) {
      close()
    } else {
      customerId = company.companyId
      onContactNew()
    }
  }

  const onCustomerDelete = async () => {
    itemToDelete = ItemType.ItemCustomer
    modalProps = {
      modalHeading: t('customerList.modal.deleteHeading'),
      primaryButtonText: t('customerList.modal.deletePrimaryButton'),
      secondaryButtonText: t('customerList.modal.deleteSecondaryButton'),
      primaryColor: 'mojo'
    }
    displayModal = true
  }

  const onTemplateEdit = () => navigate(`/workflow?cid=${customerId}`)

  const onModalValidate = async () => {
    try {
      if (itemToDelete === ItemType.ItemCustomer) {
        await deleteCustomerFromCustomerStore(customerId)
      } else {
        await deleteContactFromContactsStore(contact)
      }

      toast(itemToDelete === ItemType.ItemCustomer
        ? t('customerManage.feedback.customerDelete')
        : t('customerManage.feedback.contactDelete')
      , ToastType.Success)
    } catch (err) {
      remoteDebuggingService.addInfo(String(err), Severity.Error)
      toast(itemToDelete === ItemType.ItemCustomer
        ? t('customerManage.feedback.customerDeleteError')
        : t('customerManage.feedback.contactDeleteError'),
      ToastType.Error)
    }

    displayModal = false
    if (itemToDelete === ItemType.ItemCustomer) close(true)
    if (itemToDelete === ItemType.ItemContact) onContactCancel()
  }

  onMount(async () => {
    if (customerId) {
      const cust = get(CustomersStore).find((c) => c.company.companyId === customerId)

      if (cust) {
        customer = cust
        initCompany = customer.company
        company = JSON.parse(JSON.stringify(initCompany))
        
        contacts = get(ContactsStore).filter(c => c.companyId === company?.companyId)
        if (customer.isPrivateIndividual && contacts.length) {
          contact = contacts[0]
          initContact = JSON.parse(JSON.stringify(contact))
        }

        // UI
        isPrivateIndividual = customer.isPrivateIndividual
        customerRadio = customer.isPrivateIndividual ?
          CustomerType.PrivateIndividual : customer.company?.regulatory?.frRegulScope?.siren ?
            CustomerType.FrenchCompany :
            CustomerType.ForeignCompany

        // NEW CONTACT
        if (window.location.pathname.endsWith('/contacts/new')) onContactNew()

        // EDIT CONTACT
        if (contactId) {
          const ctt = get(ContactsStore).find(c => c.contactId === contactId)
          if (ctt) onContactSelect(ctt)
        }

        // WORKFLOW + LANGUAGES
        const res = await getDunningWorkflow(customerId)
        if (res.workflow) dunningWorkflowPreference = res.workflow

      } else {
        remoteDebuggingService.addInfo(`CustomerManage.svelte : Customer ID ${customerId} not found`, '', Severity.Error)
      }
    } else {
      initNew()
    }
  })
</script>

<FullSizeModal
  class="w-full h-full"
  isCloseable={true}
  on:close={() => close()}
  showFooter={true}>

  <div slot="content" class="h-full flex">
    <div class="flex flex-col flex-shrink-0 w-96 p-2 pt-8 border-r border-athensGray">

      <h1 class="font-bold text-4xl px-4 mb-6">
        {customerId ? t('customerManage.page.editClient') : t('customerManage.page.newClient')}
      </h1>

      <div>
        {#if company}
          <CustomerPreview {company} {contact} {isEditingContact} {isPrivateIndividual} {customerRadio}/>

          {#if !isEditingContact && !prodEnv && contacts.length}
            <div class="text-sm text-gray-900 border-t m-4">
              <h2 class="font-medium text-base bg-white py-4">
                {t('customerManage.form.templatesTitle')}
              </h2>

              {#if dunningWorkflowPreference?.prefItem?.defaultL10n}
                <div>
                  {t('customerManage.form.templatesLanguage')}
                  <StyledSelect
                    class="my-2"
                    bind:value={dunningWorkflowPreference.prefItem.defaultL10n}
                    disabled={false}
                    isSearchable={true}
                    items={locales}
                  />
                </div>
              {/if}
              <div class="flex justify-between items-center">
                {t('customerManage.form.templatesEdition')}
                <button class="edit" on:click={() => onTemplateEdit()}>
                  <div class="icon"/>
                </button>
              </div>
            </div>
          {/if}

          {#if customerId && !isPrivateIndividual && !isEditingContact}
            <div class="mt-6">
              <ContactList
                {contact}
                {contacts}
                on:select={(e) => onContactSelect(e.detail)}
                on:createNew={() => onContactNew()}
              />
            </div>
          {/if}
        {/if}
        
      </div>
    </div>

    <div class="w-full flex flex-col items-center overflow-y-auto pt-16 bg-slate-50">
      <div class="w-8/12">

        {#if !isEditingContact}

            <fieldset>
              <legend
                class="text-base font-semibold leading-6 text-gray-900">{t('customerManage.form.selectClientType')}</legend>

              <div class="my-4 mb-8 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">

                <label class="relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                      class:disabled={customerId && customerRadio !== CustomerType.FrenchCompany}
                      on:change={() => onRadioChange(CustomerType.FrenchCompany)}>
                  <input type="radio" bind:group={customerRadio} id="company" value={CustomerType.FrenchCompany}
                        name="project-type" class="sr-only" aria-labelledby="project-type-0-label"
                        aria-describedby="project-type-0-description-0 project-type-0-description-1">
                  <span class="flex flex-1">
                    <span class="flex flex-col">
                      <span class="flag">
                        <span role="region" aria-hidden="true" class="france-flag">
                        </span>
                      </span>
                      <span id="project-type-0-label"
                            class="block text-lg font-medium text-black">{t('customerManage.form.frenchPrivateCompany')}</span>
                      <span id="project-type-0-description-0"
                            class="mt-1 flex items-center text-sm text-gray-500">{t('customerManage.form.frenchPrivateCompanyCopy')}</span>
                    </span>
                  </span>

                  <svg class="h-5 w-5 text-dundyOrange absolute top-1 right-1"
                      class:invisible={customerRadio !== CustomerType.FrenchCompany} viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                          clip-rule="evenodd"/>
                  </svg>

                  <span class="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent"
                        class:border-highlight={customerRadio === CustomerType.FrenchCompany} aria-hidden="true"></span>
                </label>

                <label class="relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                      class:disabled={customerId && customerRadio !== CustomerType.ForeignCompany}
                      on:change={() => onRadioChange(CustomerType.ForeignCompany)}>
                  <input type="radio" bind:group={customerRadio} id="foreignCompany" value={CustomerType.ForeignCompany}
                        name="project-type" class="sr-only" aria-labelledby="project-type-1-label"
                        aria-describedby="project-type-1-description-0 project-type-1-description-1">
                  <span class="flex flex-1">
                    <span class="flex flex-col">
                      <img class="w-12 h-12 my-1" src="/img/worldwide-company.png"
                          alt="{t('customerManage.form.foreignPrivateCompany')}"/>
                      <span id="project-type-0-label"
                            class="block text-lg font-medium text-black">{t('customerManage.form.foreignPrivateCompany')}</span>
                      <span id="project-type-1-description-0"
                            class="mt-1 flex items-center text-sm text-gray-500">{t('customerManage.form.foreignPrivateCompanyCopy')}</span>
                    </span>
                  </span>
                  <svg class="h-5 w-5 text-dundyOrange absolute top-1 right-1"
                      class:invisible={customerRadio !== CustomerType.ForeignCompany} viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                          clip-rule="evenodd"/>
                  </svg>

                  <span class="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent"
                        class:border-highlight={customerRadio === CustomerType.ForeignCompany} aria-hidden="true"></span>
                </label>


                <label class="relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                      class:disabled={customerId && customerRadio !== CustomerType.PrivateIndividual}
                      on:change={() => onRadioChange(CustomerType.PrivateIndividual)}>
                  <input type="radio" bind:group={customerRadio} id="privateIndividual"
                        value={CustomerType.PrivateIndividual} name="project-type" class="sr-only"
                        aria-labelledby="project-type-2-label"
                        aria-describedby="project-type-2-description-0 project-type-2-description-1">
                  <span class="flex flex-1">
                    <span class="flex flex-col">
                      <svg class="h-12 w-12 mt-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.25 7C14.25 8.24264 13.2426 9.25 12 9.25C10.7574 9.25 9.75 8.24264 9.75 7C9.75 5.75736 10.7574 4.75 12 4.75C13.2426 4.75 14.25 5.75736 14.25 7Z"
                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path
                          d="M12 9.75C8.6 9.75 7.75 11.5 7.75 14.25H9.75V17.25C9.75 18.3546 10.6454 19.25 11.75 19.25H12.25C13.3546 19.25 14.25 18.3546 14.25 17.25V14.25H16.25C16.25 11.5 15.4 9.75 12 9.75Z"
                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg>
                      <span id="project-type-0-label"
                            class="block text-lg font-medium text-black mt-1">{t('customerManage.form.individual')}</span>
                      <span id="project-type-2-description-0"
                            class="mt-1 flex items-center text-sm text-gray-500">{t('customerManage.form.individualCopy')}</span>
                    </span>
                  </span>

                  <svg class="h-5 w-5 text-dundyOrange absolute top-1 right-1"
                      class:invisible={customerRadio !== CustomerType.PrivateIndividual} viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                          clip-rule="evenodd"/>
                  </svg>

                  <span class="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent"
                        class:border-highlight={customerRadio === CustomerType.PrivateIndividual}
                        aria-hidden="true"></span>
                </label>

              </div>
            </fieldset>
          
            {#if !customerId}
              <CompanySearch
                {isFetching}
                display={customerRadio === CustomerType.FrenchCompany.toString() && !Boolean(company?.formalName) ? 'block' : 'none'}
                on:selectCompany={c => onCompanySelected(c.detail)}
              />
            {/if}

          {#if displayCompanyEdition}

            {#if contact}
              <CompanyEdition
                bind:company={company}
                bind:isEditing={isEditing}
                bind:firstName={contact.firstName}
                bind:lastName={contact.lastName}
                bind:mail={contact.email}
                bind:mobilePhone={contact.mobilePhone}
                bind:officePhone={contact.officePhone}
                {isPrivateIndividual}
                {isManual}
                isForeignCompany={customerRadio === CustomerType.ForeignCompany.toString()}
                isRegistrationPending={Boolean(false)}
                displayCancelButton={!!customerId || customerRadio === CustomerType.FrenchCompany.toString()}
                on:cancel={() => onCompanyCancel()}
                on:save={() => onCompanySave()}
              />
            {/if}

            {#if customerId}
              <div class="text-center mt-4 mb-12">
                <button class="text-sm text-red-500 hover:border-b border-red-500"
                        on:click={() => onCustomerDelete()}>
                  {t('customerManage.form.deleteClient')}
                </button>
              </div>
            {/if}
          {/if}

        {:else}
          <div class="w-full p-9 rounded-md border border-zinc-200">
            <ContactForm bind:editedContact={contact} let:canSubmitContact>
              <div class="mx-auto w-full justify-center space-x-6 mt-12 flex">
                <button type="button" class="btn action-default mr-5" on:click={() => onContactCancel()}>
                  {t('onboarding.companyInfo.edit.cancel')}
                </button>
                <button type="button" class="btn action-bla primary" disabled={!canSubmitContact}
                        on:click={() => onContactSave()}>
                  {t('customerManage.form.saveClient')}
                </button>
              </div>
            </ContactForm>
          </div>

          {#if initContact.companyId}
            <div class="text-center">
              <button class="mt-6 text-sm text-red-500 hover:border-b border-red-500"
                      on:click={() => onContactDelete()}>
                {t('customerManage.form.deleteContact')}
              </button>
            </div>
          {/if}
        {/if}
      </div>
    </div>

    <ConfirmationModal
      on:cancelAndClose={() => { displayModal = false }}
      on:confirmAction={() => onModalValidate()}
      open={displayModal}
      {...modalProps}
    />

  </div>
</FullSizeModal>

<style lang="postcss">
  .disabled {
    pointer-events: none;
    opacity: .4;
  }

  .border-highlight {
    @apply border-dundyOrange;
  }

  .flag {
    @apply w-12 p-0 my-3 rounded-md overflow-hidden;
  }

  .france-flag {
    @apply block w-full bg-white relative m-0;
    padding-bottom: 66.667%;
  }

  .france-flag::before {
    content: ' ';
    position: absolute;
    background-color: #0055A4;
    width: 30%;
    height: 100%;
  }

  .france-flag::after {
    content: ' ';
    position: absolute;
    background-color: #EF4135;
    width: 30%;
    height: 100%;
    right: 0;
  }

  .edit {
    @apply rounded border-2 border-transparent size-10;

    &:hover {
      @apply bg-provincialPink  border-dundyOrange;
    }

    .icon {
      mask: url("/img/icons/edit.svg");
      mask-repeat: no-repeat;
      @apply size-6 bg-black m-1.5;
    }
  }

</style>