<script lang="ts">
    import { Link } from 'svelte-routing'

    export let to = ''

    function getProps({ location, href, isPartiallyCurrent, isCurrent }) {
      const isActive = href === '/' ? isCurrent : isPartiallyCurrent || isCurrent

      // TMP : home customer workflow
      const url = new URL(location.href)
      if (url.pathname === '/workflow' && url.searchParams.has('cid')) return { class: 'nav-link' }

      if (isActive) return { class: 'nav-link active' }

      return { class: 'nav-link' }
    }
</script>

<Link {getProps} {to} on:click>
    <slot/>
</Link>
<style lang="postcss" global>
    .nav-link {
        @apply flex rounded-md text-sm bg-none relative w-full text-white cursor-pointer items-center justify-between appearance-none my-1 h-[37px] px-3 hover:bg-rhino border-t border-transparent hover:border-warmGray hover:border-opacity-10;
    }
    .nav-link-disabled {
        @apply flex rounded-md text-sm bg-none relative w-full text-white items-center justify-between appearance-none my-1 h-[37px] px-3 opacity-50 cursor-default;
    }

    .nav-link svg {
        @apply text-zinc-500;
    }

    .nav-link:hover svg {
        @apply text-white;
    }

    .nav-link.active {
        @apply bg-rhino border-t border-warmGray border-opacity-10;
    }
    .nav-link.active svg {
        @apply text-white;
    }
</style>