<script lang="ts">
  import { t } from '../../core-app/lib/i18n/i18nextWrapper'
  import BusinessNumberPreferencePanel from '../lib/invoice-creation/form/BusinessNumberPreferencePanel.svelte'
  import { BusinessDocumentKind } from '../enums/business-document-kind'
  import type { NumberingScheme, NumberingSchemeSettings } from '../models/voxy-preferences'
  import { VoxyPreferencesStore } from '../stores/voxy-preference.store'
  import {
    replaceAllParenthesesPlaceholdersWithValues,
    replaceDoubleAccoladesWithParenthesesAndTranslatePlaceholders,
    replaceNumberOfDigitsWithZeroes
  } from '../utils/numbering-utils'
  import { get, type Unsubscriber } from 'svelte/store'
  import { ProfileStore } from '../../core-app/stores/profile.store'
  import { currentLanguageCode } from '../../core-app/stores/i18n.store'
  import { onDestroy, onMount } from 'svelte'
  import mixpanel from 'mixpanel-browser'
  import InvoiceCustomisation from '../component/InvoiceCustomisation.svelte'
  import FeatureToggle from '$src/config/FeatureToggle.svelte'

  /** Const & Var declarations **/
  let showBusinessDocumentNumberingPreferences: boolean
  let savedInvoiceNumberingCodex: string
  let savedRFPNumberingCodex: string
  let creditNoteNumberingCodex: string
  let documentKind: BusinessDocumentKind
  let unsubscribeVoxyPreferencesStore: Unsubscriber

  let viewerZoom: boolean = false

  /**
   * Get the current business document number template depending on the document kind
   * @param numberingSchemesByBusinessDocument
   * @param documentKind
   * @returns {string}
   */
  function getCurrentlySetBusinessDocumentNumberTemplateDependingOnKind(
    numberingSchemesByBusinessDocument: Map<BusinessDocumentKind, NumberingScheme>,
    documentKind: BusinessDocumentKind,
  ): string {
    if (
      !numberingSchemesByBusinessDocument ||
      !numberingSchemesByBusinessDocument[documentKind] ||
      !numberingSchemesByBusinessDocument[documentKind].currentNumberingScheme ||
      !numberingSchemesByBusinessDocument[documentKind].currentNumberingScheme.documentNumberTemplate ||
      numberingSchemesByBusinessDocument[documentKind].currentNumberingScheme.documentNumberTemplate === ''
    ) {
      return undefined
    }
    let businessDocumentNumberingScheme: NumberingSchemeSettings =
      numberingSchemesByBusinessDocument[documentKind].currentNumberingScheme
    let documentNumberTemplate: string = replaceDoubleAccoladesWithParenthesesAndTranslatePlaceholders(
      businessDocumentNumberingScheme.documentNumberTemplate,
      get(currentLanguageCode),
    )
    let sequenceNumberOfDigits: number = businessDocumentNumberingScheme.sequenceNumberOfDigits
    let initialDocumentSequenceNumber: number =
      numberingSchemesByBusinessDocument[documentKind].lastDocumentSequenceNumbering &&
      numberingSchemesByBusinessDocument[documentKind].lastDocumentSequenceNumbering.number > 1
        ? numberingSchemesByBusinessDocument[documentKind].lastDocumentSequenceNumbering.number
        : businessDocumentNumberingScheme.initialDocumentSequenceNumber
    let automaticBusinessDocumentNumberingSuffix: string = replaceNumberOfDigitsWithZeroes(
      initialDocumentSequenceNumber,
      sequenceNumberOfDigits,
    )
    if (documentKind === BusinessDocumentKind.INVOICE) {
      return (
        replaceAllParenthesesPlaceholdersWithValues(
          documentNumberTemplate,
          get(ProfileStore).lang.split('-')[0].toLocaleLowerCase(),
        ) + automaticBusinessDocumentNumberingSuffix
      )
    } else if (documentKind === BusinessDocumentKind.QUOTE) {
      return (
        replaceAllParenthesesPlaceholdersWithValues(
          documentNumberTemplate,
          get(ProfileStore).lang.split('-')[0].toLocaleLowerCase(),
        ) + automaticBusinessDocumentNumberingSuffix
      )
    } else if (documentKind === BusinessDocumentKind.CREDITNOTE) {
      return (
        replaceAllParenthesesPlaceholdersWithValues(
          documentNumberTemplate,
          get(ProfileStore).lang.split('-')[0].toLocaleLowerCase(),
        ) + automaticBusinessDocumentNumberingSuffix
      )
    }
  }

  onMount(() => {
    mixpanel.track('IE10 Edit Voxy Settings Page', {
      Description: 'Open EditVoxySettings.svelte'
    })
    if (!!unsubscribeVoxyPreferencesStore) {
      unsubscribeVoxyPreferencesStore()
    }
    /* console.log('onMount - ppp!!!! vxp  running VoxyPreferencesStore.subscribe() in EditVoxySettings.svelte') */
    unsubscribeVoxyPreferencesStore = VoxyPreferencesStore.subscribe((value) => {
      savedInvoiceNumberingCodex = getCurrentlySetBusinessDocumentNumberTemplateDependingOnKind(
        get(VoxyPreferencesStore)?.numberingSchemesByBusinessDocument,
        BusinessDocumentKind.INVOICE,
      )
      savedRFPNumberingCodex = getCurrentlySetBusinessDocumentNumberTemplateDependingOnKind(
        get(VoxyPreferencesStore)?.numberingSchemesByBusinessDocument,
        BusinessDocumentKind.QUOTE,
      )
      creditNoteNumberingCodex = getCurrentlySetBusinessDocumentNumberTemplateDependingOnKind(
        get(VoxyPreferencesStore)?.numberingSchemesByBusinessDocument,
        BusinessDocumentKind.CREDITNOTE,
      )
      /* console.log(value, 'This is the subscription to VoxyPreferencesStore') */
    })
  })

  onDestroy(() => {
    if (!!unsubscribeVoxyPreferencesStore) {
      /* console.log('onDestroy - ppp!!!! vxp  running VoxyPreferencesStore.Unsubscribe() in EditVoxySettings.svelte') */
      unsubscribeVoxyPreferencesStore()
    }
  })
</script>

<svelte:head>
  <title>{t('topMenu.nav.settings.invoicingSettings')} - Dundy</title>
</svelte:head>

<section class="{viewerZoom ? 'max-w-6xl' : 'max-w-4xl'}">
  <div>
    <h2 class="text-black text-3xl font-bold">{t('topMenu.nav.settings.invoicingSettings')}</h2>
    <p class="mt-3 text-sm">{t('invoicingAppEdit.titleCopy')}</p>
  </div>

  <div
    class="bg-whisper my-6 max-w-5xl rounded-md py-8 px-6 text-[0.8125rem] leading-6 text-black ring-1 ring-slate-700/10"
  >
    {#if showBusinessDocumentNumberingPreferences}
      <BusinessNumberPreferencePanel
        {documentKind}
        on:deactivateInvoiceNumberingWindow={() => {
          showBusinessDocumentNumberingPreferences = false
        }}
      />
    {:else}
      <div class="text-xl font-semibold leading-5">{t('invoicingAppEdit.automaticNumbering.title')}</div>
      <div class="mt-2 leading-5">{t('invoicingAppEdit.automaticNumbering.description')}</div>
      <div class="mt-4 flex items-center border-t border-slate-400/20 py-3">
        <span class="w-2/5 flex-none">{t('invoicingAppEdit.automaticNumbering.invoiceNumberingTitle')}</span>
        <span class="">{savedInvoiceNumberingCodex}</span>
        <button
          on:click={() => {
            showBusinessDocumentNumberingPreferences = !showBusinessDocumentNumberingPreferences
            documentKind = BusinessDocumentKind.INVOICE
          }}
          type="button"
          class="ml-auto inline-flex h-8 items-center justify-center rounded border border-gray-300 bg-white px-2.5 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-dundyOrange focus:ring-offset-2"
        >
          {t('invoicingAppEdit.automaticNumbering.modifyButtonLabel')}
        </button>
      </div>
      <div class="flex items-center border-t border-slate-400/20 py-3">
        <span class="w-2/5 flex-none">{t('invoicingAppEdit.automaticNumbering.rfpNumberingTitle')}</span>
        <span class="">{savedRFPNumberingCodex}</span>
        <button
          on:click={() => {
            showBusinessDocumentNumberingPreferences = !showBusinessDocumentNumberingPreferences
            documentKind = BusinessDocumentKind.QUOTE
          }}
          type="button"
          class="ml-auto inline-flex h-8 items-center justify-center rounded border border-gray-300 bg-white px-2.5 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-dundyOrange focus:ring-offset-2"
        >
          {t('invoicingAppEdit.automaticNumbering.modifyButtonLabel')}
        </button>
      </div>
      <div class="flex items-center border-t border-slate-400/20 py-3">
        <span class="w-2/5 flex-none">{t('invoicingAppEdit.automaticNumbering.creditNoteNumberingTitle')}</span>
        <span class="">{creditNoteNumberingCodex}</span>
        <button
          on:click={() => {
            showBusinessDocumentNumberingPreferences = !showBusinessDocumentNumberingPreferences
            documentKind = BusinessDocumentKind.CREDITNOTE
          }}
          type="button"
          class="ml-auto inline-flex h-8 items-center justify-center rounded border border-gray-300 bg-white px-2.5 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-dundyOrange focus:ring-offset-2"
        >
          {t('invoicingAppEdit.automaticNumbering.modifyButtonLabel')}
        </button>
      </div>
    {/if}
  </div>

  <FeatureToggle featureKey="cspdf">
    <div class="relative p-6 border rounded bg-whisper">
      <h2 class="text-black text-xl font-bold mt-2 mb-2">
        {t('invoicingAppEdit.invoiceBackground.title')}
      </h2>
        <InvoiceCustomisation bind:viewerZoom />
      </div>
    <!-- <FallbackComponent slot="wall" /> -->
    <div slot="wall">CUSTOM WALL MSG</div>
  </FeatureToggle>
</section>
