import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
import { type WorkflowMedia, type WorkflowTemplate, WorkflowTemplateType } from '$dundy/data/workflow'
import { DunningMessageKind } from '../../models/dunning-message-template'

export const WorkflowMedias:WorkflowMedia[] = [
  { 
    key: DunningMessageKind.DunningEmail,
    label: t('todoAction.dunningMeansTab.email'),
    icon: '/img/icons/mail.svg' 
  },
  { 
    key: DunningMessageKind.DunningPhoneCall,
    label: t('todoAction.dunningMeansTab.phoneCall'),
    icon: '/img/icons/phone.svg' 
  },
  { 
    key: DunningMessageKind.DunningPostalLetter,
    label: t('todoAction.dunningMeansTab.postalLetter'),
    icon: '/img/icons/mailbox.svg'
  },
  { 
    key: DunningMessageKind.DunningLegalAction,
    label: t('todoAction.dunningMeansTab.legalAction'),
    icon: '/img/icons/legal.svg'
  },
  { 
    key: DunningMessageKind.dunningInformation,
    label: t('todoAction.dunningMeansTab.information'),
    icon: '/img/icons/information.svg'
  }
]

export const WorkflowTemplates:WorkflowTemplate[] = [
  {
    key: WorkflowTemplateType.DEFAULT,
    label: t('workflow.defaultTemplate'),
    icon: '/img/icons/file-text.svg' 
  },
  {
    key: WorkflowTemplateType.CUSTOM,
    label: t('workflow.customTemplate'),
    icon: '/img/icons/file-code.svg' 
  }
]