import { get } from 'svelte/store'
import { BusinessDocument, BusinessDocumentLineItem } from '../../models/business-document'
import { WorkspaceStore } from '../../../crm-app/stores/workspace.store'
import { APICallOptions, APIEntity, apiUpload } from '../../../core-app/services/api.service'
import axios, { type AxiosResponse } from 'axios'
import { pdfMakeFont } from '../../../core-app/lib/pdf-generator'
import pdfMake from 'pdfmake/build/pdfmake'
import { pdfMakeInvoiceDefinition } from './pdfmake-voxy-invoice-definitions'
import { AFRelationship, PDFDocument, PDFName, PDFString } from 'pdf-lib'
import { BusinessDocumentKind } from '$src/voxy-app/enums/business-document-kind'
import { TaxonomyTag } from '$src/voxy-app/enums/taxonomy-tag'
import { Customer } from '$src/crm-app/models/customer'
import { MailAddress } from '$src/crm-app/models/company'
import { Contact } from '$src/crm-app/models/contact'
import { ProfileStore } from '$src/core-app/stores/profile.store'
import { getVoxyInvoicePreferences } from '../business-document-api/business-document-repository.api'
import { generateLegalBoilerplateForAGivenLegalStructure } from '../generate-voxy-legal-information'
import { isClientCountryCodeAMemberOfEuropeanUnion } from '$src/core-app/services/countries-pure-functions'
import { currentProfileLanguage, t } from '$src/core-app/lib/i18n/i18nextWrapper'
import { isFeatureEnabled } from '$src/config/features.service'

/**
 * Test function to download the file directly when generating tests
 * @param blob
 * @param fileName
 */
function downloadBlob(blob: Blob, fileName: string): void {
  const url: string = window.URL.createObjectURL(blob)
  const a: HTMLAnchorElement = document.createElement('a')
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

/**
 * Fetch the customer company logo using the URL
 * saved in the account company
 * @param url
 */
export function fetchCustomerCompanyLogo(url: string): Promise<Blob> {
  return axios({
    url,
    method: 'GET',
    headers: { 'Access-Control-Allow-Origin': '*' },
    responseType: 'blob'
  }).then((response: AxiosResponse<any, any>) => response.data)
}

/**
 * Convert a blob to a base64 data URI
 * @param blob
 */
export function blobToBase64DataURI(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader: FileReader = new FileReader()
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result)
      } else {
        reject(new Error('Expected a string result from FileReader.'))
      }
    }
    reader.onerror = () => reject(new Error('An error occurred while reading the Blob.'))
    reader.readAsDataURL(blob)
  })
}

/**
 * Create PDF according to requirements for the invoice
 * uses PDFMake
 * @param accountCompanyLogoBase64
 * @param isPaidWhenFinalized
 * @param paymentURL
 * @param invoice
 * @param accountCompanyParsedFRSIREN
 * @param customerTVANumber
 * @param showPaymentLink
 */
export function createPDF(
  accountCompanyLogoBase64: string,
  isPaidWhenFinalized: boolean,
  paymentURL: string,
  invoice: BusinessDocument,
  accountCompanyParsedFRSIREN: string,
  customerTVANumber: string,
  showPaymentLink: boolean,
  backgroundImage: string = '',
  backgroundImageTransparency: number = 1,
  headerMargin: number = 0,
  headerTextColor: string = '',
  footerMargin: number = 0,
  footerTextColor: string = ''
): pdfMake.TCreatedPdf {
  pdfMake.vfs = pdfMakeFont

  return pdfMake.createPdf(
    pdfMakeInvoiceDefinition({
      isPaidWhenFinalized,
      paymentURL,
      businessDocument: invoice,
      accountCompanyLogoBase64,
      accountCompanyParsedFRSIREN,
      customerTVANumber,
      showPaymentLink,
      backgroundImage,
      backgroundImageTransparency,
      headerMargin,
      headerTextColor,
      footerMargin,
      footerTextColor
    }),
  )
}

/**
 * Uploads the file to the API
 * @param blob
 * @param invoice
 */
export function handleFileUpload(blob: Blob, invoice: BusinessDocument): Promise<any> {
  return apiUpload(`/workspace/${get(WorkspaceStore).workspaceId}/invoice/${window.btoa(invoice.businessDocumentNumber).replace(/=/g, '')}/file-attachments`, null, <APICallOptions>{
    entity: APIEntity.INVOICE_FILE,
    file: new File([blob], `${invoice.accountCompany.formalName}-${invoice.businessDocumentNumber}.pdf`, { type: blob.type }),
    isB64: true,
    invoiceDataId: invoice.businessDocumentId,
    invoiceNumber: invoice.businessDocumentNumber,
    invoiceFileName: invoice.businessDocumentNumber + '.pdf'
  })
}

/**
 * Test Function that allows to download the blob
 * @param blob
 * @param label
 * @param documentNumber
 */
function downloadPDFBlob(blob: Blob, label: string, documentNumber: string) {
  const fileName: string = `${label}-${documentNumber}.pdf`
  const url: string = window.URL.createObjectURL(blob)
  const a: HTMLAnchorElement = document.createElement('a')
  a.href = url
  a.download = fileName
  a.click()
  window.URL.revokeObjectURL(url)
}

const genFacturxXml = (data: string, invoice: BusinessDocument): string => {

  const getTypeCode = (kind: BusinessDocumentKind): number => {
    // source : https://www.billygen.fr/publications/comprendre-factur-x-part2/
    // https://unece.org/fileadmin/DAM/trade/untdid/d15b/tred/tred1001.htm
    switch (kind) {
      // CODES NON IMPLEMENTES CAR NON ACCEPTÉ POUR CHORUSPRO
      // 389 : Facture d’autofacturation (créée par l'acheteur pour le compte du fournisseur).
      // 261 : Avoir d’autofacturation.
      // 751 : Informations de facture pour comptabilisation
      //       code exigé en Allemagne pour satisfaire ses contraintes réglementaires.
      case BusinessDocumentKind.CREDITNOTE:
        // 381 : Avoir (note de crédit)
        return 381
      case BusinessDocumentKind.INVOICE: default:
        // 384 : Facture rectificative
        if (invoice.taxonomyTags.includes(TaxonomyTag.CORRECTIVE_INVOICE)) return 384
        // 386 : Facture d'acompte
        if (invoice.taxonomyTags.includes(TaxonomyTag.PARTIAL_BUSINESS_DOCUMENT)) return 386
        // 380 : Facture commerciale

        return 380
    }
  }

  const dico = {
    'InvoiceNumber': invoice.businessDocumentNumber,
    'InvoiceTypeCode': getTypeCode(invoice.businessDocumentKind),
    'InvoiceDateTime': invoice.modifiedDate.rfc3339.split('T')[0].replaceAll('-', ''),
    'SellerTradePartyName': invoice.accountCompany.formalName,
    'SellerTradePartyCountry': invoice.accountCompany.mailAddress.countryCode,
    'SellerTradePartyVAT': invoice.accountCompany.regulatory.euRegulScope?.euIntraVat ?? '',
    'BuyerTradePartyName': invoice.customerCustomer.company.formalName,
    'InvoiceCurrencyCode': invoice.currency,
    'TaxBasisTotalAmount': invoice.totalExcludingTaxScaledValue,
    'TaxTotalAmount': invoice.totalTaxScaledValue,
    'GrandTotalAmount': invoice.totalIncludingTaxScaledValue,
    'DuePayableAmount': invoice.installmentResultIncludingTaxScaledValue
  }

  Object.entries(dico).forEach(([key, val]) => {
    let rx = new RegExp('{' + key + '}', 'gmi')
    data = data.replaceAll(rx, val.toString())
  })

  return data
}

type MetaDataOptions = {
  title: string,
  author: string,
  producer: string,
  creatorTool: string,
  subject: string,
  keywords: string[],
  documentType: string,
  documentCreationDate: Date,
  documentModificationDate: Date,
  metadataModificationDate: Date,
}

const addMetadataToDoc = (pdfDoc: PDFDocument, options: MetaDataOptions) => {
  const formatDate = (date: Date) => date.toISOString().split('.')[0] + 'Z'
  const whitespacePadding = new Array(20).fill(' '.repeat(100)).join('\n')

  const metadataXML = `
    <?xpacket begin="" id="W5M0MpCehiHzreSzNTczkc9d"?>
      <x:xmpmeta xmlns:x="adobe:ns:meta/" x:xmptk="Adobe XMP Core 5.2-c001 63.139439, 2010/09/27-13:37:26">
        <rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">

          <rdf:Description rdf:about="" xmlns:dc="http://purl.org/dc/elements/1.1/">
            <dc:format>application/pdf</dc:format>
            <dc:creator>
              <rdf:Seq>
                <rdf:li>${options.author}</rdf:li>
              </rdf:Seq>
            </dc:creator>
            <dc:title>
                <rdf:Alt>
                  <rdf:li xml:lang="x-default">${options.title}</rdf:li>
                </rdf:Alt>
            </dc:title>
            <dc:subject>
              <rdf:Bag>
                ${(options.keywords as string[]).map((keyword: string) => `<rdf:li>${keyword}</rdf:li>`).join('\n')}
              </rdf:Bag>
            </dc:subject>
          </rdf:Description>

          <rdf:Description rdf:about="" xmlns:xmp="http://ns.adobe.com/xap/1.0/">
            <xmp:CreatorTool>${options.creatorTool}</xmp:CreatorTool>
            <xmp:CreateDate>${formatDate(options.documentCreationDate as Date)}</xmp:CreateDate>
            <xmp:ModifyDate>${formatDate(options.documentModificationDate as Date)}</xmp:ModifyDate>
            <xmp:MetadataDate>${formatDate(options.metadataModificationDate as Date)}</xmp:MetadataDate>
          </rdf:Description>

          <rdf:Description rdf:about="" xmlns:pdf="http://ns.adobe.com/pdf/1.3/">
            <pdf:Producer>${options.producer}</pdf:Producer>
          </rdf:Description>

          <rdf:Description rdf:about="" xmlns:pdfaid="http://www.aiim.org/pdfa/ns/id/">
            <pdfaid:part>3</pdfaid:part>
            <pdfaid:conformance>A</pdfaid:conformance>
          </rdf:Description>

          <rdf:Description rdf:about="" xmlns:fx="urn:factur-x:pdfa:CrossIndustryDocument:invoice:1p0#">
            <fx:ConformanceLevel>MINIMUM</fx:ConformanceLevel>
            <fx:DocumentFileName>factur-x.xml</fx:DocumentFileName>
            <fx:DocumentType>${options.documentType}</fx:DocumentType>
            <fx:Version>1.0</fx:Version>
          </rdf:Description>

          <rdf:Description rdf:about=""
            xmlns:pdfaExtension="http://www.aiim.org/pdfa/ns/extension/"
            xmlns:pdfaSchema="http://www.aiim.org/pdfa/ns/schema#"
            xmlns:pdfaProperty="http://www.aiim.org/pdfa/ns/property#">
            <pdfaExtension:schemas>
              <rdf:Bag>
                <rdf:li rdf:parseType="Resource">
                  <pdfaSchema:schema>Factur-X PDFA Extension Schema</pdfaSchema:schema>
                  <pdfaSchema:namespaceURI>urn:factur-x:pdfa:CrossIndustryDocument:invoice:1p0#</pdfaSchema:namespaceURI>
                  <pdfaSchema:prefix>fx</pdfaSchema:prefix>
                  <pdfaSchema:property>
                    <rdf:Seq>
                      <rdf:li rdf:parseType="Resource">
                        <pdfaProperty:name>DocumentFileName</pdfaProperty:name>
                        <pdfaProperty:valueType>Text</pdfaProperty:valueType>
                        <pdfaProperty:category>external</pdfaProperty:category>
                        <pdfaProperty:description>name of the embedded XML invoice file</pdfaProperty:description>
                      </rdf:li>
                      <rdf:li rdf:parseType="Resource">
                        <pdfaProperty:name>DocumentType</pdfaProperty:name>
                        <pdfaProperty:valueType>Text</pdfaProperty:valueType>
                        <pdfaProperty:category>external</pdfaProperty:category>
                        <pdfaProperty:description>INVOICE</pdfaProperty:description>
                      </rdf:li>
                      <rdf:li rdf:parseType="Resource">
                        <pdfaProperty:name>Version</pdfaProperty:name>
                        <pdfaProperty:valueType>Text</pdfaProperty:valueType>
                        <pdfaProperty:category>external</pdfaProperty:category>
                        <pdfaProperty:description>The actual version of the Factur-X XML schema
                        </pdfaProperty:description>
                      </rdf:li>
                      <rdf:li rdf:parseType="Resource">
                        <pdfaProperty:name>ConformanceLevel</pdfaProperty:name>
                        <pdfaProperty:valueType>Text</pdfaProperty:valueType>
                        <pdfaProperty:category>external</pdfaProperty:category>
                        <pdfaProperty:description>The conformance level of the embedded Factur-X data
                        </pdfaProperty:description>
                      </rdf:li>
                    </rdf:Seq>
                  </pdfaSchema:property>
                </rdf:li>
              </rdf:Bag>
            </pdfaExtension:schemas>
          </rdf:Description>

        </rdf:RDF>
      </x:xmpmeta>
      ${whitespacePadding}
    <?xpacket end="w"?>`

  const metadataStream = pdfDoc.context.stream(metadataXML, {
    Type: 'Metadata',
    Subtype: 'XML',
    Length: metadataXML.length
  })

  const metadataStreamRef = pdfDoc.context.register(metadataStream)
  pdfDoc.catalog.set(PDFName.of('Metadata'), metadataStreamRef)
}


type ColorProfileOptions = {
  url: string,
  info: string,
  identifier: string,
}

const defaultColorProfile: ColorProfileOptions = {
  url: '/icc-profiles/sRGB2014.icc',
  info: 'sRGB2014',
  identifier: 'custom'
}

const setColorProfile = async (doc: PDFDocument, options: ColorProfileOptions = defaultColorProfile) => {
  const profileUrl = options.url
  const buffer = await fetch(profileUrl).then(res => res.arrayBuffer())
  const profile: Uint8Array = new Uint8Array(buffer)

  const profileStream = doc.context.stream(profile, {
    Length: profile.length
  })
  const profileStreamRef = doc.context.register(profileStream)

  const outputIntent = doc.context.obj({
    Type: 'OutputIntent',
    S: 'GTS_PDFA1',
    OutputConditionIdentifier: PDFString.of(options.identifier),
    DestOutputProfile: profileStreamRef,
    Info: options.info ? PDFString.of(options.info) : PDFString.of(options.identifier)
  })
  const outputIntentRef = doc.context.register(outputIntent)

  doc.catalog.set(PDFName.of('OutputIntents'), doc.context.obj([outputIntentRef]))
}

async function generatePDFBlob(
  isPaidWhenFinalized: boolean,
  paymentURL: string,
  invoice: BusinessDocument,
  accountCompanyParsedFRSIREN: string,
  customerTVANumber: string,
  showPaymentLink: boolean,
  backgroundImage: string = '',
  backgroundImageTransparency: number = 1,
  headerMargin: number = 0,
  headerTextColor: string = '',
  footerMargin: number = 0,
  footerTextColor: string = '',
  insertCompanyLogo: boolean = false
): Promise<Blob> {
  let base64Logo: string = ''

  // get company logo
  if (insertCompanyLogo)
    if (invoice.accountCompany.emailLogoURL) {
      const blob = await fetchCustomerCompanyLogo(invoice.accountCompany.emailLogoURL)
      base64Logo = await blobToBase64DataURI(blob)
    }

  // get pdf preferences
  if (isFeatureEnabled('cspdf')) {
    if (!backgroundImage) {
      const { res, err } = await getVoxyInvoicePreferences()
      if (err[0]) return Promise.reject(err)
      backgroundImage = res.image
      backgroundImageTransparency = res.opacity
      headerMargin = res.headerMargin
      headerTextColor = res.headerTextColor
      footerMargin = res.footerMargin
      footerTextColor = res.footerTextColor
    }
  }

  if (backgroundImageTransparency > 1) backgroundImageTransparency = backgroundImageTransparency / 100

  // generate PDF
  const pdfDocument: pdfMake.TCreatedPdf = createPDF(
    base64Logo,
    isPaidWhenFinalized,
    paymentURL,
    invoice,
    accountCompanyParsedFRSIREN,
    customerTVANumber,
    showPaymentLink,
    backgroundImage,
    backgroundImageTransparency,
    headerMargin,
    headerTextColor,
    footerMargin,
    footerTextColor
  )

  return new Promise((resolve, reject) => {
    pdfDocument.getBlob(async (blob: Blob) => {
      try {
        // XML
        const xmlUrl = '/files/fx.xml'
        const xmlContent = await fetch(xmlUrl).then(res => res.text())

        const facturx = genFacturxXml(xmlContent, invoice)
        const xmlBuffer = new TextEncoder().encode(facturx)

        let pdfBuffer = await blob.arrayBuffer()
        const pdfDoc = await PDFDocument.load(new Uint8Array(pdfBuffer))

        await pdfDoc.attach(xmlBuffer, 'factur-x.xml', {
          mimeType: 'text/xml',
          description: invoice.businessDocumentNumber,
          creationDate: new Date(),
          modificationDate: new Date(),
          afRelationship: AFRelationship.Data // Minimum + Basix WL else: Alternative
        })

        // PDF/A-3
        const createDate: Date = new Date()
        const title: string = invoice.businessDocumentNumber
        const author: string = invoice.accountCompany.formalName
        const producer: string = 'Dundy'
        const creatorTool: string = 'Dundy (https://www.dundy.co)'
        const subject: string = invoice.businessDocumentKind
        const keywords: string[] = [subject, title]
        const documentType: string = invoice.businessDocumentKind.toUpperCase()

        addMetadataToDoc(pdfDoc, {
          title,
          author,
          subject,
          keywords,
          producer,
          creatorTool,
          documentType,
          documentCreationDate: createDate,
          documentModificationDate: createDate,
          metadataModificationDate: createDate
        })

        await setColorProfile(pdfDoc)

        // SAVE
        pdfBuffer = await pdfDoc.save()
        const finalBlob = new Blob([pdfBuffer], { type: 'application/octet-stream' })
        resolve(finalBlob)
      } catch (error) {
        reject(error)
      }
    })
  })
}

async function generatePDFAndUpload(
  isPaidWhenFinalized: boolean,
  paymentURL: string,
  invoice: BusinessDocument,
  accountCompanyParsedFRSIREN: string,
  customerTVANumber: string,
  showPaymentLink: boolean
): Promise<void> {
  try {
    const pdfBlob = await generatePDFBlob(
      isPaidWhenFinalized,
      paymentURL,
      invoice,
      accountCompanyParsedFRSIREN,
      customerTVANumber,
      showPaymentLink
    )

    try {
      await handleFileUpload(pdfBlob, invoice)
    } catch (error) {
      throw new Error(`Upload failed: ${error}`)
    }
  } catch (error) {
    throw new Error(`Error generating and uploading PDF: ${error}`)
  }
}


export async function generateMockedPDFBlob(backgroundImage: string, backgroundImageTransparency: number, insertCompanyLogo: boolean, headerMargin: number, headerTextColor: string, footerMargin: number, footerTextColor: string): Promise<Blob> {

  const invoice = new BusinessDocument()
  const workspace = get(WorkspaceStore)
  const profile = get(ProfileStore)
  const accountCompanyParsedFRSIREN = workspace?.company?.regulatory.frRegulScope?.siren ?? ''
  const customerTVANumber = workspace?.company?.regulatory?.euRegulScope?.euIntraVat ?? ''

  // MOCK
  invoice.businessDocumentNumber = `F-${new Date().getFullYear()}-${new Date().getMonth() + 1}-222`
  invoice.businessDocumentKind = BusinessDocumentKind.INVOICE
  invoice.accountCompany = workspace.company
  invoice.accountBankingInformation.bankAccountHolder = workspace.bankConfig.selectedAccountBankName
  invoice.accountBankingInformation.bankAccountBIC = workspace.bankConfig.selectedAccountBankBIC
  invoice.accountBankingInformation.bankAccountIBAN = workspace.bankConfig.selectedAccountBankIBAN
  invoice.accountBankingInformation.bankWireReference = invoice.businessDocumentNumber
  invoice.accountContact = Contact.empty()
  invoice.accountContact.email = profile.email
  invoice.customerCustomer = Customer.empty('xxxxxx', false)
  invoice.customerCustomer.company.formalName = 'ABCD'
  invoice.customerContact = {
    firstName: 'Jean',
    lastName: 'Dubois',
    email: 'jean@dubois.fr'
  } as Contact
  invoice.customerCustomer.company.mailAddress = {
    street: '2 rue du test',
    extraAddressLine: '',
    city: 'Paris',
    zipCode: '75001',
    country: 'France',
    countryCode: 'FR',
    state: ''
  } as MailAddress

  const isEuropean: boolean = isClientCountryCodeAMemberOfEuropeanUnion(invoice.accountCompany?.mailAddress?.countryCode, currentProfileLanguage())

  invoice.legalMentions = generateLegalBoilerplateForAGivenLegalStructure(
    workspace,
    invoice.accountCompany.regulatory.euRegulScope?.euIntraVat ?? '',
    invoice.accountCompany.regulatory.frRegulScope?.siren ?? '',
    invoice.currency,
    invoice.customerCustomer.isPrivateIndividual,
    isEuropean
  )

  invoice.paymentConditions = t('invoices.createInvoice.defaultFrenchPenaltyTerms') + t('invoices.createInvoice.defaultFrenchNoDiscountEarlyBirds')

  invoice.lineItems = [
    { 'businessDocumentLineItemId': '006c2280-395e-46d5-ae09-6a48e314a083', 'lineItemNumber': 1, 'title': 'Produit - matière première', 'description': '', 'supplierReference': '', 'customerReference': '', 'itemPrice': { 'unit': 'unit_unit', 'currency': 'EUR', 'scaledValue': 15 }, 'quantity': 3, 'taxRate': { 'taxRateId': 'f4c55549-314d-4525-8105-22e4123e0002', 'displayName': '0%', 'percentage': 0, 'description': '', 'jurisdiction': 'FR' }, 'taxScaledValue': 0, 'lineItemTotalIncludingTaxScaledValue': 45, 'lineItemTotalExcludingTaxScaledValue': 45 } as BusinessDocumentLineItem,
    { 'businessDocumentLineItemId': '8fb6f38c-eb8f-45c4-80a6-c15992380353', 'lineItemNumber': 2, 'title': 'Pretation - main d\'oeuvre', 'description': '', 'supplierReference': '', 'customerReference': '', 'itemPrice': { 'unit': 'unit_unit', 'currency': 'EUR', 'scaledValue': 250 }, 'quantity': 1, 'taxRate': { 'taxRateId': '23892b3c-c938-4847-a6fd-f85062dbfb20', 'displayName': '20%', 'percentage': 20, 'description': '', 'jurisdiction': 'FR' }, 'taxScaledValue': 50, 'lineItemTotalIncludingTaxScaledValue': 300, 'lineItemTotalExcludingTaxScaledValue': 250 } as BusinessDocumentLineItem
  ]

  invoice.subtotalExcludingTaxScaledValue = 295
  invoice.totalTaxScaledValue = 50
  invoice.totalIncludingTaxScaledValue = 345

  return generatePDFBlob(
    false,
    '',
    invoice,
    accountCompanyParsedFRSIREN,
    customerTVANumber,
    false,
    backgroundImage,
    backgroundImageTransparency,
    headerMargin,
    headerTextColor,
    footerMargin,
    footerTextColor,
    insertCompanyLogo
  )
}

/**
 * Master function Create PDF and handle file upload
 * @param isPaidWhenFinalized
 * @param paymentURL
 * @param invoice
 * @param accountCompanyParsedFRSIREN
 * @param customerTVANumber
 * @param showPaymentLink
 */
export function createPDFAndHandleFileUpload(
  isPaidWhenFinalized: boolean,
  paymentURL: string,
  invoice: BusinessDocument,
  accountCompanyParsedFRSIREN: string,
  customerTVANumber: string,
  showPaymentLink: boolean,
): void {
  generatePDFAndUpload(isPaidWhenFinalized, paymentURL, invoice, accountCompanyParsedFRSIREN, customerTVANumber, showPaymentLink)
}

