import { notificationStore } from '../stores/notification.store'

export type Notif = {
  message: string,
  link?: string
  expiration?: Date | null,
}

export type Notifs = Notif[]

/**
 * ADD NEW NOTIFICATION
 * @param {string} message 
 * @param {string} link 
 * @param {Date} expiration 
 */
export const addNotification = (message: string, link: string = '', expiration: Date | null = null) => {
  notificationStore.update(store => [...store, { expiration, message, link }])

  // TODO : if expiration, manage list of setTimeout (date OR number in sec ?)
}

// TODO : update with web socket for backend and collaboratives notifications