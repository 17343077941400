export default [
  { 'label': 'Afrikaans', 'value': 'af' },
  { 'label': 'Albanian', 'value': 'sq' },
  { 'label': 'Amharic', 'value': 'am' },
  { 'label': 'Arabic', 'value': 'ar' },
  { 'label': 'Aragonese', 'value': 'an' },
  { 'label': 'Armenian', 'value': 'hy' },
  { 'label': 'Asturian', 'value': 'ast' },
  { 'label': 'Azerbaijani', 'value': 'az' },
  { 'label': 'Basque', 'value': 'eu' },
  { 'label': 'Belarusian', 'value': 'be' },
  { 'label': 'Bengali', 'value': 'bn' },
  { 'label': 'Bosnian', 'value': 'bs' },
  { 'label': 'Breton', 'value': 'br' },
  { 'label': 'Bulgarian', 'value': 'bg' },
  { 'label': 'Catalan', 'value': 'ca' },
  { 'label': 'Central Kurdish', 'value': 'ckb' },
  { 'label': 'Chinese', 'value': 'zh' },
  { 'label': 'Chinese (Hong Kong)', 'value': 'zh-HK' },
  { 'label': 'Chinese (Simplified)', 'value': 'zh-CN' },
  { 'label': 'Chinese (Traditional)', 'value': 'zh-TW' },
  { 'label': 'Corsican', 'value': 'co' },
  { 'label': 'Croatian', 'value': 'hr' },
  { 'label': 'Czech', 'value': 'cs' },
  { 'label': 'Danish', 'value': 'da' },
  { 'label': 'Dutch', 'value': 'nl' },
  { 'label': 'English', 'value': 'en' },
  { 'label': 'English (Australia)', 'value': 'en-AU' },
  { 'label': 'English (Canada)', 'value': 'en-CA' },
  { 'label': 'English (India)', 'value': 'en-IN' },
  { 'label': 'English (New Zealand)', 'value': 'en-NZ' },
  { 'label': 'English (South Africa)', 'value': 'en-ZA' },
  { 'label': 'English (United Kingdom)', 'value': 'en-GB' },
  { 'label': 'English (United States)', 'value': 'en-US' },
  { 'label': 'Esperanto', 'value': 'eo' },
  { 'label': 'Estonian', 'value': 'et' },
  { 'label': 'Faroese', 'value': 'fo' },
  { 'label': 'Filipino', 'value': 'fil' },
  { 'label': 'Finnish', 'value': 'fi' },
  { 'label': 'French', 'value': 'fr' },
  { 'label': 'French (Canada)', 'value': 'fr-CA' },
  { 'label': 'French (France)', 'value': 'fr-FR' },
  { 'label': 'French (Switzerland)', 'value': 'fr-CH' },
  { 'label': 'Galician', 'value': 'gl' },
  { 'label': 'Georgian', 'value': 'ka' },
  { 'label': 'German', 'value': 'de' },
  { 'label': 'German (Austria)', 'value': 'de-AT' },
  { 'label': 'German (Germany)', 'value': 'de-DE' },
  { 'label': 'German (Liechtenstein)', 'value': 'de-LI' },
  { 'label': 'German (Switzerland)', 'value': 'de-CH' },
  { 'label': 'Greek', 'value': 'el' },
  { 'label': 'Guarani', 'value': 'gn' },
  { 'label': 'Gujarati', 'value': 'gu' },
  { 'label': 'Hausa', 'value': 'ha' },
  { 'label': 'Hawaiian', 'value': 'haw' },
  { 'label': 'Hebrew', 'value': 'he' },
  { 'label': 'Hindi', 'value': 'hi' },
  { 'label': 'Hungarian', 'value': 'hu' },
  { 'label': 'Icelandic', 'value': 'is' },
  { 'label': 'Indonesian', 'value': 'id' },
  { 'label': 'Interlingua', 'value': 'ia' },
  { 'label': 'Irish', 'value': 'ga' },
  { 'label': 'Italian', 'value': 'it' },
  { 'label': 'Italian (Italy)', 'value': 'it-IT' },
  { 'label': 'Italian (Switzerland)', 'value': 'it-CH' },
  { 'label': 'Japanese', 'value': 'ja' },
  { 'label': 'Kannada', 'value': 'kn' },
  { 'label': 'Kazakh', 'value': 'kk' },
  { 'label': 'Khmer', 'value': 'km' },
  { 'label': 'Korean', 'value': 'ko' },
  { 'label': 'Kurdish', 'value': 'ku' },
  { 'label': 'Kyrgyz', 'value': 'ky' },
  { 'label': 'Lao', 'value': 'lo' },
  { 'label': 'Latin', 'value': 'la' },
  { 'label': 'Latvian', 'value': 'lv' },
  { 'label': 'Lingala', 'value': 'ln' },
  { 'label': 'Lithuanian', 'value': 'lt' },
  { 'label': 'Macedonian', 'value': 'mk' },
  { 'label': 'Malay', 'value': 'ms' },
  { 'label': 'Malayalam', 'value': 'ml' },
  { 'label': 'Maltese', 'value': 'mt' },
  { 'label': 'Marathi', 'value': 'mr' },
  { 'label': 'Mongolian', 'value': 'mn' },
  { 'label': 'Nepali', 'value': 'ne' },
  { 'label': 'Norwegian', 'value': 'no' },
  { 'label': 'Norwegian Bokmål', 'value': 'nb' },
  { 'label': 'Norwegian Nynorsk', 'value': 'nn' },
  { 'label': 'Occitan', 'value': 'oc' },
  { 'label': 'Oriya', 'value': 'or' },
  { 'label': 'Oromo', 'value': 'om' },
  { 'label': 'Pashto', 'value': 'ps' },
  { 'label': 'Persian', 'value': 'fa' },
  { 'label': 'Polish', 'value': 'pl' },
  { 'label': 'Portuguese', 'value': 'pt' },
  { 'label': 'Portuguese (Brazil)', 'value': 'pt-BR' },
  { 'label': 'Portuguese (Portugal)', 'value': 'pt-PT' },
  { 'label': 'Punjabi', 'value': 'pa' },
  { 'label': 'Quechua', 'value': 'qu' },
  { 'label': 'Romanian', 'value': 'ro' },
  { 'label': 'Romanian (Moldova)', 'value': 'mo' },
  { 'label': 'Romansh', 'value': 'rm' },
  { 'label': 'Russian', 'value': 'ru' },
  { 'label': 'Scottish Gaelic', 'value': 'gd' },
  { 'label': 'Serbian', 'value': 'sr' },
  { 'label': 'Serbo', 'value': 'sh' },
  { 'label': 'Shona', 'value': 'sn' },
  { 'label': 'Sindhi', 'value': 'sd' },
  { 'label': 'Sinhala', 'value': 'si' },
  { 'label': 'Slovak', 'value': 'sk' },
  { 'label': 'Slovenian', 'value': 'sl' },
  { 'label': 'Somali', 'value': 'so' },
  { 'label': 'Southern Sotho', 'value': 'st' },
  { 'label': 'Spanish', 'value': 'es' },
  { 'label': 'Spanish (Argentina)', 'value': 'es-AR' },
  { 'label': 'Spanish (Latin America)', 'value': 'es-419' },
  { 'label': 'Spanish (Mexico)', 'value': 'es-MX' },
  { 'label': 'Spanish (Spain)', 'value': 'es-ES' },
  { 'label': 'Spanish (United States)', 'value': 'es-US' },
  { 'label': 'Sundanese', 'value': 'su' },
  { 'label': 'Swahili', 'value': 'sw' },
  { 'label': 'Swedish', 'value': 'sv' },
  { 'label': 'Tajik', 'value': 'tg' },
  { 'label': 'Tamil', 'value': 'ta' },
  { 'label': 'Tatar', 'value': 'tt' },
  { 'label': 'Telugu', 'value': 'te' },
  { 'label': 'Thai', 'value': 'th' },
  { 'label': 'Tigrinya', 'value': 'ti' },
  { 'label': 'Tongan', 'value': 'to' },
  { 'label': 'Turkish', 'value': 'tr' },
  { 'label': 'Turkmen', 'value': 'tk' },
  { 'label': 'Twi', 'value': 'tw' },
  { 'label': 'Ukrainian', 'value': 'uk' },
  { 'label': 'Urdu', 'value': 'ur' },
  { 'label': 'Uyghur', 'value': 'ug' },
  { 'label': 'Uzbek', 'value': 'uz' },
  { 'label': 'Vietnamese', 'value': 'vi' },
  { 'label': 'Walloon', 'value': 'wa' },
  { 'label': 'Welsh', 'value': 'cy' },
  { 'label': 'Western Frisian', 'value': 'fy' },
  { 'label': 'Xhosa', 'value': 'xh' },
  { 'label': 'Yiddish', 'value': 'yi' },
  { 'label': 'Yoruba', 'value': 'yo' },
  { 'label': 'Zulu', 'value': 'zu' }
]