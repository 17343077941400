import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
import { DunningMessageKind } from '../models/dunning-message-template'

export enum WorkflowTemplateType {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM'
}

export type WorkflowTemplate = { 
  key: WorkflowTemplateType,
  label: string,
  icon: string
}


export type WorkflowMedia = { 
  key: DunningMessageKind,
  label: string,
  icon: string
}

export enum WorkflowModeType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL'
}

export type WorkflowMode = { 
  key: WorkflowModeType,
  label: string,
  icon: string
}

export const WorkflowModes:WorkflowMode[] = [
  { 
    key: WorkflowModeType.MANUAL,
    label: t('workflow.manual'),
    icon: '/img/icons/finger-tap.svg' 
  },
  { 
    key: WorkflowModeType.AUTOMATIC,
    label: t('workflow.auto'),
    icon: '/img/icons/cog.svg' 
  }
]

export type WorkflowStep = {
  id: string,
  name: string,
  media: DunningMessageKind,
  mode: WorkflowModeType,
}

export type WorkflowSteps = WorkflowStep[]

export const workflowSteps:WorkflowSteps = [
  {
    id: 'STEP00_ISSUE_BUSINESS_DOCUMENT',
    name: t('workflow.issueDate'),
    media: DunningMessageKind.DunningEmail,
    mode: WorkflowModeType.MANUAL
  },
  // {
  //   id: 'STEP01_BETWEEN_ISSUANCE_AND_FIRST_REMINDER',
  //   name: t('workflow.issueDatePlus7'),
  //   media: WorkflowMediaType.MAIL,
  //   mode: WorkflowModeType.MANUAL,
  // },
  {
    id: 'STEP02_FIRST_REMINDER_BEFORE_DUE_DATE',
    name: t('workflow.dueDateLess6'),
    media: DunningMessageKind.DunningEmail,
    mode: WorkflowModeType.MANUAL
  },
  {
    id: 'STEP03_FIRST_REMINDER_AFTER_DUE_DATE',
    name: t('workflow.dueDate'),
    media: DunningMessageKind.DunningEmail,
    mode: WorkflowModeType.MANUAL
  },
  {
    id: 'STEP04_SECOND_REMINDER_AFTER_DUE_DATE',
    name: t('workflow.dueDatePlus2'),
    media: DunningMessageKind.DunningEmail,
    mode: WorkflowModeType.MANUAL
  },
  {
    id: 'STEP05_THIRD_FIRM_REMINDER_AFTER_DUE_DATE',
    name: t('workflow.dueDatePlus6'),
    media: DunningMessageKind.DunningEmail,
    mode: WorkflowModeType.MANUAL
  },
  {
    id: 'STEP06_FOURTH_REMINDER_BY_PHONE_AFTER_DUE_DATE',
    name: t('workflow.dueDatePlus15'),
    media: DunningMessageKind.DunningPhoneCall,
    mode: WorkflowModeType.MANUAL
  },
  {
    id: 'STEP07_FIFTH_REMINDER_LAST_CALL_BEFORE_LEGAL_ACTIONS',
    name: t('workflow.dueDatePlus20'),
    media: DunningMessageKind.DunningPostalLetter,
    mode: WorkflowModeType.MANUAL
  },
  {
    id: 'STEP08_LEGAL_ACTIONS',
    name: t('workflow.dueDatePlus35'),
    media: DunningMessageKind.DunningLegalAction,
    mode: WorkflowModeType.MANUAL
  }
]