<script lang="ts">
  import { notificationStore } from '$core/stores/notification.store'
  import type { Notif } from '$src/core-app/services/notification.service'
  import { navigate } from 'svelte-routing'

  let listRef: HTMLTableElement
  let displayList:boolean = false
  let newNotif:boolean = false

  notificationStore.subscribe((store) => {
    if (store.length === 0) return
  
    newNotif = true
    setTimeout(() => newNotif = false, 3000)
  })

  const onClickOutside = (e: MouseEvent) => {
    if (listRef && !listRef.contains(e.target as Node)) {

      document.removeEventListener('click', onClickOutside)
      displayList = false
    }
  }

  const onToggleList = () => {
    displayList = !displayList

    if (displayList) {
      setTimeout(() => document.addEventListener('click', onClickOutside))
    } else {
      document.removeEventListener('click', onClickOutside)
    }
  }

  const onNavigate = (notification:Notif)=> {
    if (notification.link) navigate(notification.link)
  }

  const onDelete = (notification:Notif)=> {
    notificationStore.update(store => store.filter(n => n.message !== notification.message && n.expiration !== notification.expiration))

    if ($notificationStore.length === 0) displayList = false
  }
</script>

<div class="relative">
  <button
    class="flex items-center justify-center text-dundyOrange"
    class:disabled={($notificationStore?.length ?? 0) === 0}
    on:click={onToggleList}
  >
    <div class="ico toggle" class:bounce={newNotif} />
    {$notificationStore.length}
  </button>

  {#if displayList}
    <table bind:this={listRef} class="bg-white border rounded absolute top-9 text-s shadow-md">
      {#each $notificationStore as notification}
        <tr class="rounded even:bg-whisper hover:bg-gray-200">
          <td class="pl-4 pr-2 whitespace-nowrap cursor-default">{notification.message}</td>
          <td><button class="ico {notification.link ? 'link' : 'empty'}" on:click={() => onNavigate(notification)}/></td>
          <td><button class="ico delete" on:click={() => onDelete(notification)}/></td>
        </tr>
      {/each}
    </table>
  {/if}
</div>

<style lang="postcss">
  .disabled {
    @apply pointer-events-none cursor-default text-gray-400;

    .toggle {
      @apply bg-gray-400;
    }
  }

  .ico {
    @apply size-7 mt-1 text-center bg-black;
  }

  .toggle {
    mask: url("/img/icons/bell.svg") no-repeat;
    @apply  bg-dundyOrange;
  }

  .link {
    mask: url("/img/icons/link.svg") no-repeat;

    &:hover {
      @apply bg-green-500;
    }
  }

  .empty {
    @apply bg-transparent cursor-default;
  }

  .delete {
    mask: url("/img/icons/delete.svg") no-repeat;

    &:hover {
      @apply bg-red-500;
    }
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }

  .bounce {
    animation: bounce 1s ease-in-out infinite;
  }
</style>