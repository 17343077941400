import { derived, get, writable } from 'svelte/store'
import type { BusinessDocument, BusinessDocumentAllDataPersisted } from '../models/business-document'
import { BusinessDocumentsAllDataPersistedStore, SimpleDocumentsStore } from './business-documents.store'
import { invoicesCollateralCalculusAndFilterUi } from '../services/ui-services/invoices/invoices-collateral-calculus-and-filter.ui'
import { BusinessDocumentViewListOption } from '../enums/business-documents-view-list-options'
import { extractBusinessDocumentsFromCoreDocumentsList } from '../services/business-document-various-functions'

export let searchValueStore = writable('')
export let listViewChoiceStore = writable(BusinessDocumentViewListOption.INVOICES)

/**
 * INVOICES
 */
export const InvoiceStore = derived(BusinessDocumentsAllDataPersistedStore, ($store: BusinessDocumentAllDataPersisted[]) => {
  if (!$store) return []

  const store = extractBusinessDocumentsFromCoreDocumentsList($store)

  return invoicesCollateralCalculusAndFilterUi.filterInvoicesFromStore(
    store,
    BusinessDocumentViewListOption.INVOICES,
    '',
  )
})

/**
 * INVOICES WITH SEARCH
 */
export const InvoiceSearchStore = derived(InvoiceStore, ($store: BusinessDocument[]) => {
  if (!$store) return []

  return invoicesCollateralCalculusAndFilterUi.filterInvoicesFromStore(
    $store,
    BusinessDocumentViewListOption.INVOICES,
    get(searchValueStore),
  )
})

/**
 * CREDIT NOTES
 */
export const CreditNoteStore = derived(BusinessDocumentsAllDataPersistedStore, ($store: BusinessDocumentAllDataPersisted[]) => {
  if (!$store) return []

  const store = extractBusinessDocumentsFromCoreDocumentsList($store)

  return invoicesCollateralCalculusAndFilterUi.filterInvoicesFromStore(
    store,
    BusinessDocumentViewListOption.CREDIT_NOTES_AND_VOIDED_INVOICES,
    '',
  )
})

/**
 * CREDIT NOTES WITH SEARCH
 */
export const CreditNoteSearchStore = derived(CreditNoteStore, ($store: BusinessDocument[]) => {
  if (!$store) return []

  return invoicesCollateralCalculusAndFilterUi.filterInvoicesFromStore(
    $store,
    BusinessDocumentViewListOption.CREDIT_NOTES_AND_VOIDED_INVOICES,
    get(searchValueStore),
  )
})

/**
 * VOIDED
 */
export const VoidedStore = derived(BusinessDocumentsAllDataPersistedStore, ($store: BusinessDocumentAllDataPersisted[]) => {
  if (!$store) return []

  const store = extractBusinessDocumentsFromCoreDocumentsList($store)

  return invoicesCollateralCalculusAndFilterUi.filterInvoicesFromStore(
    store,
    BusinessDocumentViewListOption.VOIDED,
    '',
  )
})

/**
 * VOIDED WITH SEARCH
 */
export const VoidedSearchStore = derived(VoidedStore, ($store: BusinessDocument[]) => {
  if (!$store) return []

  return invoicesCollateralCalculusAndFilterUi.filterInvoicesFromStore(
    $store,
    BusinessDocumentViewListOption.VOIDED,
    get(searchValueStore),
  )
})

/**
 * DRAFT
 */
export const DraftStore = derived(BusinessDocumentsAllDataPersistedStore, ($store: BusinessDocumentAllDataPersisted[]) => {
  if (!$store) return []

  const store = extractBusinessDocumentsFromCoreDocumentsList($store)

  return invoicesCollateralCalculusAndFilterUi.filterInvoicesFromStore(
    store,
    BusinessDocumentViewListOption.DRAFT,
    '',
  )
})

/**
 * DRAFT WITH SEARCH
 */
export const DraftSearchStore = derived(DraftStore, ($store: BusinessDocument[]) => {
  if (!$store) return []

  return invoicesCollateralCalculusAndFilterUi.filterInvoicesFromStore(
    $store,
    BusinessDocumentViewListOption.DRAFT,
    get(searchValueStore),
  )
})

/**
 * STORES COUNTS
 */
export const InvoicesCountsStore = derived([searchValueStore, SimpleDocumentsStore], () => {
  const searchValue = get(searchValueStore)

  // initials stores
  if (searchValueStore)
    return invoicesCollateralCalculusAndFilterUi.updateCollateralCalculus(get(SimpleDocumentsStore), searchValue)

  // search in stores
  const stores = [
    ...get(InvoiceSearchStore),
    ...get(CreditNoteSearchStore),
    ...get(VoidedSearchStore),
    ...get(DraftSearchStore)
  ]

  return invoicesCollateralCalculusAndFilterUi.updateCollateralCalculus(stores, searchValue)
})

/**
 * DISPLAY CURRENT STORE
 */
export const InvoicesStore = derived(
  [searchValueStore, listViewChoiceStore, SimpleDocumentsStore],
  () => {
    const searchValue = get(searchValueStore)
    const tab = get(listViewChoiceStore)

    switch (tab) {
      default: case BusinessDocumentViewListOption.INVOICES:
        return searchValue ? get(InvoiceSearchStore) : get(InvoiceStore)
      case BusinessDocumentViewListOption.CREDIT_NOTES_AND_VOIDED_INVOICES:
        return searchValue ? get(CreditNoteSearchStore) : get(CreditNoteStore)
      case BusinessDocumentViewListOption.VOIDED:
        return searchValue ? get(VoidedSearchStore) : get(VoidedStore)
      case BusinessDocumentViewListOption.DRAFT:
        return searchValue ? get(DraftSearchStore) : get(DraftStore)
    }
  })
