import { APICallOptions, APIEntity, apiGet, apiPost, apiPut } from '../../core-app/services/api.service'
import { get } from 'svelte/store'
import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
import { eventsManager } from '../../core-app/events/event-manager'
import { EventType } from '../../core-app/events/event-type'
import type { DundyEvent } from '../events/dundy-event'
import type { TodoListItem } from '../models/todo-list-item'
import type Invoice from '../models/invoice'
import { TodosStore } from '../stores/todos.store'
import { getUserCompanyTimezone } from '../../core-app/util/timezone-utils'
import type { Customer } from '$src/crm-app/models/customer'
import { v4 as uuidv4 } from 'uuid'
import { authZJsUserId } from '$core/lib/auth0authentication/authStore';
import { ExchangeDate } from '$core/models/exchange-date';

class TodosServiceClass {

  initialized: boolean
  initialize(): void {
    if (!this.initialized) {

      this.initialized = true

      eventsManager.on(EventType.TODO_MARKED_DONE, (event: DundyEvent<TodoListItem>): void => {
        if (event.data) {
          this.markAsDone(event.data)
            .then(r => {
              /* console.log('Todo marked as done', r) */
            })
        }
      }, 'todosService')

      // when a new TO DO list has just been loaded from the back-end
      eventsManager.on<TodoListItem[]>(EventType.TODO_LIST_FETCHED, (e: DundyEvent<TodoListItem[]>): void => {
        TodosStore.set(e.data)
      }, 'todosService')

      // when e.g. we have just refreshed the invoice list from the back-end (e.g. after a new Voxy invoice)
      eventsManager.on<Invoice[]>(EventType.INVOICE_LIST_FETCHED, () => {
        setTimeout(() => {
          this.fetch()
        }, 0)
      }, 'todosService')

    }
  }
  /**
     * Upper Function to fetch the TO DO list for a given workspace
     * calls the core fetch method
     * @param workspaceId
     */
  fetchTodosListForWorkspace(workspaceId: string): Promise<TodoListItem[]> {
    return this.fetchList(workspaceId)
  }
  /**
     * Core fetch method for a given workspace
     * @param workspaceId
     * @private
     */
  private fetchList(workspaceId?: string): Promise<TodoListItem[]> {
    const timeZone: string = getUserCompanyTimezone(get(WorkspaceStore))
    const timeZoneURLEncoded: string = encodeURIComponent(timeZone)
    return apiGet<TodoListItem[]>(`/workspace/${workspaceId || get(WorkspaceStore).workspaceId}/to-do-list?timeZoneIANACode=${timeZoneURLEncoded}`, <APICallOptions>{
      entity: APIEntity.DUNNING_TO_DO
    })
  }
  /**
     * Fetch the TO DO list
     * @private
     */
  private fetch(): void {
    this.fetchList()
      .then((response: TodoListItem[]): void => {
        eventsManager.emit(EventType.TODO_LIST_FETCHED, response,
          'todosService')
      })
      .catch(err => console.warn(err))
  }
  /**
     * Mark a TO DO as done
     * @param todo
     * @private
     */
  private markAsDone(todo: TodoListItem): Promise<any> {
    const now: Date = new Date()
    const body = {
      domainEventInterface: {
        eventId: uuidv4(),
        userId: get(authZJsUserId),
        workspaceId: todo.workspaceId,
        customerId: todo.customerId,
        domainName: 'Dunning',
        eventKind: 'TaskDone',
        eventImplementation: 'TaskDoneModelVM1m3',
        doneToDoItem: todo,
        eventDate: ExchangeDate.newDate(now),
        eventIANACode: get(WorkspaceStore).company.timeZoneIANACode,
        createdDate: ExchangeDate.newDate(now)
      }
    }

    apiPut(`/workspace/${get(WorkspaceStore).workspaceId}/event/task-done`,
      body,
      <APICallOptions>{
        entity: APIEntity.DUNNING_TO_DO
      })
      .catch((reason: any) => {
        console.error(reason)
      })

    return apiPut(`/workspace/${get(WorkspaceStore).workspaceId}/event/task-done`,
      body,
      <APICallOptions>{
        entity: APIEntity.TOTUM_LIFECYCLE
      })
  }
}

export const todosService: TodosServiceClass = new TodosServiceClass()


/**
 * Mark the task as done
 * Called when the user clicks on the "Mark as Done" button
 * @param {TodoListItem} todo
 * @param {Customer} customer
 */
export const markTaskDone = (todo:TodoListItem, customer:Customer) => {
  const now: Date = new Date()
  const nowUtcTime: number = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds())
  const nowUTC: Date = new Date(nowUtcTime)

  todo.dateDone = Math.round(nowUtcTime / 1000)
  todo.dateDoneRFC3339 = nowUTC.toISOString()
  todo.dateDoneIANACode = Intl.DateTimeFormat().resolvedOptions().timeZone
  todo.isDone = true

  const todoStoreValue: TodoListItem[] = get(TodosStore)
  const todoFromStoreIndex = todoStoreValue.findIndex(storedTodo => storedTodo.actionKind === todo.actionKind && storedTodo.customerId === customer.company.companyId && JSON.stringify(storedTodo.scopeInvoiceNumbers) === JSON.stringify(todo.scopeInvoiceNumbers))
  todoStoreValue[todoFromStoreIndex] = todo
  TodosStore.set(todoStoreValue)

  // EVENT
  eventsManager.emit(EventType.TODO_MARKED_DONE, todo, 'ReceivableAction.svelte')
}