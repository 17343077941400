import { APICallOptions, APIEntity, apiGet, apiPost } from '../../core-app/services/api.service'
import type Invoice from '../models/invoice'
import type ComputedDataInvoice from '../models/computed-data-invoice'
import { get } from 'svelte/store'
import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
import { authZJsUserId } from '../../core-app/lib/auth0authentication/authStore'
import { eventsManager } from '../../core-app/events/event-manager'
import { EventType } from '../../core-app/events/event-type'
import type { DundyEvent } from '../events/dundy-event'
import { DoNotPersistInvoicesStoreChangeOnce, DunningInvoicesStore } from '../stores/dunning-invoices.store'
import { ComputedDataInvoicesStore } from '../stores/computed-data-invoice.store'
import { feedbackService } from '../../core-app/services/feedback.service'
import { FeedbackEntity } from '../../core-app/enums/feedback-enums'
import { FeedbackHelper } from '../../core-app/helpers/feedback-helper'
import { getUserCompanyTimezone } from '../../core-app/util/timezone-utils'
import {
  updateDraftVoxyBusinessDocumentsWhenBankInformationChanges,
  updateDraftVoxyBusinessDocumentsWhenWorkspaceCompanyDataChanges
} from '../../voxy-app/services/update-voxy-draft-documents'
import type FeedbacksOnInvoices from '../models/feedbacks-on-invoices'
import type InvoicesApiResponse from '../models/invoices-api-response'
import type SavedInvoicesResponse from '../models/saved-invoices-api-response'
import type StorageBucketObject from '../../file-mgt-domain/models/storage-bucket-object'
import type { Workspace } from '../../crm-app/models/workspace'


class InvoicesServiceClass {

  initialized
  fetchAfterSave
  initialize(): void {

    if (!this.initialized) {
      this.initialized = true

      /* console.log('hunt invoices fetch 0 fetch invoices when init invoices') */
      this.fetch()

      eventsManager.on(EventType.INVOICE_MARKED_COMPLETED, (): void => {
        this.fetchAfterSave = true
      }, 'dunningInvoicesService')

      eventsManager.on(EventType.INVOICE_UPLOADED, (): void => {
        /* console.log('hunt invoices fetch 2 fetch invoices on INVOICE_UPLOADED') */
        this.fetch()
      }, 'dunningInvoicesService')

      eventsManager.on(EventType.REQUEST_INVOICE_LIST_AND_COMPUTED_INVOICE_LIST_TO_REFRESH, (): void => {
        this.fetch()
      }, 'dunningInvoicesService')

      eventsManager.on<ComputedDataInvoice[]>(EventType.COMPUTED_INVOICE_LIST_FETCHED, (e: DundyEvent<ComputedDataInvoice[]>): void => {
        /* console.log('+-+-+-==-- subscribing to COMPUTED_INVOICE_LIST_FETCHED to set ComputedDataInvoicesStore') */
        ComputedDataInvoicesStore.set(e.data)
      }, 'dunningInvoicesService (bis)')


      eventsManager.on<Workspace>(EventType.WORKSPACE_BANK_ACCOUNT_CHANGED, (e: DundyEvent<Workspace>): void => {
        updateDraftVoxyBusinessDocumentsWhenBankInformationChanges(e.data.bankConfig)
      }, 'VoxyInvoiceAddModal.svelte')

      eventsManager.on<Workspace>(EventType.WORKSPACE_COMPANY_CHANGED, (e: DundyEvent<Workspace>): void => {
        updateDraftVoxyBusinessDocumentsWhenWorkspaceCompanyDataChanges(e.data)
      }, 'VoxyInvoiceAddModal.svelte')

      eventsManager.on(EventType.INVOICE_LIST_CHANGED, (event: DundyEvent<Array<Invoice>>): void => {
        /* console.log('+++ INVOICE_LIST_CHANGED in invoices.services.ts event.data', event.data) */
        if (event.data) {
          this.save(event.data)
        } else {
          /* console.log('+++---- event.data NULL INVOICE_LIST_CHANGED in invoices.services.ts') */
        }
      }, 'dunningInvoicesService')
    }
  }
  /**
     * Fetches the list of invoices from the API
     * @param workspaceId
     */
  fetchInvoicesForWorkspace(workspaceId): Promise<InvoicesApiResponse> {
    return this.fetchInvoicesList(workspaceId)
  }

  /**
     * setInvoicesStoreToFetchedInvoiceListOnlyWheneverMostUpToDate
     * updates properly the invoices stores with eventually consistent system compatibility
     * otherwise we could receive an updated list from the back-end while we have made some changes locally meanwhile
     * this function takes each most up-to-date item
     * the ownership of the modified field belongs to:
     * - dundy front-end for Dundy "purely" invoices (made with AgGrid or the popup in Dundy)
     * - Voxy for Voxy invoices (and its integration microservice, but it is closely related)
     * - the integration microservice of any other source
     */

  setInvoicesStoreToFetchedInvoiceListOnlyWheneverMostUpToDate(fetchedInvoiceList: Invoice[]): void {
    const currentInvoiceList: Invoice[] = get(DunningInvoicesStore)
    let nextInvoiceListUpdate: Invoice[]
    nextInvoiceListUpdate = currentInvoiceList
    // filter out soft deleted items (items missing in the fetched list from back-end that are present in the local list but marked as deleted)
      .filter((currentInvoice: Invoice) => {
        const foundFetchedInvoiceVersion: Invoice = fetchedInvoiceList.find((fetchedInvoice: Invoice): boolean => (fetchedInvoice.dataId === currentInvoice.dataId))

        return !(!foundFetchedInvoiceVersion && currentInvoice.deleted)
      })
    // filter out (ignore) items that we currently have and are missing in the fetched list from the back-end
      .filter((currentInvoice: Invoice) => {
        const foundFetchedInvoiceVersion: Invoice = fetchedInvoiceList.find((fetchedInvoice: Invoice): boolean => (fetchedInvoice.dataId === currentInvoice.dataId))

        return !!foundFetchedInvoiceVersion
      })
      .map<Invoice>((currentInvoice: Invoice): undefined | Invoice => {
      const foundFetchedInvoiceVersion: Invoice = fetchedInvoiceList.find((fetchedInvoice: Invoice): boolean => (fetchedInvoice.dataId === currentInvoice.dataId))
        if (!foundFetchedInvoiceVersion) {
        return;
      } else {
        if (foundFetchedInvoiceVersion.modified >= currentInvoice.modified) {
          return foundFetchedInvoiceVersion
          } else {
          return currentInvoice
          }
      }
    })
    const newInvoicesFromFetched: Invoice[] = fetchedInvoiceList.filter((fetchedInvoice: Invoice): boolean => {
      const foundExistingInvoiceVersion: Invoice = currentInvoiceList.find((currentInvoice: Invoice): boolean => (currentInvoice.dataId === fetchedInvoice.dataId))
      if (foundExistingInvoiceVersion) {
        return false
      } else {
        return true
      }
    })
    nextInvoiceListUpdate = [...nextInvoiceListUpdate, ...newInvoicesFromFetched]
    DunningInvoicesStore.set(nextInvoiceListUpdate) // will trigger a post to the API in the store (see stores/dunning-invoices.store.ts DunningInvoicesStore.subscribe in function initializeInvoicesStore)
  }
  /**
     * Fetches the list of invoices for the current workspace
     * @param workspaceId
     * @private
     */
  private fetchInvoicesList(workspaceId?:string): Promise<InvoicesApiResponse> {
    const timeZone: string = getUserCompanyTimezone(get(WorkspaceStore))
    const timeZoneURLEncoded: string = encodeURIComponent(timeZone)
    return apiGet<InvoicesApiResponse>(`/workspace/${workspaceId || get(WorkspaceStore).workspaceId}/invoices?timeZoneIANACode=${timeZoneURLEncoded}`, <APICallOptions>{
      entity: APIEntity.DUNNING_EXCERPTS,
      ignoreFeedback: true
    })
  }
  /**
     * Triggers the fetch invoices event
     * @private
     */
  private fetch(): void {

    this.fetchInvoicesList()
      .then((d: InvoicesApiResponse) => {
        /* console.log('+++ about to triggerFetchEvents') */
        this.triggerFetchEvents(d)
      })
      .catch(error => {
        if (error.status === 404) {
          /* console.log('+++ status 404') */
          this.triggerFetchEvents(<InvoicesApiResponse>{
            invoices: [],
            computedDataInvoices: [],
            feedbacksOnInvoicesList: <FeedbacksOnInvoices>{
              feedbackOnInvoiceList: [],
              numberOfInvalidInvoices: 0
            }
          })
        } else {
          /* console.log('+++ error when fetchInvoicesList() in dunning-invoices.service.ts', error) */
        }
      })
  }
  /**
     * Triggers the fetch invoices event
     * @param response
     * @private
     */
  private triggerFetchEvents(response: InvoicesApiResponse): void {
    /* console.log('+++ triggerFetchEvents executing and emitting INVOICE_LIST_FETCHED + COMPUTED_INVOICE_LIST_FETCHED') */
    /* console.log('important new invoices downloaded from back-end with ' + (response.invoices.length) + ' items') */
    eventsManager.emit(EventType.INVOICE_LIST_FETCHED, response.invoices || [],
      'dunningInvoicesService')
    eventsManager.emit(EventType.COMPUTED_INVOICE_LIST_FETCHED, response.computedDataInvoices || [],
      'dunningInvoicesService')
    const feedback = FeedbackHelper.invoiceFeedbackToFeedback(response.feedbacksOnInvoicesList)
    feedbackService.populateFeedback(FeedbackEntity.INVOICES, feedback)
  }
  /**
     * Saves the list of invoices
     * @param invoices
     * @private
     */
  private save(invoices: Invoice[]): Promise<void> {
    return apiPost<SavedInvoicesResponse>(`/user/${get(authZJsUserId)}/workspace/${get(WorkspaceStore).workspaceId}/invoices`,
      { invoices }, <APICallOptions>{
        entity: APIEntity.DUNNING_EXCERPTS,
        overrideContentType: 'application/json'
      })
      .then((response: SavedInvoicesResponse): void => {
        /* console.log('+++--++--+ response received from POST') */
        if (this.fetchAfterSave) {
          /* console.log('+++--++--+ this.fet chAfterSave true :-)') */
          // fetchAfterSave is needed because :
          // - we have an optimistic approach when an invoice is draft (when we make a change locally, we assume the exact same change is done in the backend)
          // - however when we finalize an invoice we cannot assume f-e and b-e are sync'd: hence the need to reload the invoices after a finalization (ideally we would need to reload just the finalized invoice)
          // - after an invoice finalization, there is no more invoice change, just collateral events (like paid or something)
          this.fetchAfterSave = false

          eventsManager.once<Invoice[]>(EventType.INVOICE_LIST_FETCHED, (e: DundyEvent<Invoice[]>): void => {
            DoNotPersistInvoicesStoreChangeOnce.set(false)
            dunningInvoicesService.setInvoicesStoreToFetchedInvoiceListOnlyWheneverMostUpToDate(e.data)
          }, 'dunningInvoicesService')

          eventsManager.once<ComputedDataInvoice[]>(EventType.COMPUTED_INVOICE_LIST_FETCHED, (e: DundyEvent<ComputedDataInvoice[]>): void => {
            ComputedDataInvoicesStore.set(e.data)
          }, 'dunningInvoicesService')
          /* console.log('hunt invoices fetch 4 fetch invoices after save invoices') */
          this.fetch()
        } else {
          /* console.log('+++--++--+ this.fetchAfterSave FALSE :-(') */
          feedbackService.populateFeedback(FeedbackEntity.INVOICES, FeedbackHelper.invoiceFeedbackToFeedback(response.feedbacksOnInvoicesList))
        }
      })
  }
}

export const dunningInvoicesService: InvoicesServiceClass = new InvoicesServiceClass()
