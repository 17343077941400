<script lang="ts">
  import { featureToggleStore } from './feature-toggle.store'
  import { type Unsubscriber } from 'svelte/store'
  import { onDestroy, onMount } from 'svelte'
  import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
  import { FeatureDisplayMode, type Feature } from './features.service'
  export let featureKey: string
  export let isComponent: boolean = true
  export let height: string = 'auto'
  export let width: string = 'auto'
  export let overflow: boolean = false

  export let expirationRule:((() => boolean) | null) = null

  const SLOTS = $$props.$$slots
  
  let featuresStoreUnsubscribe:Unsubscriber
  let feature: Feature | undefined

  let style:string = ''
  let classes:string = 'hidden'

  const getStyle = () => {
    let s = []
    if (height) s.push(`height : ${height}`)
    if (width) s.push(`width : ${width}`)
    
    style = s.join(';')
  }

  const getClass = () => {
    let isExpired:boolean = false
    if (expirationRule) isExpired = expirationRule()

    if (!isComponent) {
      classes = 'feature-toggle'
      
      return
    }

    if (isExpired || feature?.displayMode === FeatureDisplayMode.HIDE) {
      classes = 'hidden'
    } else if (feature?.displayMode === FeatureDisplayMode.COMPONENT) {
      classes = 'feature-toggle'
    } else {
      classes = 'feature-toggle--empty'
    }

    classes += overflow ? ' overflow-auto' : ' overflow-hidden'
  }

  onMount(() => {
    featuresStoreUnsubscribe = featureToggleStore.subscribe((store) => {
      if (store) feature = store[featureKey]
      getClass()
    })

    getStyle()
  })

  onDestroy(() => {
    if (featuresStoreUnsubscribe) featuresStoreUnsubscribe()
  })
</script>

<div class="relative {classes}" {style}>
  {#if feature}
    {#if feature.componentMessage && isComponent}
      <div class="feature-toggle--message">
        {t(feature.componentMessage)}
      </div>
    {/if}

    {#if !isComponent}
      {#if feature.displayMode !== FeatureDisplayMode.HIDE}
        <slot/>
      {/if}
    {:else}
      {#if feature.displayMode === FeatureDisplayMode.WALL}
        {#if SLOTS.wall}
          <slot name="wall" />
        {:else}
          <div class="feature-toggle--message">
            {t('featureToggle.expired', { feature: featureKey })}
          </div>
        {/if}
      {:else}
        <slot/>
      {/if}
    {/if}
  {/if}
</div>

<style lang="postcss">
  .feature-toggle {

    &--empty {
      @apply bg-gray-200 rounded-sm flex flex-col items-center justify-center;
    }
    &--message {
      @apply bg-dundyOrange text-white text-center text-sm my-3 py-1 px-3 rounded
    }
  }
</style>