import {
  BusinessDocument,
  BusinessDocumentAllDataPersisted,
  BusinessDocumentCollateralData,
  SavedBusinessDocumentResponse
} from '../../models/business-document'
import { APICallOptions, apiDelete, APIEntity, apiGet, apiPut, apiUploadAny, APIUploadCallOptions } from '../../../core-app/services/api.service'
import { get } from 'svelte/store'
import { WorkspaceStore } from '../../../crm-app/stores/workspace.store'
import { eventsManager } from '../../../core-app/events/event-manager'
import { EventType } from '../../../core-app/events/event-type'
import { feedbackService } from '../../../core-app/services/feedback.service'
import { Feedback } from '../../../core-app/models/feedback'
import { ApiError } from '../../../core-app/models/api-error'
import { cryptoRandomString } from '$src/core-app/util/encryption'
import type ApiUploadArgs from '$src/crm-app/models/api-upload-args'
import { getPreferenceByKind, savePreference } from '$src/shared/services/preference.service'

/**
 * Get the business document with its collateral data (UI Info) from the server
 * Check usage with other implementation: getBusinessDocumentWithCollateralDataById
 * NB:
 * - getVoxyInvoiceWithCollateralDataFromServerById simply makes the call to the back-end API
 * - getBusinessDocumentWithCollateralDataById also makes the call to the back-end API and triggers events that update reference lists on the front-end * @param businessDocumentId
 */
export function getVoxyInvoiceWithCollateralDataFromServerById(businessDocumentId: string) {
  return apiGet<BusinessDocumentAllDataPersisted>(`/workspaces/${get(WorkspaceStore).workspaceId}/business-documents/${businessDocumentId}/with-collateral-data`, <APICallOptions>{
    entity: APIEntity.VOXY_BUSINESS_DOCUMENTS,
    overrideContentType: 'application/json',
    ignoreFeedback: true
  })
}


/**
 * Retrieve a Voxy Business Document along with its collateral data by its ID
 * Check usage with other implementation: getVoxyInvoiceWithCollateralDataFromServerById
 * NB:
 * - getVoxyInvoiceWithCollateralDataFromServerById simply makes the call to the back-end API
 * - getBusinessDocumentWithCollateralDataById also makes the call to the back-end API and triggers events that update reference lists on the front-end
 * @param businessDocumentId
 */
export function getBusinessDocumentWithCollateralDataById(businessDocumentId: string): Promise<void | BusinessDocumentAllDataPersisted> {
  return getVoxyInvoiceWithCollateralDataFromServerById(businessDocumentId)
    .then((persistedBusinessDocumentResponse: BusinessDocumentAllDataPersisted) => {
      /* console.log('Response from Get Request getBusinessDocumentWithCollateralDataById', persistedBusinessDocumentResponse) */
      /* console.log('Business document', persistedBusinessDocumentResponse.coreDocument.businessDocument) */
      setTimeout((): void => {
        /* console.log('request to update all when getting voxy collateral from back-end') */
        eventsManager.emit(EventType.REQUEST_INVOICE_LIST_AND_COMPUTED_INVOICE_LIST_TO_REFRESH, null, 'voxy.service.ts')
      }, 2000)

      return persistedBusinessDocumentResponse
    }).catch(e => feedbackService.displayFeedback(<Feedback>{
      feedbackLevel: 'Error',
      feedbackMessage: e.message || e.detail || e
    }))
}


/**
 * Get the business document from the server
 * Check usage with other implementation: getBusinessDocumentById
 * NB:
 * - getVoxyInvoiceFromServer simply makes the call to the back-end API
 * - getBusinessDocumentById also makes the call to the back-end API and triggers events that update reference lists on the front-end
 * @param businessDocument
 */
export function getVoxyInvoiceFromServer(businessDocumentId?: string): Promise<{ businessDocument: BusinessDocument }> {
  /* console.log('The businessDocument object to be pulled from server', businessDocumentId) */

  return apiGet<{
    businessDocument: BusinessDocument;
  }>(`/workspaces/${get(WorkspaceStore).workspaceId}/business-documents/${businessDocumentId}`, <APICallOptions>{
    entity: APIEntity.VOXY_BUSINESS_DOCUMENTS,
    overrideContentType: 'application/json',
    ignoreFeedback: true
  })
}

/**
 * Retrieve a Voxy Business Document by its ID
 * Check usage with other implementation: getVoxyInvoiceFromServer
 * NB:
 * - getVoxyInvoiceFromServer simply makes the call to the back-end API
 * - getBusinessDocumentById also makes the call to the back-end API and triggers events that update reference lists on the front-end * @param businessDocumentId
 */
export function getBusinessDocumentById(businessDocumentId: string): Promise<void | BusinessDocument> {
  return getVoxyInvoiceFromServer(businessDocumentId)
    .then((response: { businessDocument: BusinessDocument }) => {
      /* console.log('Response from Get Request', response) */
      setTimeout(() => {
        /* console.log('request to update all when getting voxy from back-end by id (deprecated)') */
        eventsManager.emit(EventType.REQUEST_INVOICE_LIST_AND_COMPUTED_INVOICE_LIST_TO_REFRESH, null,
          'voxy.service.ts')
      }, 2000)

      return response.businessDocument
    }).catch(e => feedbackService.displayFeedback(<Feedback>{
      feedbackLevel: 'Error',
      feedbackMessage: e.message || e.detail || e
    }))
}


/**
 * Save a voxy invoice with its collateral data (UI info) to the server
 * @param invoiceCollateralData
 */
export function saveVoxyBusinessDocumentCollateralDataToServer(invoiceCollateralData?: BusinessDocumentCollateralData) {
  /* console.log('The collateral object being saved', invoiceCollateralData) */

  return apiPut<{
    upsertResult: any;
  }>(`/workspaces/${get(WorkspaceStore).workspaceId}/business-document-collateral-data`,
    {
      workspaceId: `${get(WorkspaceStore).workspaceId}`,
      businessDocumentCollateralData: invoiceCollateralData
    }, <APICallOptions>{
      entity: APIEntity.VOXY_BUSINESS_DOCUMENTS,
      overrideContentType: 'application/json'
    }).then((response: { upsertResult: any }) =>
      /* console.log('%c VOXY saveVoxyBusinessDocumentCollateralDataToServer() response', 'color: purple;', response) */

      response
    ).catch((error: ApiError): void => {
      console.error('saveVoxyBusinessDocumentCollateralDataToServer', error)
    })
}

/**
 * Save a voxy invoice to the server
 * @param businessDocument
 */
export function saveVoxyInvoiceToServer(businessDocument?: BusinessDocument): Promise<void | SavedBusinessDocumentResponse> {
  /* console.log('saving businessDocument', businessDocument) */

  return apiPut<SavedBusinessDocumentResponse>(`/workspaces/${get(WorkspaceStore).workspaceId}/business-documents`,
    {
      workspaceId: `${get(WorkspaceStore).workspaceId}`,
      businessDocument
    }, <APICallOptions>{
      entity: APIEntity.VOXY_BUSINESS_DOCUMENTS,
      overrideContentType: 'application/json'
    }).then((response: SavedBusinessDocumentResponse) =>
      /* console.log('%c VOXY saveInvoiceToServer() response', 'color: purple;', response) */

      response
    ).catch((error: ApiError): void => {
      console.error(error)
    })
}

/**
 * Delete multiple draft invoices
 * @param businessDocumentIds
 */
export function deleteVoxyDraftInvoices(businessDocumentIds: string[]): Promise<any> {
  /* console.log('deleteVoxyDraftInvoices() businessDocumentIds', businessDocumentIds) */

  return apiDelete(`/workspaces/${get(WorkspaceStore).workspaceId}/business-documents`, businessDocumentIds, <APICallOptions>{
    entity: APIEntity.VOXY_BUSINESS_DOCUMENTS,
    ignoreFeedback: true
  })
    .then((response: any) => response)
    .catch((error) => { throw error })
}


export const saveVoxyInvoicePreferences = async (file: any, onProgress: (percentCompleted: number) => void): Promise<any> => {

  const workspaceId = get(WorkspaceStore).workspaceId

  try {
    // upload file
    if (file.base64) {
      const entity: string = APIEntity.INVOICE_FILE

      const formItems = new Map<string, ApiUploadArgs>([
        ['base64Encoded', <ApiUploadArgs>{
          isFormValue: true,
          isFileValue: false,
          contents: false
        }],
        ['workspacePrefFileBase64', <ApiUploadArgs>{
          isFormValue: true,
          isFileValue: false,
          contents: file.base64
        }],
        ['wsPrefFile', <ApiUploadArgs>{
          isFormValue: false,
          isFileValue: true,
          contents: file.base64,
          fileName: file.fileName,
          mimeType: file.mimeType
        }],
        ['wsPrefFileName', <ApiUploadArgs>{
          isFormValue: true,
          isFileValue: false,
          contents: file.fileName
        }],
        ['wsPrefFileType', <ApiUploadArgs>{
          isFormValue: true,
          isFileValue: false,
          contents: file.fileMimeType
        }]
      ])

      const path = `workspace/${workspaceId}/pref-file/prefKind/BusinessDocumentPDFBackground/fileSubCategory/all`
      await apiUploadAny(path, null, (percentCompleted: number) => onProgress(percentCompleted), <APIUploadCallOptions>{ entity, formItems })
    }

    // save preferences
    const prf = {
      'kind': 'WorkspaceGeneric',
      'genKind': 'BizDocPDFBackgroundImage',
      workspaceId,
      'wsGeneric': {
        workspaceId,
        'pref': {
          'genKind': 'BizDocPDFBackgroundImage',
          'version': 'bdpdfbgimg-v1m0',
          'hasABackgroundImageAvailable': file.base64 ? true : false,
          'preferenceKind': 'ws-biz-doc-pdf-bg-img',
          'fileSubCategory': 'all',
          workspaceId,
          'fileName': file.fileName,
          'fileType': file.fileMimeType,
          'backgroundImageOpacity': file.transparency,
          'insertCompanyLogo': file.insertCompanyLogo,
          'headerMargin': file.headerMargin,
          'headerTextColor': file.headerTextColor,
          'footerMargin': file.footerMargin,
          'footerTextColor': file.footerTextColor
        }
      }
    }

    const res = await savePreference(workspaceId, prf)
    if (res.failures) return res.failures[0]

  } catch (err) {
    return err
  }
}

export const getVoxyInvoicePreferences = async (): Promise<any> => {
  const entity: string = APIEntity.INVOICE_FILE
  const workspaceId = get(WorkspaceStore).workspaceId

  // preference
  const prf = await getPreferenceByKind(workspaceId, 'WorkspaceGeneric', [{
    workspaceId,
    'kind': 'WorkspaceGeneric',
    'genKind': 'BizDocPDFBackgroundImage'
  }])

  const err: any[] = prf?.failures

  let res: any = {}
  let downloadImage: boolean = true

  if (!err.length) {
    const tmp = (prf?.successes as any[])[0]
    if (tmp?.foundPref?.pref) {
      downloadImage = tmp.foundPref.pref.hasABackgroundImageAvailable

      res = {
        image: '',
        fileName: tmp.foundPref.pref.fileName,
        fileMimeType: tmp.foundPref.pref.fileType,
        opacity: tmp.foundPref.pref.backgroundImageOpacity,
        insertCompanyLogo: Boolean(tmp.foundPref.pref.insertCompanyLogo),
        headerMargin: tmp.foundPref.pref.headerMargin,
        headerTextColor: tmp.foundPref.pref.headerTextColor,
        footerMargin: tmp.foundPref.pref.footerMargin,
        footerTextColor: tmp.foundPref.pref.footerTextColor

      }
    }
  }

  // image
  if (downloadImage) {
    const path = `workspace/${workspaceId}/pref-file/prefKind/BusinessDocumentPDFBackground/fileSubCategory/all?justLatestFile`
    let img = await apiGet(path, <APIUploadCallOptions>{ entity })
    if (img && img[0]) res.image = atob(img[0]?.contentBase64) ?? ''
  }

  return {
    err,
    res
  }
}