
/**
 * CHECK IF VARIABLE IS OBJECT TYPE
 * @param {any} value 
 * @returns {boolean}
 */
export const isObject = (value: any): boolean => typeof value === 'object' && value !== null && !Array.isArray(value)

/**
 * 
 * @param obj 
 * @returns {Object}
 */
export const objectDeepClone = (obj: any): Object => {
  if (obj === null) return obj
  let clone = Object.assign({}, obj)
  Object.keys(clone).forEach(
    key =>
    (clone[key] =
      typeof obj[key] === 'object' ? objectDeepClone(obj[key]) : obj[key])
  )
  if (Array.isArray(obj)) {
    clone.length = obj.length

    return Array.from(clone)
  }

  return clone
}

/**
 * REMOVE PROPERTIES FROM OBJECT
 * @param {Object} obj 
 * @param {string[]} props 
 * @param {boolean} clone 
 * @returns {Object}
 */
export const objectRemoveProperties = (obj: any, props: string[], clone: boolean = true): Object => {
  const o = clone ? objectDeepClone(obj) : obj

  props.forEach((p: any) => delete o[p])

  return o
}

/**
 * FIND OBJECT INSIDE ANOTHER BY PATH
 * @param {Object} obj 
 * @param {string} path 
 * @returns {Object|undefined}
 */
export const objectFindByPath = (obj: any, path: any): object => {
  if (!obj) return path

  let paths = path.split('.')
  let current = obj
  let i: number

  for (i = 0; i < paths.length; ++i) {
    if (current[paths[i]] == undefined) {
      return {}
    } else {
      current = current[paths[i]]
    }
  }

  return current
}

/**
 * COMPARE TWO OBJECTS AND CHECK IS THERE IS A DIFFERENCE
 * @param {any} obj 
 * @param {any} other 
 * @returns {boolean}
 */
export const objectIsEqual = (obj: any, other: any): boolean => {
  if (obj === other) return true

  // Check if both are null or undefined
  if (obj == null || other == null) return obj === other

  // Check the type of each object to handle special cases
  if (typeof obj !== 'object' || typeof other !== 'object') return false

  // Compare type instances (eg Date, RegExp)
  if (obj.constructor !== other.constructor) return false

  // Comparison for Array type objects
  if (Array.isArray(obj) && Array.isArray(other)) {
    if (obj.length !== other.length) return false

    return obj.every((value, index) => objectIsEqual(value, other[index]))
  }

  // Comparison for Map type objects
  if (obj instanceof Map && other instanceof Map) {
    if (obj.size !== other.size) return false
    for (let [key, valA] of obj) {
      if (!other.has(key) || !objectIsEqual(valA, other.get(key))) return false
    }

    return true
  }

  // Comparison for Set type objects
  if (obj instanceof Set && other instanceof Set) {
    if (obj.size !== other.size) return false
    for (let valA of obj) {
      if (!other.has(valA)) return false
    }

    return true
  }

  // Comparison for generic objects
  const keysObj = Object.keys(obj)
  const keysOther = Object.keys(other)
  if (keysObj.length !== keysOther.length) return false

  return keysObj.every(key => objectIsEqual(obj[key], other[key]))
}