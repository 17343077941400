<script lang="ts">
  import { get } from 'svelte/store'
  import { navigate } from 'svelte-routing'
  import { t } from '../../core-app/lib/i18n/i18nextWrapper.js'
  import { TodosStore } from '../../dundy-app/stores/todos.store'
  import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
  import Tooltip from '../../core-app/lib/ui-kit/Tooltip/Tooltip.svelte'

  /** Exported declarations */
  export let isDisabled: boolean = false
  export let invoiceNumber: string
  export let customerId: string
  export let invoiceId: string
  export let customTemplate: boolean = false

  /** Local declarations */
  let workspaceId: string = get(WorkspaceStore).workspaceId

  /** Reactive declarations */
  $:todo = $TodosStore?.find(e => !!e.scopeInvoiceNumbers.find(n => n === invoiceNumber))

  const onSendMail = () => {
    navigate(
      customTemplate
        ? `/action/${workspaceId}/${customerId}/${invoiceId}/todo?from=invoice`
        : `/action/${workspaceId}/${customerId}/${invoiceId}/overview`
    )
  }
</script>

<div data-ut-component="view-todo-dunning-button">
  <Tooltip>
    <div slot="activator">
      <button aria-label="{t('invoices.viewInvoice.buttons.dunningButton')}"
        class="h-12 w-12 flex items-center justify-center rounded hover:border hover:border-black hover:text-black focus:z-10 focus:ring-2 focus:ring-whisper"
        disabled={isDisabled}
        on:click={() => onSendMail()}
        type="button"
      >
        <svg fill={customTemplate ? 'orange' : 'none'} height="24" viewBox="0 0 24 24" width="24">
          <path d="M4.75 19.25L12 4.75L19.25 19.25L12 15.75L4.75 19.25Z" stroke="currentColor" stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"></path>
          <path d="M12 15.5V12.75" stroke="currentColor" stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"></path>
        </svg>
      </button>
    </div>
    {#if !!todo && todo?.actionKind === 'InvoiceIssuance'}
      {t('invoices.viewInvoice.buttons.sendInvoiceButton')}
    {:else}
      {t('invoices.viewInvoice.buttons.dunningButton')}
    {/if}
  </Tooltip>
</div>

