<script lang="ts">
  import { onMount } from 'svelte'
  // import { remoteDebuggingService } from './core-app/services/remote-debugging.service'
  // import customLog from './shared/services/log-user-event.service'
  // import mixpanel from 'mixpanel-browser'

  onMount(() => {
    // customLog('')
    // remoteDebuggingService.addInfo('')
    // mixpanel.track('mob.A00.u mobile page loaded', {
    //   'Description': 'User open app with a mobile device'
    // })
  })
</script>

<div class="text-center h-screen w-screen">
  <div class="flex items-center bg-gray-900 text-white text-xl p-7 mb-10">
    <a href="/">
      <img alt="Dundy" src="/img/dundy-icon.svg"/>
    </a>
     - Portail Client
  </div>

  <div>
    DEVELOPPEMENT EN COURS
  </div>
</div>
