<script lang="ts">
  import { BarOrientation } from '$shared/components/bar/bar.d'
  import { ContactsStore } from '$src/crm-app/stores/contacts.store'
  import { checkDunningTemplateInput, convertRawToHtml, getDunningTemplate, replacePlaceholders } from '$src/dundy-app/services/dunning-template.service'
  import { createEventDispatcher, onDestroy, onMount } from 'svelte'
  import { DunningMessageKind, type DunningMessageTemplateItem, type DunningMessageTemplatePrefKey } from '$dundy/models/dunning-message-template'
  import { fields, medias, modes, type Field, type FieldsErrors, type FormField, type Media } from '$src/dundy-app/data/template'
  import { getDunningWorkflow } from '$src/dundy-app/services/dunning-workflow.service'
  import { objectFindByPath } from '$shared/utils/object'
  import { type Placeholders } from '$dundy/data/placeholder'
  import Bar from '$src/shared/components/bar/Bar.svelte'
  import InputPlaceholder from '$src/shared/components/input/InputPlaceholder.svelte'
  import languages from '$core/lib/i18n/languages-all'
  import Loader from '$src/core-app/lib/ui-kit/Loader.svelte'
  import StyledSelect from '$src/core-app/lib/ui-kit/StyledSelect.svelte'
  import type { Contact } from '$src/crm-app/models/contact'

  // readonly
  export let stepId:Readonly<string> = ''
  export let customerId:Readonly<string> = ''
  export let invoiceId:Readonly<string> = ''
  export let contacts:Readonly<Contact[]> = []
  export let placeholders:Readonly<Placeholders> = []
  export let hideMedia:Readonly<boolean> = false
  export let hideLanguage:Readonly<boolean> = false
  export let hidePreview:Readonly<boolean> = false
  export let disabled:Readonly<boolean> = false
  
  // bind
  export let media:Media = DunningMessageKind.DunningEmail.toString() as Media
  export let language:string = 'en'
  export let isEditing:boolean = true // edit or preview
  export let inputAllowInject:boolean = true
  export let formErrors:FieldsErrors = {}
  
  // local
  const dispatch = createEventDispatcher()
  let isLoading:boolean = true
  let input:InputPlaceholder | null
  let locales:Record<string, string>[] = languages
  let languageFallback:string = 'en'
  let prefKey: DunningMessageTemplatePrefKey | null
  let prefValue: DunningMessageTemplateItem | null
  let prefValueDefault: DunningMessageTemplateItem | null
  let formFields:FormField[] = []
  let resizeObserver:ResizeObserver
  let mainDiv:HTMLDivElement
  let minMode:boolean = false

  $: {
    if (mainDiv && !resizeObserver) {
      resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          minMode = entry.target?.clientWidth < 1000
        }
      })
  
      resizeObserver.observe(mainDiv)
    }
  }

  $:temporaryMedias = medias.filter(m => m.key !== DunningMessageKind.DunningInformation)

  const dispatchTemplateUpdate = () => {
    dispatch('templateChange', { prefKey, prefValue, formFields })
  }

  /**
   * CONVERT RAW DATA TO HTML FROM STRING/OBJECT 
   * @param data
   */
  const convertData2Editor = (data:any) => {
    if (typeof data === 'string') return convertRawToHtml(data)

    if (typeof data === 'object') {
      return Object.entries(data).reduce((acc:any, [key, value]) => {
        acc[key] = convertRawToHtml(value as string)
      
        return acc
      }, {})
    }
  }

  /**
   * CONVERT RAW VALUES INTO CHARACTER STRINGS (e.g. emails)
   * @param data
   */
  const valueConverter = (data:any) => {
    if (!data) return ''

    if (typeof data === 'string') return data
  
    const newVal:string[] = []
    const lst = Array.isArray(data) ? [...data] : [data]
    lst.forEach(item => {
      // DunningEmailAddressTemplate
      if (item.literalEmail) item.literalEmail.split(',').forEach((m:string) => newVal.push(m))
      if (item.dunningPlaceHolder) item.dunningPlaceHolder.split(',').forEach((m:string) => newVal.push(m))
      if (item.contactReference) {
        const contact = $ContactsStore.find(c =>c.contactId === item.contactReference) 
        if (contact) newVal.push(contact.email)
      }

      // MailAddress
      if (item.street) newVal.push(item.street)

      // Contact
      if (item.email) {
        const contact = $ContactsStore.find(c =>c.contactId === item.contactReference) 
        if (contact) newVal.push(contact.email)
      }
    })

    return newVal.join(',')
  }

  /**
   * GET THE RAW VALUE OF prefValue FOR A SPECIFIC KEY key IN MULTILANGUAGE
   * @param {boolean} isSystemDefault
   * @param {boolean} hasLocale
   * @param {string} key
   */
  const valueInit = (isSystemDefault:boolean, hasLocale: boolean, key:string):any => {
    if (!hasLocale) {
      if (isSystemDefault) return ''
    
      return objectFindByPath(prefValue, key)
    }

    const val = objectFindByPath(prefValue, key)
  
    if (isSystemDefault) return Object.keys(val).reduce((acc:any, cur) => {
      acc[cur] = ''
    
      return acc
    }, {})
  
    return val
  }

  /**
   * GENERATE FORM FIELDS FROM TEMPLATE (API)
   */
  const loadTemplate = () => {
    isLoading = true
    
    const mediaKey = (media[0].toLowerCase() + media.slice(1)) as Media
    
    formFields = (fields[mediaKey]).reduce((acc:FormField[], cur:Field) => {
      const f = Object.assign({}, cur) as FormField
      const key = `${mediaKey}.${cur.key}`
      const value = valueInit(prefValue?.isSystemDefault || false, f.hasLocale ?? false, key)
      const placeholder = objectFindByPath(prefValueDefault, key)
    
      f.placeholder = f.hasLocale ? placeholder : valueConverter(placeholder)
      f.placeholder = f.multiline ? convertData2Editor(f.placeholder) : f.placeholder
      // debugger // TODO : placeholder est erroné car prefValueDefault = null (voir API)
      // if (f.key === 'body') debugger // TODO : erreur lors du passage de edit à preview car value n'a pas les langues (API)
      if (value) {
        f.value = f.hasLocale ? value : valueConverter(value)
      } else {
        f.value = f.placeholder
      }
      f.value = f.multiline ? convertData2Editor(f.value) : f.value

      acc.push(f)
    
      return acc
    }, [])
 
    isLoading = false
  }

  const onBarMediaChange = () => {
    input = null
    dispatch('inputKeyChange', '')
    loadTemplate()
  }

  const onBarModeChange = (mode:string) => {
    isEditing = mode === 'edit'
  }

  const onChangeLanguage = (l:any) => {
    language = l.value
    loadTemplate()
  }

  const onInputFocus = (e:any, key: string) => {
    input = e
    dispatch('inputKeyChange', key)
    dispatch('searchChange', '')
  }

  const onInputBlur = (key: string) => {
    if (!prefValue) return
    formErrors[key] = checkDunningTemplateInput(prefValue, formFields, language, key)
  }

  const onInputChange = (field:FormField, value:string) => {
    if (field.value.hasOwnProperty([language])) {
      if (field.placeholder[language] === value) value = '' // replace placeholder by null value
      field.value[language] = value
    } else {
      if (field.placeholder === value) value = '' // replace placeholder by null value
      field.value = value
    }

    dispatchTemplateUpdate()
  }

  const onInputChangeWord = (value:string) => dispatch('searchChange', value)

  const getFieldValue = (field:FormField, placeholderFallback:boolean = false):string => {
    let res:string = ''

    if (field.hasLocale) {
      // add language if not present in field
      if (!(language in field.value)) field.value[language] = ''
      if (typeof field.placeholder === 'string') field.placeholder = { [language]: '' }
      if (!(language in field.placeholder)) field.placeholder[language] = ''
      
      res = field.value[language] === field.placeholder[language] ? '' : field.value[language]
      if (!res && placeholderFallback) res = field.placeholder[language]
    } else {
      res = field.value
      if (!res && placeholderFallback) res = field.placeholder
    }

    return res
  }
  
  const getFieldPreview = (field:FormField):string => {
    const value = getFieldValue(field, true)

    return replacePlaceholders(value, invoiceId, { media })
  }

  onMount(async () => {
    let workflowKind:string = ''

    // language
    const resLng = await getDunningWorkflow(customerId)
    if (resLng) {
      workflowKind = resLng.workflow?.prefItem?.defaultDunningWorkflowKind || ''
      language = resLng.workflow?.prefItem?.defaultL10n || ''
      languageFallback = resLng.workflow?.prefItem?.mandatoryFallbackL10n ?? 'en'
      locales = languages.filter(l => resLng.workflow?.prefItem.dunningWorkflowL10ns.includes(l.value))
    }
    
    // template
    const res = await getDunningTemplate(workflowKind as DunningMessageKind, stepId, customerId)
    prefKey = res.prefKey
    prefValue = res.prefValue
    prefValueDefault = res.prefValueDefault
    
    // select media
    if (prefValue && !media) {
      if (prefValue?.kind === DunningMessageKind['*']) prefValue.kind = DunningMessageKind[medias[0].key as keyof typeof DunningMessageKind]

      media = prefValue.kind.toString() as Media
    }

    loadTemplate()
    dispatchTemplateUpdate()
  })

  onDestroy(() => {
    if (resizeObserver) resizeObserver.unobserve(mainDiv)
  })

  export const injectPlaceholder = (placeholderKey:string, separator: string = '', replaceWord:boolean = false) => {
    if (input) input.injectPlaceholder(placeholderKey, separator, replaceWord)
  }
</script>

<div
  class="relative size-full min-h-[40rem] flex flex-col bg-white rounded"
  class:disabled={disabled}
  bind:this={mainDiv}
>

  {#if isLoading}
    <div class="absolute top-1/2 left-1/2 -translate-y-[50%] -translate-x-[50%]">
      <Loader />
    </div>
  {:else}

    {#if !hideMedia || !hideLanguage || !hidePreview}
      <div class="flex items-center mb-4 {hideMedia && hideLanguage ? 'justify-end' : 'justify-between'}">
        <div class="flex">
          {#if !hideMedia}
            <Bar
              choices={temporaryMedias}
              orientation={BarOrientation.HORIZONTAL}
              minimal={true}
              hideLabel={minMode}
              displayTooltip={minMode}
              bind:value={media}
              on:change={() => onBarMediaChange()}
            />
          {/if}

          {#if !hideLanguage}
            <StyledSelect
              value={language} items={locales} isSearchable={false} zIndex={99}
              on:select={e => onChangeLanguage(e.detail)}
              class={'mx-2 min-w-60'}
            />
          {/if}
        </div>

        {#if !hidePreview}
          <Bar
            choices={modes}
            displayTooltip={minMode}
            hideLabel={minMode}
            minimal={true}
            orientation={BarOrientation.HORIZONTAL}
            selectColor={'#7e56ed'}
            value={isEditing ? 'edit' : 'preview'}
            on:change={(e) => onBarModeChange(e.detail)}
          />
        {/if}
      </div>
    {/if}

    {#each formFields as field}
      <div class="relative flex items-center {!!field.multiline ? 'flex-1' : ''}">
        {#if field.label}
          <span class="w-3/12 text-sm">{field.label}</span>
        {/if}

        <div class="size-full py-2" class:flex={!!field.hasLocale}>
          {#if isEditing}
            <InputPlaceholder
              error={formErrors[field.key] ?? ''}
              getCurrentWord={!['from', 'subject', 'body', 'callCanevas', 'letterSubject', 'letterBody', 'content'].includes(field.key)}
              editable={field.editable}
              clearBeforeInject={field.clearBeforeInject}
              multiline={!!field.multiline}
              variables={contacts}
              placeholder={field.hasLocale ? field.placeholder[language] : field.placeholder}
              placeholders={placeholders.filter(p => !p.fields || p.fields.includes(field.key))}
              value={getFieldValue(field)}
              bind:allowInject={inputAllowInject}
              on:focus={e => onInputFocus(e.detail, field.key)}
              on:blur={() => onInputBlur(field.key)}
              on:change={e => onInputChange(field, e.detail)}
              on:changeWord={e => onInputChangeWord(e.detail)}
            />

          {:else}

            <div class='inline-block w-full p-2 text-sm border min-h-9'>
              <table>
                {@html getFieldPreview(field)}
              </table>
            </div>

          {/if}

        </div>
      </div>
    {/each}

  {/if}
    
</div>

<style lang="postcss">
  .disabled {
    @apply bg-gray-100 pointer-events-none;
  }
</style>