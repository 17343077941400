import type { BankTransaction, CashApplicationDeclaredEvent } from '../models/cash-application-declared-model'
import { APICallOptions, APIEntity, apiPost, apiPut } from '../../core-app/services/api.service'
import { get } from 'svelte/store'
import { WorkspaceStore } from '../../crm-app/stores/workspace.store'
import type { CashApplicationDeclaredResponse } from '../models/cash-application-declared-model'
import { eventsManager } from '../../core-app/events/event-manager'
import { EventType } from '../../core-app/events/event-type'
import type { InvoicesHistoryItem } from '../../dundy-app/models/invoices-history'
import type { BBBTransaction } from '../../bank-app/models/bbb-transactions-model'
import type { DundyEvent } from '../../dundy-app/events/dundy-event'
import { CashApplicationDeclaredEventsStore } from '../stores/cash-application.store'
import { deepClone } from '../../core-app/util/object-deep-cloning'
import { formatCurrencyWithOptions } from '../../voxy-app/utils/number-formatting-utils'
import { ConvertToBankTransaction } from '../../bank-app/models/bbb-transactions-model'
import { Amount, NewAmountOfMoney } from '../models/amount-of-money-model'
import type { CADEStatusForTransaction } from '../models/cades-status-for-transaction'
import {
  extractCashApplicationDeclaredEventsFromInvoicesHistory, getListOfTransactionsRequiringCashApplication,
  getRemainingAmountToApplyForInvoiceExceptWithGivenTransaction,
  getRemainingAmountToApplyForTransactionExceptForGivenInvoice, sumAppliedAmounts
} from './cash-application-pure-functions'

class CashApplicationService {

  initialized
  workspaceId
  initialize() {
    if (!this.initialized) {
      /* console.log('init CashApplicationService') */
      this.initialized = true
      this.workspaceId = get(WorkspaceStore).workspaceId

      eventsManager.on<InvoicesHistoryItem[]>(EventType.HISTORY_LIST_FETCHED, (e: DundyEvent<InvoicesHistoryItem[]>) => {
        /* console.log('+++ calling fetch cash application from ON INVOICES_HISTORY_LIST_FETCHED') */
        this.buildCashApplicationDeclaredStoreList(e.data)
      }, 'CashApplicationService')

    }
  }
  /**
     * ApiPOST the cash application declared event for the current workspace
     * @param cashApplicationDeclaredEvent
     */
  postCashApplicationDeclaredEvent(cashApplicationDeclaredEvent: CashApplicationDeclaredEvent[]) {
    return apiPut(`/workspace/${this.workspaceId}/event/cash-application-declared`, cashApplicationDeclaredEvent, <APICallOptions>{
      entity: APIEntity.CASH_APPLICATION,
      dontRedirectToSignInOnUnAuthorized: true
    })
      .then((response: CashApplicationDeclaredResponse) => response)
  }
  /**
     * Returns the cash application declared events payload for the current workspace
     * @param newHistoryList
     */
  buildCashApplicationDeclaredStoreList(newHistoryList: InvoicesHistoryItem[]) {
    /* console.log('+++ about to rebuild cash application declared list') */
    /* console.log('newly newHistoryList e.data', newHistoryList.length, newHistoryList) */
    let cashApplicationDeclaredList: CashApplicationDeclaredEvent[] = extractCashApplicationDeclaredEventsFromInvoicesHistory(newHistoryList)
    /* console.log('newly cashApplicationDeclaredList', cashApplicationDeclaredList.length, cashApplicationDeclaredList) */
    CashApplicationDeclaredEventsStore.set(cashApplicationDeclaredList)
    eventsManager.emit(EventType.CASH_APPLICATION_LIST_FETCHED, cashApplicationDeclaredList,
      'CashApplicationService')
  }

}

export const cashApplicationService = new CashApplicationService()
cashApplicationService.initialize()
