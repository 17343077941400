import { formatCurrencyWithOptions } from '$src/voxy-app/utils/number-formatting-utils'
import { get } from 'svelte/store'
import { SimpleDocumentsStore } from '$src/voxy-app/stores/business-documents.store'
import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
import type { BusinessDocument } from '$src/voxy-app/models/business-document'

export const placeholderOpenChr:string = '{'
export const placeholderCloseChr:string = '}'

export type Placeholder = {
  key: string,
  value:string,
  fields?: string[],
  fn:(data?: object) => string
}

export type Placeholders = Placeholder[]

export const placeholders:Placeholders = [
  {
    key: '{invoiceNumber}',
    value: t('template.variable.keywords.invoiceNumber'),
    fields: ['letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content' ],
    fn: (data:any) => data.invoice?.invoiceNumber || '[invoiceNumber]'
  }, 
  {
    key: '{invoiceAmount}',
    value: t('template.variable.keywords.invoiceAmount'),
    fields: ['letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => {
      if (data.invoice?.amountIncludingTaxes && data.invoice?.currency) 
        return formatCurrencyWithOptions(data.invoice?.amountIncludingTaxes, t('locales'), data.invoice?.currency, true, 'symbol')
      
      return '[invoiceAmount]'
    } 
  }, 
  {
    key: '{invoiceRemainingAmount}',
    value: t('template.variable.keywords.invoiceRemainingAmount'),
    fields: ['letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => {
      if (data.invoice?.currency) {
        const businessDocument = get(SimpleDocumentsStore).find(d => d.businessDocumentId === data.invoice.dataId) || {} as BusinessDocument

        return formatCurrencyWithOptions(businessDocument?.amountDueScaledValue, t('locales'), businessDocument?.currency, true, 'symbol')
      } 
      
      return '[invoiceRemainingAmount]'
    }
  }, 
  {
    key: '{invoiceDueDate}',
    value: t('template.variable.keywords.invoiceDueDate'),
    fields: ['letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => {
      if (data.invoice?.dateDue) {
        const d = new Date(data.invoice.dateDue)
        
        return d.toLocaleDateString()
      }
      
      return '[invoiceDueDate]'
    }
  }, 
  {
    key: '{invoiceIssueDate}',
    value: t('template.variable.keywords.invoiceIssueDate'),
    fields: ['letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => {
      if (data.invoice?.dateIssued) {
        const d = new Date(data.invoice.dateIssued)
        
        return d.toLocaleDateString()
      }
      
      return '[invoiceDueDate]'
    }
  },
  {
    key: '{firstCustomerContact}',
    value: t('template.variable.keywords.firstCustomerContact'),
    fields: ['emailsTo', 'emailsCC', 'emailsCCI', 'contactDestination', 'letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => {
      let value = ''

      switch (data.media) {
        case 'DunningEmail':
          value = data.invoice?.finalCustomerContact?.email
          break
        case 'DunningPhoneCall':
          value = data.invoice?.finalCustomerContact?.mobilePhone
          value = (value ? value + ' - ' : '') + data.invoice?.finalCustomerContact?.officePhone
          break
        case 'DunningPostalLetter':
        case 'DunningLegalAction':
        case 'DunningInformation':
        default:
          value = `${data.invoice?.finalCustomerContact?.firstName} ${data.invoice?.finalCustomerContact?.lastName}`
          break
      }
      
      return value || '[firstCustomerContact]'
    }
  },
  {
    key: '{firstCustomerFirstName}',
    value: t('template.variable.keywords.firstCustomerFirstName'),
    fields: ['letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => data.invoice?.finalCustomerContact?.firstName || '[firstCustomerFirstName]'
  },
  {
    key: '{firstCustomerLastName}',
    value: t('template.variable.keywords.firstCustomerLastName'),
    fields: ['letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => data.invoice?.finalCustomerContact?.lastName || '[firstCustomerLastName]'
  },
  {
    key: '{firstCustomerFullName}',
    value: t('template.variable.keywords.firstCustomerFullName'),
    fields: ['letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => {
      if (data.invoice?.finalCustomerContact?.firstName && data.invoice?.finalCustomerContact?.lastName) return `${data.invoice?.finalCustomerContact?.firstName} ${data.invoice?.finalCustomerContact?.lastName}`

      return '[firstCustomerFullName]'
    }
  },
  {
    key: '{customerCompanyName}',
    value: t('template.variable.keywords.customerCompanyName'),
    fields: ['letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => data.invoice?.toFinalCustomer?.formalName || '[customerCompanyName]'
  },
  {
    key: '{customerCompanyAddress}',
    value: t('template.variable.keywords.customerCompanyAddress'),
    fields: ['letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => {
      const a = data.invoice?.toFinalCustomer?.mailAddress
      let address = a?.street
      if (a?.extraAddressLine) address += '\n' + a?.extraAddressLine
      if (a?.zipCode) address += '\n' + a?.zipCode
      if (a?.city) address += '\n' + a?.city
      if (a?.state) address += '\n' + a?.state
      if (a?.country) address += '\n' + a?.country

      return address ?? '[customerCompanyAddress]'
    }
  },
  {
    key: '{myFirstName}',
    value: t('template.variable.keywords.myFirstName'),
    fields: ['letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => data?.profile?.firstName
  },
  {
    key: '{myLastName}',
    value: t('template.variable.keywords.myLastName'),
    fields: ['letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => data?.profile?.lastName
  },
  {
    key: '{myFullName}',
    value: t('template.variable.keywords.myFullName'),
    fields: ['letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => `${data?.profile?.firstName} ${data?.profile?.lastName}`
  },
  {
    key: '{myMobileNumber}',
    value: t('template.variable.keywords.myMobileNumber'),
    fields: ['letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => data?.profile?.mobilePhone
  },
  {
    key: '{myOfficeNumber}',
    value: t('template.variable.keywords.myOfficeNumber'),
    fields: ['letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => data?.profile?.officePhone
  },
  {
    key: '{myPhoneNumbers}',
    value: t('template.variable.keywords.myPhoneNumbers'),
    fields: ['letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => {
      let numbers = data?.profile?.mobilePhone
      if (numbers) {
        if (data?.profile?.officePhone) {
          numbers += ' - ' + data?.profile?.officePhone
        }
      } else {
        numbers = data?.profile?.officePhone
      }
      
      return numbers
    }
  },
  {
    key: '{myEmailAddress}',
    value: t('template.variable.keywords.myEmailAddress'),
    fields: ['emailFrom', 'emailsTo', 'emailsCC', 'emailsCCI', 'letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => data?.profile?.email
  },
  {
    key: '{myCompanyName}',
    value: t('template.variable.keywords.myCompanyName'),
    fields: ['letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => data?.company?.formalName
  },
  {
    key: '{myCompanyAddress}',
    value: t('template.variable.keywords.myCompanyAddress'),
    fields: ['letterSubject', 'letterBody', 'subject', 'body', 'callCanevas', 'content'],
    fn: (data:any) => {
      const a = data?.company?.mailAddress
      let address = a?.street
      if (a?.extraAddressLine) address += '\n' + a?.extraAddressLine
      if (a?.zipCode) address += '\n' + a?.zipCode
      if (a?.city) address += '\n' + a?.city
      if (a?.state) address += '\n' + a?.state
      if (a?.country) address += '\n' + a?.country

      return address
    }
  },
  {
    key: '{paymentLinkBlock}',
    value: t('template.variable.keywords.paymentLinkBlock'),
    fields: ['body'],
    fn: (data:any) => `<tr>
      <td>
        <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;"><br />${t('todoAction.baseActionEmailItemBody.bankDetails')}</p>
      </td>
    </tr>
    <tr>
      <td style="padding: 24px 0;">
        <table style="width:100%;border-spacing: 0;padding: 0;">
          <tr>
            <td class="active-t btn" align="center" style="background:#1F2533;font:13px/16px Arial, Helvetica, sans-serif;border-radius:6px;height:48px;">
              <a style="color:#fff; text-decoration:none; display:block; padding:16px 10px;" target="_blank" href="${data.paymentLink ?? '#'}">${t('todoAction.baseActionEmailItemBody.makePayment')}</a>
            </td>
          </tr>
        </table>
      </td>
    </tr>`
  },
  {
    key: '{bankTransferBlock}',
    value: t('template.variable.keywords.bankTransferBlock'),
    fields: ['body', 'letterBody', 'content'],
    fn: (data:any) => `<tr>
      <td style="padding: 20px;background: #F6F5F9; border-radius: 10px;">
        <p style="font-weight:400;color:#606F82;font:12px/20px Arial, Helvetica-Light, sans-serif; margin: 0 0 5px 0;">${t('todoAction.baseActionEmailItemBody.bankDetailsTitle')} :</p>
        <p style="font-weight:400;color:#606F82;font:12px/20px Arial, Helvetica-Light, sans-serif;">${t('todoAction.baseActionEmailItemBody.bank')} : <span style="color: #1F2533">${data.bankConfig.selectedAccountBankName}</span></p>
        <p style="font-weight:400;color:#606F82;font:12px/20px Arial, Helvetica-Light, sans-serif;">${t('todoAction.baseActionEmailItemBody.iban')} : <span style="color: #1F2533">${data.bankConfig.selectedAccountBankIBAN}</span></p>
        <p style="font-weight:400;color:#606F82;font:12px/20px Arial, Helvetica-Light, sans-serif;">${t('todoAction.baseActionEmailItemBody.bic')} : <span style="color: #1F2533">${data.bankConfig.selectedAccountBankBIC}</span></p>
        <p style="font-weight:400;color:#606F82;font:12px/20px Arial, Helvetica-Light, sans-serif;margin: 0 0 5px 0;">${t('todoAction.baseActionEmailItemBody.reference')} : <span style="color: #1F2533">{invoiceNumber}</span></p>
        <p style="font-weight:400;color:#606F82;font:12px/20px Arial, Helvetica-Light, sans-serif;">${t('todoAction.baseActionEmailItemBody.thanks')}</p>
      </td>
    </tr>`
  },
  {
    key: '{myEmailSignature}',
    value: t('template.variable.keywords.myEmailSignature'),
    fields: ['body'],
    fn: () => `<tr>
      <td>
        <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;padding-top:32px;">${t('todoAction.baseActionEmailItemBody.thoughtfully')},</p>
        <p style="font-weight:400;color:#1F2533;font:14px/24px Arial, Helvetica-Light, sans-serif;">{myFullName}</p>
        <p style="font-weight:400;color:#1F2533;font:12px/20px Arial, Helvetica-Light, sans-serif;">{myCompanyName}</p>
      </td>
    </tr>`
  }
]