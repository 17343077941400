<script lang="ts">
  import { CustomersStore } from '$src/crm-app/stores/customers.store'
  import { getDunningWorkflow, saveDunningWorkflow, type DunningWorkflow } from '../services/dunning-workflow.service'
  import { onDestroy, onMount } from 'svelte'
  import { Route, Router, navigate } from 'svelte-routing'
  import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
  import { workflowSteps, type WorkflowStep } from '../data/workflow'
  import Accordion from '$src/shared/components/accordion/Accordion.svelte'
  import AccordionItem from '$src/shared/components/accordion/AccordionItem.svelte'
  import CheckboxList from '$src/core-app/lib/ui-kit/CheckboxList.svelte'
  import languages from '$core/lib/i18n/languages-all'
  import Page from '$src/shared/components/templates/Page.svelte'
  import RadioList from '$src/core-app/lib/ui-kit/RadioList.svelte'
  import WorkflowEditor from '../components/workflow/WorkflowEditor.svelte'
  import WorkflowItem from '../components/workflow/WorkflowItem.svelte'

  let customerId:string | null = new URL(window.location.toString()).searchParams?.get('cid')
  let locales:any[] = []
  let defaultLocale:string = 'en'
  let fallbackLocale:string = 'en'
  let dunningWorkflow:DunningWorkflow | null
  let dunningWorkflowLocales:string[] = []
  let loading:boolean = false

  $:localesFiltered = locales.filter(l => l.checked)
  $:customer = customerId ? $CustomersStore.find(c => c.company.companyId === customerId) : null
  $:pageTitle = t('topMenu.nav.settings.reminderWorkflow') + (customer ? ' - ' + customer?.company?.formalName : '')

  const loadLocales = async () => {
    const resLng = await getDunningWorkflow(customerId || '')

    if (resLng) {
      dunningWorkflow = resLng.workflow
      dunningWorkflowLocales = dunningWorkflow?.prefItem?.dunningWorkflowL10ns || []
      dunningWorkflowLocales = dunningWorkflow?.prefItem?.dunningWorkflowL10ns || []
      defaultLocale = dunningWorkflow?.prefItem?.defaultL10n || 'en'
      fallbackLocale = dunningWorkflow?.prefItem?.mandatoryFallbackL10n || 'en'
    }
    
    locales = languages.map(l => ({ ...l, checked: dunningWorkflowLocales.includes(l.value) }))
  }

  const saveLocalePreference = async () => {
    if (!dunningWorkflow) return

    const localesToSave = locales.reduce((acc, cur) => {
      if (cur.checked) acc.push(cur.value)
      
      return acc
    }, [])
    
    loading = true
    dunningWorkflow.prefItem.isSystemDefault = false
    dunningWorkflow.prefItem.dunningWorkflowL10ns = localesToSave
    dunningWorkflow.prefItem.defaultL10n = defaultLocale
    dunningWorkflow.prefItem.mandatoryFallbackL10n = fallbackLocale

    await saveDunningWorkflow(dunningWorkflow, customerId || '')
    await loadLocales()
    loading = false
    
  }

  const onItemEdit = (item:WorkflowStep) => {
    navigate(`/workflow/edit/${item.id}?media=${item.media}${customerId ? '&cid=' + customerId : ''}`)
  }

  const onLocalesChange = async () => {
    await saveLocalePreference()
  }

  const onDefaultLocaleChange = async (locale:string) => {
    defaultLocale = locale
    await saveLocalePreference()
  }

  const onFallbackLocaleChange = async (locale:string) => {
    fallbackLocale = locale
    await saveLocalePreference()
  }

  // DETECT WHEN URL SEARCH PARAM CHANGE
  let originalPushState = history.replaceState

  onMount(async () => {
    originalPushState = history.replaceState

    window.history.replaceState = new Proxy(window.history.replaceState, {
      apply: (target, thisArg, argArray:any) => {
        if (window.location.href.includes('edit')) return // do not check on modal edit page
        customerId = new URL(window.location.origin + '/' + argArray[2]).searchParams?.get('client')
        target.apply(thisArg, argArray)
      }
    })

    await loadLocales()
  })

  onDestroy(() => {
    window.history.replaceState = originalPushState
  })
</script>

<Page title={pageTitle} subtitle={t('workflow.subtitle')}>

  <div class="mt-6 w-2/3">
    <h2 class="text-lg font-bold">Timeline</h2>
    {#each workflowSteps as s, i}
      <WorkflowItem {...s} index={i + 1} on:edit={() => onItemEdit(s)} />
    {/each}
  </div>

  <div class="mt-12 w-2/3 hidden">
    <Accordion>
      <AccordionItem title={t('workflow.advancedMode')}>

        <div class="mb-4 p-4 rounded border border-gray-200">
          <span class="text-red-500">API : test des langues des clients</span>
          <h2>{t('template.languages')}</h2>
            <CheckboxList
              height="h-28"
              {loading}
              bind:list={locales}
              on:change={() => onLocalesChange()}
            />
        </div>

        <div class="mb-4 p-4 rounded border border-gray-200">
          <h2>{t('template.defaultLanguage')}</h2>
          <div>
            <RadioList
              height="h-18"
              {loading}
              selected={defaultLocale}
              displaySearch={false}
              bind:list={localesFiltered}
              on:change={(e) => onDefaultLocaleChange(e.detail)}
            />
          </div>
        </div>

        <div class=" p-4 rounded border border-gray-200">
          <h2>{t('template.fallbackLanguage')}</h2>
            <RadioList
              height="h-18"
              {loading}
              selected={fallbackLocale}
              displaySearch={false}
              bind:list={localesFiltered}
              on:change={(e) => onFallbackLocaleChange(e.detail)}
            />
        </div>
      </AccordionItem>
    </Accordion>
  </div>

</Page>

<Router>
  <Route path="edit/:id" let:params>
    <WorkflowEditor stepId={params.id} {customerId} customerName={customer?.company?.formalName} />
  </Route>
</Router>