import { Feedback } from '../models/feedback'
import { eventsManager } from '../events/event-manager'
import { EventType } from '../events/event-type'
import { toast } from '@zerodevx/svelte-toast'
import { FeedbackEntity } from '../enums/feedback-enums'
import { FeedbackStore, initializeFeedbacksStore } from '../stores/feedback.store'
import type { DundyEvent } from '../../dundy-app/events/dundy-event'
import { t } from '../lib/i18n/i18nextWrapper'

const TOAST_THEMES = {
  Error: {
    '--toastBarHeight': 0,
    '--toastBackground': '#FDF1F0',
    '--toastBarBackground': '#DE496D',
    '--toastBorder': '1px solid #DE496D',
    '--toastColor': '#1f2533',
    '--toastPadding': '.25rem .5rem'
  },
  Warning: {
    '--toastBarHeight': 0,
    '--toastBackground': '#EAC96C',
    '--toastBarBackground': '#FCF2E2'
    // '--toastColor': '#1F2533'
  },
  Info: {
    '--toastBarHeight': '.15rem',
    '--toastBackground': '#1f2533',
    '--toastBarBackground': '#73C6A8',
    '--toastBorder': '1px solid #3C4A6A',
    '--toastColor': '#ffffff',
    '--toastPadding': '.25rem .5rem'
  },
  Success: {
    '--toastBarHeight': '.15rem',
    '--toastBackground': '#1f2533',
    '--toastBarBackground': '#73C6A8',
    '--toastBorder': '1px solid #3C4A6A',
    '--toastColor': '#ffffff',
    '--toastPadding': '.25rem .5rem'
  }
}

class FeedbackServiceClass {

  initialized: boolean
  initialize() {
    if (!this.initialized) {

      this.initialized = true

      this.initializeDataInStore()

      const logMethods: Record<string, 'warn' | 'log' | 'error'> = {
        'Warning': 'warn',
        'Info': 'log'
      }

      eventsManager.on<Feedback>(EventType.FEEDBACK_CHANGED, (e: DundyEvent<Feedback>) => {
        if (e.data.feedbackLevel !== 'None') {
          const logMethod = logMethods[e.data.feedbackLevel] || 'error'
          console[logMethod](e.data ? JSON.stringify(e.data) : 'Problem with feedback...')
          this.displayFeedback(e.data)
        }
      }, 'feedbackService')
    }
  }
  populateFeedbackWithErrorMessageAndStatus(feedbackEntity: FeedbackEntity, message: string, status?: number) {
    /*
                 console.log('%c Populate Feedback populateFeedbackWithErrorMessageAndStatus', 'font-size: 1.1em;', feedbackEntity, message, status);
        */
    this.populateFeedback(feedbackEntity, <Feedback>{
      feedbackMessage: message,
      responseStatus: status,
      feedbackLevel: 'Error'
    })
  }
  populateFeedback(feedbackEntity: FeedbackEntity, feedback: Feedback) {
  /* console.log('%c Populate Feedback', 'font-size: 1.1em;', feedback); */
    FeedbackStore.update((feedbackMap: Map<FeedbackEntity, Feedback>) => {
      feedbackMap.set(feedbackEntity, feedback)

      return feedbackMap
    })
  }
  displayFeedback(feedback: Feedback) {
    if (feedback.feedbackLevel === 'Error') {
       console.error('displayFeedback error, e=', feedback)
    } else {
       console.warn('displayFeedback warn, e=', feedback)
    }
    toast.push(feedback.feedbackMessage || '', {
      theme: TOAST_THEMES[feedback.feedbackLevel || 'Error']
    })
  }
  private initializeDataInStore() {
    const feedbackPerEntity: Map<FeedbackEntity, Feedback> = new Map<FeedbackEntity, Feedback>()
    for (const element of Object.values(FeedbackEntity)) {
      if (isNaN(Number(element))) {
        feedbackPerEntity.set(FeedbackEntity[element], new Feedback())
      }
    }
    initializeFeedbacksStore(feedbackPerEntity)
  }
}

export const feedbackService: FeedbackServiceClass = new FeedbackServiceClass()
