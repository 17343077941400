<script lang="ts">
  import { createEventDispatcher } from 'svelte'

  export let name:string = ''
  export let value:string = ''
  export let group:string = ''
  export let disabled = false

  const dispatch = createEventDispatcher()

  const onRadioSelect = () => {
    group = value
    dispatch('select', value)
  }
</script>

<button
  class="flex items-center text-sm {disabled ? 'pointer-events-none' : 'cursor-pointer'}"
  on:click={() => onRadioSelect()}
>
  <div
    class:selected={group === value}
    class:disabled={disabled}
  />
  <input 
    type="radio" 
    class=""
    {name} 
    {value} 
    {disabled}
    aria-disabled={disabled}
    bind:group={group} 
  />
  <slot />
</button>

<style lang="postcss">
  div {
    @apply relative rounded-full size-4 border bg-white border-persianGreen mr-3;

    &.disabled {
      @apply border-warmGray;
    }
  }
  div.selected::before {
    content:'';
    @apply absolute size-2.5 mt-[2px] -ml-[5px] rounded-full bg-persianGreen;
  }
  div.selected.disabled::before {
    @apply bg-warmGray;
  }
</style>