<script lang="ts">
  import { featureToggleStore } from '$src/config/feature-toggle.store'
  import { FeatureDisplayMode, type Feature } from '$src/config/features.service'

  const displayModes = Object.values(FeatureDisplayMode)

  let displayList:boolean = false
  let features:Feature[] = []

  featureToggleStore.subscribe((store) => {
    features = []

    Object.keys(store ?? {}).forEach((f:string) => {
      const feature = store[f]
      if (!feature?.name) feature.name = f
      features.push(feature)
    })
  })

  const onSelectChange = (name:string, value:FeatureDisplayMode) => {
    featureToggleStore.update(store => {
      store[name].displayMode = value
      
      return store
    })
  }

  const onInputChange = (name:string, value:FeatureDisplayMode) => {
    featureToggleStore.update(store => {
      store[name].componentMessage = value
      
      return store
    })
  }
</script>

<div class="relative ">
  <button on:click={() => displayList = !displayList} class="relative py-1.5 px-4 text-sm {displayList ? 'rounded-t bg-persianGreen text-white' : 'rounded bg-gray-200 '}">
    Features
  </button>

  {#if displayList}
    <div class="absolute -translate-x-full left-full text-s border border-persianGreen bg-whisper  p-2 rounded-b rounded-tl top-8">
      <table>
        <tr>
          <th>Name</th>
          <th>Mode</th>
          <th>Message</th>
        </tr>
        {#each features as feature}
          <tr class="">
            <td class="py-2 px-4">
              {feature.name}
            </td>
            <td>
              <select class="text-s py-0 pr-8 text-black" on:change={e => onSelectChange(feature.name, e.target.value)}>
                {#each displayModes as displayMode}
                  <option value={displayMode} selected={feature.displayMode === displayMode}>{displayMode}</option>
                {/each}
              </select> 
            </td>
            <td>
              <input type="text" class="text-s py-0 text-black" value={feature.componentMessage} on:change={e => onInputChange(feature.name, e.target.value)}/>
            </td>
          </tr>
        {/each}
      </table>
    </div>
  {/if}
</div>