<script lang="ts">
  import { onMount } from 'svelte'
  import { DunningInvoicesStore } from '../stores/dunning-invoices.store'
  import { TodosStore } from '../stores/todos.store'
  import type Invoice from '../models/invoice'
  import { t } from '$src/core-app/lib/i18n/i18nextWrapper'
  import type { TodoListItem } from '../models/todo-list-item'
  import { workflowSteps } from '../data/workflow'
  import Loader from '$src/core-app/lib/ui-kit/Loader.svelte'
  import TemplateEditor from '../components/template/TemplateEditor.svelte'
  import { emailService } from '../services/email.service'
  import { dunningTemplateToMail, getContacts } from '../services/dunning-template.service'
  import { feedbackService } from '$src/core-app/services/feedback.service'
  import { remoteDebuggingService, Severity } from '$src/core-app/services/remote-debugging.service'
  import type { Feedback } from '$src/core-app/models/feedback'
  import type { EmailAttachment } from '../models/email-attachment'
  import AttachmentsArea from '../lib/dundy-file-mgt/AttachmentsArea.svelte'
  import PageModal from '$src/shared/components/templates/PageModal.svelte'
  import Variable from '$src/shared/components/list/Variable.svelte'
  import { placeholders } from '../data/placeholder'
  import ViewActionReceivableInvoiceHeader from '../lib/action-receivable-invoice/ViewActionReceivableInvoiceHeader.svelte'
  import { navigate } from 'svelte-routing'
  import { markTaskDone } from '../services/todo.service'
  import { confetti } from '@neoconfetti/svelte'
  import Accordion from '$src/shared/components/accordion/Accordion.svelte'
  import AccordionItem from '$src/shared/components/accordion/AccordionItem.svelte'

  export let customerId: string
  export let invoiceId: string

  let urlOrigin:string = ''
  let templateEditor:TemplateEditor
  let template:any = {}
  let inputKey:string = ''
  let language:string = ''
  let loadingTemplate:boolean = true
  let loadingSendMail:boolean = false
  let attachments: Array<EmailAttachment>
  let inputAllowInject:boolean = true
  let variableSearchValue:string = ''
  let allowSendReminder:boolean = true
  let showConfetti: boolean = false

  const getWorkflowStep = (item:TodoListItem) => {
    if (!item) return

    let step = ''
    switch (item.actionKind) {
      case 'InvoiceIssuance':case 'InvoiceIssuance':
        step = 'STEP00_ISSUE_BUSINESS_DOCUMENT'
        break
      case 'NoActionAtThisStage':
        step = 'STEP01_BETWEEN_ISSUANCE_AND_FIRST_REMINDER'
        break
      case 'CourtesyReminderShortlyBeforeDueDate':
        step = 'STEP02_FIRST_REMINDER_BEFORE_DUE_DATE'
        break
      case 'ReminderJustAfterDueDate':
        if (item.escalation === 'CourtesyReminder' && item.dunningAction === 'InformationAboutOverdueInvoice') step = 'STEP03_FIRST_REMINDER_AFTER_DUE_DATE' 
        if (item.escalation === 'FirstReminder' && item.dunningAction === 'GentleReminderToPay') step = 'STEP04_SECOND_REMINDER_AFTER_DUE_DATE' 
        break
      case 'ReminderShortlyAfterDueDate':
        step = 'STEP05_THIRD_FIRM_REMINDER_AFTER_DUE_DATE'
        break
      case 'DirectReminderSomeTimeAfterDueDate':
        step = 'STEP06_FOURTH_REMINDER_BY_PHONE_AFTER_DUE_DATE'
        break
      case 'StrongLastReminderAfterDueDate':
        step = 'STEP07_FIFTH_REMINDER_LAST_CALL_BEFORE_LEGAL_ACTIONS'
        break
      case 'CollectionLitigation':
        step = 'STEP08_LEGAL_ACTIONS'
        break
    }

    if (step) return workflowSteps.find(s => s.id === step)
  }

  $:title = urlOrigin === 'invoice' ? t('todoAction.titleInvoice') : t('todoAction.titleReminder')

  $:closeUrl = urlOrigin === 'invoice' ? `/receivables/view/${invoiceId}/details?no-animation` : '/dunning' 

  $:invoice = $DunningInvoicesStore.find(invoiceItem => invoiceItem.dataId === invoiceId) || {} as Invoice

  $:todo = $TodosStore.find(todoItem => todoItem.scopeInvoiceNumbers.some(s => s === invoice?.invoiceNumber)) || {} as TodoListItem

  $:contacts = getContacts(customerId, 'DunningEmail')
  $:placeholdersFiltered = placeholders.filter(p => p.fields?.includes(inputKey) || !p.fields)
  $:variables = inputKey
    ? [...placeholdersFiltered, ...contacts].sort((a, b) => a.value.localeCompare(b.value))
    : []

  $:workflowStep = todo ? getWorkflowStep(todo as TodoListItem) : null

  const onTemplateInputKeyChange = (value:string) => inputKey = value
  const onTemplateSearchChange = (value:string) => variableSearchValue = value
  const onTemplateChange = (value:any) => {
    template = value
    loadingTemplate = false
  }

  const onVariableSelected = (v:Variable) => {
    const isContactInput = ['emailsTo', 'emailsCC', 'emailsCCI', 'contactDestination', 'mailAddressDestination'].includes(inputKey)
    const separator = isContactInput ? ',' : ''
    const replaceWord = isContactInput && Boolean(variableSearchValue)

    if (templateEditor) templateEditor.injectPlaceholder(v.key, separator, replaceWord)
  }

  const onSendMail = async () => {
    loadingSendMail = true

    const mail = await dunningTemplateToMail(template.formFields, language, customerId, invoiceId)

    if (!mail) {
      feedbackService.displayFeedback({
        feedbackMessage: t('todoAction.email.toast.emailGenerationFail'),
        feedbackLevel: 'Error'
      } as Feedback)
      
      loadingSendMail = false
      
      return
    }

    const res = await emailService.sendTodoActionEmail(
      mail.selectedContacts,
      mail.selectedCcContacts,
      mail.selectedBccContacts,
      mail.fromName,
      mail.replyToEmail,
      mail.replyToFullName,
      mail.renderedHeader,
      mail.renderedSubjectMessage,
      mail.renderedBodyMessage,
      attachments && attachments.length ? attachments : [],
      'todoActionEmail'
    )
    
    if (res?.StatusCode === 202) {
      markTaskDone(todo, mail.customer)

      showConfetti = true
      allowSendReminder = false

      setTimeout(() => {
        showConfetti = false
        navigate('/invoices')

        feedbackService.displayFeedback({
          feedbackMessage: t('todoAction.markTodoDoneSuccess'),
          feedbackLevel: 'Success'
        } as Feedback)
      }, 2000)

    } else {
      remoteDebuggingService.addInfo('Empty response for emailService.sendTodoActionEmail()', 'DUNDY', Severity.Error)
    }

    loadingSendMail = false
  }

  onMount(() => {
    // url origin
    const searchParams = new URLSearchParams(window.location.search)
    urlOrigin = searchParams.get('from') || ''
  })
</script>

<svelte:head>
  <style lang="postcss">
    body {
      @apply bg-whisper;
    }
  </style>
</svelte:head>

<PageModal {title} closeNavigation={closeUrl} width={'w-10/12'} hideAnimation={true}>

  <svelte:fragment slot="left-side">
    {#if placeholders.length > 0}
    <div class="text-sm font-semibold flex flex-col mt-4">
      <Accordion>
        <AccordionItem title={t('template.variable.title')} expand={true}>
          <Variable
            bind:searchValue={variableSearchValue}
            {variables}
            placeholder={t('template.variable.filter')}
            disabled={!inputAllowInject}
            on:select={(v) => onVariableSelected(v.detail)}
          />
        </AccordionItem>
      </Accordion>
    </div>
    {/if}
  </svelte:fragment>

    <svelte:fragment slot="right-side">
      <ViewActionReceivableInvoiceHeader {invoice} {todo} customer={customerId} hideLink={true} />

      <div class="h-full flex mt-6">

        {#if workflowStep && invoice}
          <div class="panel grow flex flex-col">
            <TemplateEditor
              {contacts}
              {customerId}
              {invoiceId}
              {placeholders}
              stepId={workflowStep.id}
              hideMedia={true}
              hideLanguage={true}
              isEditing={false}
              disabled={loadingSendMail}
              bind:this={templateEditor}
              bind:language={language}
              on:inputKeyChange={(value) => onTemplateInputKeyChange(value.detail)}
              on:searchChange={(value) => onTemplateSearchChange(value.detail)}
              on:templateChange={(value) => onTemplateChange(value.detail)}
            />

            <AttachmentsArea {invoice} bind:attachments={attachments} showAutomaticAttachments={true}/>
          </div>
        {:else}
          <div class="w-4/6 px-6 py-4 panel flex items-center justify-center">
            <Loader />
          </div>
        {/if}

        <div class="ml-2 w-2/6 panel sticky top-0">
          {#if loadingTemplate}
            <Loader />
          {:else}
            {#if loadingSendMail}
              <Loader />
            {:else}
              <button class="send btn big-primary uppercase ml-auto w-full" disabled={!allowSendReminder} on:click={() => onSendMail()}>
                {#if todo.actionKind === 'InvoiceIssuance'}
                    {t('todoAction.sendInvoice')}
                  {:else}
                    {t('todoAction.sendReminder')}
                  {/if}
              </button>

              {#if showConfetti}
                <div class="confetti-container">
                  <div use:confetti/>
                </div>
              {/if}
            {/if}
          {/if}
        </div>
      </div>
    </svelte:fragment>
</PageModal>

<style lang="postcss">
  .panel {
    @apply px-6 py-4 bg-white border border-athensGray rounded;
  }

  .send::before {
    content:'';
    mask: url("/img/icons/send.svg") no-repeat;
    mask-size: 50%;
    @apply bg-white size-12 mt-6;
  }
</style>