<script lang="ts">
  import FullSizeModal from '$src/core-app/lib/ui-kit/FullSizeModal.svelte'
  import { navigate } from 'svelte-routing'

  export let isCloseable:Readonly<boolean> = true
  export let closeNavigation:Readonly<string> = ''
  export let hideAnimation:Readonly<boolean> = false
  export let title:Readonly<string> = ''
  export let width:Readonly<string> = 'w-8/12'

  const hasFooterSlot = !!$$slots.footer

  const onClose = () => {
    navigate(closeNavigation)
  }
</script>

<FullSizeModal class="size-full overflow-y-auto" {isCloseable} {hideAnimation} on:close={() => onClose()}>

  <div slot="content" class="{hasFooterSlot ? 'size-full' : 'w-full'} flex">
    <div class="flex flex-col overflow-y-auto p-12 basis-[28rem] border-r border-athensGray">
      <h1 class="font-bold text-2xl bg-white z-50">{title}</h1>
      <slot name="left-side" />
    </div>
    
    <div class="w-full flex flex-col bg-slate-50">
      <div class="form mx-auto flex-1 m-3 mt-20 flex flex-col {width} {hasFooterSlot ? '' : 'pb-8'}">
        <slot name="right-side" />
      </div>

      {#if hasFooterSlot}
        <div class="flex items-center justify-center mt-6 px-2 py-8 border-t border-athensGray bg-white">
          <slot name="footer" />
        </div>
      {/if}
    </div>
  </div>

</FullSizeModal>