<script lang="ts">
  import { blobToBase64DataURI } from '$src/voxy-app/services/pdf-make/business-document-pdf-generator'
  import { createEventDispatcher } from 'svelte'

  export let width:number | string = '100%'
  export let height:number | string = '100%'
  export let hidden:boolean = false
  export let fallbackLabel: string = 'Your browser does not support PDFs.'
  export let isLoading: boolean = false
  export let zoom:boolean = false
  export let url: string | null = null
  export let blob: Blob | null = null

  const dispatch = createEventDispatcher()

  let base64: string = ''

  const generateBase64 = async () => {
    isLoading = true

    // download blob from url
    if (url) {
      const res = await fetch(url)
      if (res.ok) blob = await res.blob()
    }

    // convert blob to base64
    if (!blob) return
    const base = await blobToBase64DataURI(blob)
    base64 = base.replace('data:application/octet-stream;base64,', 'data:application/pdf;base64,')

    isLoading = false
  }

  $: {
    if (url || blob) {
      generateBase64()
    } else {
      zoom = false // re init zoom if url or blob is null
    }
  }

  $: displayWidth = typeof width === 'number' ? `${width * (zoom ? 2 : 1)}px` : width
  $: displayHeight = typeof height === 'number' ? `${height * (zoom ? 2 : 1)}px` : height

  const onClick = () => {
    isLoading = true
    zoom = !zoom
    
    setTimeout(() => {
      dispatch('zoom', zoom)
      isLoading = false
    })
  }
</script>

<button
  class="viewer-pdf"
  class:viewer-pdf-hidden={hidden}
  style='--width:{displayWidth};--height:{displayHeight}'>

  {#if base64 && !isLoading}
    <object class="viewer-pdf-object" width="100%" height="100%" type="application/pdf"
      title="PDF viewer" aria-label="PDF viewer" aria-labelledby="PDF viewer"
      data="{base64}#toolbar=0">

      {fallbackLabel}

      {#if url}
        <a href={url}>Download the PDF</a>.
      {/if}
    </object>
    <button class="viewer-pdf-overlay" on:click={onClick}/>
  {:else if isLoading}
    <div class="skeleton-loading" />
  {:else}
    Edouard : https://iconic.app/photo-hide/
  {/if} 
  </button>

<style>
  .viewer-pdf {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--width);
    height: var(--height);
    min-width: var(--width);
    min-height: var(--height);
    position: relative;
    border: 2px dashed #ccc;
    overflow: hidden;
    cursor: pointer;

    &-hidden {
      display: none;
    }

    &-overlay {
      position:absolute;
      top:0;
      left:0;
      right: 0;
      bottom: 0;
      cursor: pointer;
    }
  }

  .skeleton-loading {
    width: 100%;
    height: 100%;
    background: #e0e0e0;
    background: linear-gradient(90deg, #f0f0f0 25%, #9e9e9e 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: skeleton 2s infinite linear;
  }

  @keyframes skeleton {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
</style>
